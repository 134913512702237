/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./phonepe.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "./phonepe.page";
import * as i5 from "../../../services/order/order.service";
import * as i6 from "../../../services/label/label.service";
import * as i7 from "../../../services/shared/shared.service";
import * as i8 from "../../../services/config/config.service";
var styles_PhonepePage = [i0.styles];
var RenderType_PhonepePage = i1.ɵcrt({ encapsulation: 0, styles: styles_PhonepePage, data: {} });
export { RenderType_PhonepePage as RenderType_PhonepePage };
export function View_PhonepePage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "ion-header", [], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 4, "ion-toolbar", [], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(3, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "ion-title", [], null, null, null, i2.View_IonTitle_0, i2.RenderType_IonTitle)), i1.ɵdid(5, 49152, null, 0, i3.IonTitle, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["phonepe"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "ion-content", [], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(8, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null)], null, null); }
export function View_PhonepePage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-phonepe", [], null, null, null, View_PhonepePage_0, RenderType_PhonepePage)), i1.ɵdid(1, 114688, null, 0, i4.PhonepePage, [i5.OrderService, i3.ModalController, i3.LoadingController, i6.LabelService, i3.ToastController, i7.SharedService, i8.ConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PhonepePageNgFactory = i1.ɵccf("app-phonepe", i4.PhonepePage, View_PhonepePage_Host_0, {}, {}, []);
export { PhonepePageNgFactory as PhonepePageNgFactory };
