/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./appointment-schedules.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i4 from "@ionic/angular";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@angular/forms";
import * as i7 from "./appointment-schedules.page";
import * as i8 from "../services/user/user.service";
import * as i9 from "../services/appointment/appointment.service";
import * as i10 from "../services/shared/shared.service";
import * as i11 from "../services/label/label.service";
import * as i12 from "@angular/router";
var styles_AppointmentSchedulesPage = [i0.styles];
var RenderType_AppointmentSchedulesPage = i1.ɵcrt({ encapsulation: 0, styles: styles_AppointmentSchedulesPage, data: {} });
export { RenderType_AppointmentSchedulesPage as RenderType_AppointmentSchedulesPage };
function View_AppointmentSchedulesPage_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "arrow-up"]], null, null, null, null, null))], null, null); }
function View_AppointmentSchedulesPage_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "slot-date"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectDate(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "slot-active": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "f-w-b"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppointmentSchedulesPage_3)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = "slot-date"; var currVal_1 = _ck(_v, 3, 0, _v.context.$implicit.active); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = _v.context.$implicit.active; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _v.context.$implicit.day; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.getDate(_v.context.$implicit.date); _ck(_v, 7, 0, currVal_3); }); }
function View_AppointmentSchedulesPage_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ion-text", [], null, null, null, i3.View_IonText_0, i3.RenderType_IonText)), i1.ɵdid(1, 49152, null, 0, i4.IonText, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(2, 0, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("APPOINTMENT_SCHEDULE.slot_full")); _ck(_v, 2, 0, currVal_0); }); }
function View_AppointmentSchedulesPage_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "ion-item", [["lines", "none"]], null, null, null, i3.View_IonItem_0, i3.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i4.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { lines: [0, "lines"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 6, "ion-label", [], null, null, null, i3.View_IonLabel_0, i3.RenderType_IonLabel)), i1.ɵdid(3, 49152, null, 0, i4.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "ion-text", [], null, null, null, i3.View_IonText_0, i3.RenderType_IonText)), i1.ɵdid(5, 49152, null, 0, i4.IonText, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(6, 0, [" ", " to ", " "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AppointmentSchedulesPage_5)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, 0, 3, "ion-radio", [["slot", "start"]], null, [[null, "ionBlur"], [null, "ionSelect"]], function (_v, en, $event) { var ad = true; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12)._handleBlurEvent($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("ionSelect" === en)) {
        var pd_1 = (i1.ɵnov(_v, 12)._handleIonSelect($event.target) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_IonRadio_0, i3.RenderType_IonRadio)), i1.ɵprd(5120, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.RadioValueAccessor]), i1.ɵdid(11, 49152, null, 0, i4.IonRadio, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { disabled: [0, "disabled"], value: [1, "value"] }, null), i1.ɵdid(12, 16384, null, 0, i4.RadioValueAccessor, [i1.ElementRef], null, null)], function (_ck, _v) { var currVal_0 = "none"; _ck(_v, 1, 0, currVal_0); var currVal_3 = !_v.context.$implicit.slotLimit; _ck(_v, 8, 0, currVal_3); var currVal_4 = !_v.context.$implicit.slotLimit; var currVal_5 = _v.context.$implicit; _ck(_v, 11, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.start; var currVal_2 = _v.context.$implicit.end; _ck(_v, 6, 0, currVal_1, currVal_2); }); }
function View_AppointmentSchedulesPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "p", [["class", "choose-txt"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "scrolling-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppointmentSchedulesPage_2)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 8, "div", [["class", "time-schedules"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 7, "ion-list", [], null, null, null, i3.View_IonList_0, i3.RenderType_IonList)), i1.ɵdid(10, 49152, null, 0, i4.IonList, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(11, 0, null, 0, 5, "ion-radio-group", [], null, [[null, "ionChange"], [null, "ionBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14)._handleBlurEvent($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 14)._handleChangeEvent($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("ionChange" === en)) {
        var pd_2 = (_co.setTimeSlot($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_IonRadioGroup_0, i3.RenderType_IonRadioGroup)), i1.ɵprd(5120, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.SelectValueAccessor]), i1.ɵdid(13, 49152, null, 0, i4.IonRadioGroup, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵdid(14, 16384, null, 0, i4.SelectValueAccessor, [i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AppointmentSchedulesPage_4)), i1.ɵdid(16, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.scheduleSlots; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.getActiveTimeSchedules(); _ck(_v, 16, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("APPOINTMENT_SCHEDULE.choose_slot")); _ck(_v, 3, 0, currVal_0); }); }
function View_AppointmentSchedulesPage_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "no-data-txt"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("APPOINTMENT_SCHEDULE.no_slots")); _ck(_v, 1, 0, currVal_0); }); }
function View_AppointmentSchedulesPage_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ion-footer", [], null, null, null, i3.View_IonFooter_0, i3.RenderType_IonFooter)), i1.ɵdid(1, 49152, null, 0, i4.IonFooter, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "ion-button", [["class", "footer-button"], ["expand", "block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.bookAppointment() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_IonButton_0, i3.RenderType_IonButton)), i1.ɵdid(3, 49152, null, 0, i4.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { disabled: [0, "disabled"], expand: [1, "expand"] }, null), (_l()(), i1.ɵted(4, 0, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isBookNowDisabled(); var currVal_1 = "block"; _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("APPOINTMENT_SCHEDULE.book_now")); _ck(_v, 4, 0, currVal_2); }); }
export function View_AppointmentSchedulesPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "ion-header", [["class", "modal-header"]], null, null, null, i3.View_IonHeader_0, i3.RenderType_IonHeader)), i1.ɵdid(1, 49152, null, 0, i4.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 11, "ion-toolbar", [], null, null, null, i3.View_IonToolbar_0, i3.RenderType_IonToolbar)), i1.ɵdid(3, 49152, null, 0, i4.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 3, "ion-title", [["class", "modal-header-title"]], null, null, null, i3.View_IonTitle_0, i3.RenderType_IonTitle)), i1.ɵdid(5, 49152, null, 0, i4.IonTitle, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(6, 0, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, 0, 5, "ion-buttons", [["slot", "end"]], null, null, null, i3.View_IonButtons_0, i3.RenderType_IonButtons)), i1.ɵdid(9, 49152, null, 0, i4.IonButtons, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(10, 0, null, 0, 3, "ion-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_IonButton_0, i3.RenderType_IonButton)), i1.ɵdid(11, 49152, null, 0, i4.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(12, 0, null, 0, 1, "ion-icon", [["name", "close"]], null, null, null, i3.View_IonIcon_0, i3.RenderType_IonIcon)), i1.ɵdid(13, 49152, null, 0, i4.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 4, "ion-content", [], null, null, null, i3.View_IonContent_0, i3.RenderType_IonContent)), i1.ɵdid(15, 49152, null, 0, i4.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AppointmentSchedulesPage_1)), i1.ɵdid(17, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noSlots", 2]], 0, 0, null, View_AppointmentSchedulesPage_6)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppointmentSchedulesPage_7)), i1.ɵdid(20, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "close"; _ck(_v, 13, 0, currVal_1); var currVal_2 = _co.scheduleSlots.length; var currVal_3 = i1.ɵnov(_v, 18); _ck(_v, 17, 0, currVal_2, currVal_3); var currVal_4 = _co.scheduleSlots.length; _ck(_v, 20, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("APPOINTMENT_SCHEDULE.header_text")); _ck(_v, 6, 0, currVal_0); }); }
export function View_AppointmentSchedulesPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-appointment-schedules", [], null, null, null, View_AppointmentSchedulesPage_0, RenderType_AppointmentSchedulesPage)), i1.ɵdid(1, 114688, null, 0, i7.AppointmentSchedulesPage, [i8.UserService, i9.AppointmentService, i10.SharedService, i11.LabelService, i4.ModalController, i12.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppointmentSchedulesPageNgFactory = i1.ɵccf("app-appointment-schedules", i7.AppointmentSchedulesPage, View_AppointmentSchedulesPage_Host_0, {}, {}, []);
export { AppointmentSchedulesPageNgFactory as AppointmentSchedulesPageNgFactory };
