import * as tslib_1 from "tslib";
import { Events } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { first, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
var VariantsService = /** @class */ (function () {
    function VariantsService(events, afs) {
        this.events = events;
        this.afs = afs;
    }
    VariantsService.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('variants:getAllColors', function () {
            _this.getAllColors();
        });
        this.events.subscribe('variants:deleteColor', function (cid, i) {
            _this.deleteColor(cid, i);
        });
        this.events.subscribe('variants:getVariantsTypeData', function () {
            _this.getVariantsTypeData();
        });
        this.events.subscribe('variants:getAllTemplates', function () {
            _this.getAllTemplates();
        });
    };
    VariantsService.prototype.getAllColors = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var colorsData, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('variants').doc('colors').collection('options', function (ref) { return ref
                                .orderBy('createdAt', 'desc'); }).snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 1:
                        colorsData = _a.sent();
                        this.events.publish('variants:publishAllColors', colorsData);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.dir(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    VariantsService.prototype.deleteColor = function (cid, i) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('variants').doc('colors').collection('options').doc(cid).delete()];
                    case 1:
                        _a.sent();
                        this.events.publish('variants:deleteColorSuccess', i);
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        console.dir(error_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    VariantsService.prototype.getVariantsTypeData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var attributes_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    attributes_1 = [];
                    this.afs.collection('variants').doc('types').valueChanges().subscribe(function (data) {
                        for (var index = 0; index < data.attributes.length; index++) {
                            if (data.attributes[index].active) {
                                attributes_1.push(data.attributes[index]);
                            }
                        }
                        _this.events.publish('variants:publishVariantsTypeData', attributes_1);
                    });
                }
                catch (error) {
                    console.dir(error);
                }
                return [2 /*return*/];
            });
        });
    };
    VariantsService.prototype.getAllTemplates = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var templatesData, error_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('variants').doc('templates').collection('options')
                                .snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 1:
                        templatesData = _a.sent();
                        this.events.publish('variants:publishAllTemplates', templatesData);
                        return [3 /*break*/, 3];
                    case 2:
                        error_3 = _a.sent();
                        console.dir(error_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    VariantsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VariantsService_Factory() { return new VariantsService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore)); }, token: VariantsService, providedIn: "root" });
    return VariantsService;
}());
export { VariantsService };
