<ion-header no-border>
  <ion-toolbar>
    <ion-title>Submit form</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

  <app-widget-form [widgetID]="formId" source="modal">
  </app-widget-form>

</ion-content>
