<!-- <ion-header no-border>
  <ion-toolbar >
    <ion-buttons slot="end" (click)="close()">
      <ion-button class="close" fill="solid" color="primary">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header> -->
<ion-content>
  <div class="spinner" *ngIf="showLoader">
    <ion-spinner name="lines-small"></ion-spinner>
  </div>
  <ng-container *ngIf="!showLoader">

    <ion-fab vertical="top" horizontal="end" slot="fixed">
      <ion-fab-button (click)="close()">
        <ion-icon name="close"></ion-icon>
      </ion-fab-button>
    </ion-fab>

    <ion-fab vertical="center" horizontal="start" slot="fixed" *ngIf="imgs.length > 1">
      <ion-fab-button class="fab-nav"  (click)="prevSlide()"  [disabled]="isFirstSlide">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-fab-button>
    </ion-fab>

    <ion-fab vertical="center" horizontal="end" slot="fixed" *ngIf="imgs.length > 1">
      <ion-fab-button class="fab-nav" (click)="nextSlide()" *ngIf="imgs.length > 1" [disabled]="isLastSlide">
        <ion-icon name="arrow-forward"></ion-icon>
      </ion-fab-button>
    </ion-fab>

    <!-- <div class="close" (click)="close()">
      <i class="flaticon-letter-x"></i>
    </div>
    <ion-button class="nav prev" (click)="prevSlide()" *ngIf="imgs.length > 1" [disabled]="isFirstSlide">
      <i class="flaticon-back" slot="icon-only"></i>
    </ion-button>
    <ion-button class="nav next" (click)="nextSlide()" *ngIf="imgs.length > 1" [disabled]="isLastSlide">
      <i class="flaticon-next" slot="icon-only"></i>
    </ion-button> -->

    <div class="slider-container">
      <ion-slides [options]="sliderOpts" #modalslider (ionSlideDidChange)="slideChanged()">
        <ion-slide *ngFor="let img of imgs; let i = index">
          <div class="img-wrap swiper-zoom-container">
            <img data-src="{{img.url}}" class="swiper-lazy" [hidden]="showLoader">
          </div>
        </ion-slide>
      </ion-slides>
    </div>

    <ion-fab vertical="bottom" horizontal="end" slot="fixed" class="fab-zoom">
      <ion-fab-button class="fab-zoom-btn fab-btn-1" (click)="zoom(true)" >
        <i class="flaticon-zoom-in fab-zoom-icon"></i>
      </ion-fab-button>
      <ion-fab-button class="fab-zoom-btn fab-btn-1" (click)="zoom(false)" >
        <i class="flaticon-zoom-out fab-zoom-icon"></i>
      </ion-fab-button>
    </ion-fab>

    <!-- <div class="zoom-text">
      <p>Pinch for zoom in or out</p>
    </div> --> 
     <!-- <div class="zoom-buttons">
      <ion-fab-button >
        <i class="flaticon-zoom-in"></i>
      </ion-fab-button>

      <ion-button class="btn-2 i-start" (click)="zoom(true)" shape="round">
        <i class="flaticon-zoom-in"></i>
        Zoom In
      </ion-button>
      <ion-button class="btn-2 i-start zoom-out" (click)="zoom(false)" shape="round">
        <i class="flaticon-zoom-out"></i>
        Zoom Out
      </ion-button>
    </div> -->
  </ng-container>

</ion-content>