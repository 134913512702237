<ion-content>
  <ion-fab vertical="top" horizontal="center" slot="fixed">
    <ion-fab-button (click)="closeModal()">
      <ion-icon name="close"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <div class="image-container">
    <div class="btn-box">
    </div>
    <div class="img-box">
      <ion-img [src]="image"></ion-img>
    </div>
  </div>
</ion-content>