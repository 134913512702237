<ion-header>
  <ion-toolbar class="toolbar">
    <ion-title text-center>{{'RATE_PRODUCT.header_text' | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>


<ion-content class="f-s-14">

  <ion-item lines="none" class="rp-pdt-info">
    <ion-avatar *ngIf="product.hasOwnProperty('coverPic') && product.coverPic.url" slot="start">
      <img *ngIf="product.coverPic.thumb" src="{{product.coverPic.thumb}}">
      <img *ngIf="!product.coverPic.thumb" src="{{product.coverPic.url}}">
    </ion-avatar>
    <ion-label>
      <h4 class="ion-text-wrap">{{product.prodName}}</h4>
    </ion-label>
  </ion-item>
  <hr class="line">

  <app-rating type="product" [stars]="rating" (rateEvent)="rate($event)"></app-rating>

</ion-content>
