<ion-header class="templates-header">
  <ion-toolbar>
    <ion-title class="templates-header-title">{{'NEW_ADDRESS.pick_your_address' | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <div #map id="map"></div>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>

<ion-footer no-border class="page-footer">
  <div class="main-container">
    <ion-button (click)="saveAddress()" shape="round" class="btn-1 i-start" color="success">
      <i class="flaticon-null-20 margin-icon"></i>
      {{'PICK_AND_DROP_MAP.done' | translate}}
    </ion-button>
  </div>
</ion-footer>
