import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { NavigationExtras, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { first, map } from 'rxjs/operators';
import { BookingSchedulePage } from 'src/app/booking/booking-schedule/booking-schedule.page';
import { SelectAddonPage } from 'src/app/pages/select-addon/select-addon.page';
import { convertSnaps } from '../db-utils';
import { SharedService } from '../shared/shared.service';
import { VendorService } from '../vendor/vendor.service';
import { CartService } from '../cart/cart.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class BookingService {
    userBookings$ = new BehaviorSubject([]);
    userBookingsSub: Subscription;
  constructor(private afs: AngularFirestore,
    private sharedService: SharedService,
    private vendorService: VendorService,
    private modalController: ModalController,
    private router: Router,
	private cartService: CartService,
    private storage: Storage) { }

    async confirmBooking(data, slotLimit: number) {
        return new Promise<{status: string, id: string}>(async (resolve) => {
            try {
                //check slot availability
                let slotAvailable = false;
                if(data.schedule && data.schedule.active) {
                    let bookings = [];
                    bookings = await this.afs.collection('bookings', ref => ref
                    .where('item.id', '==', data.item.id)
                    .where('schedule.date', '==', data.schedule.date)
                    .where('schedule.slot.start', '==', data.schedule.slot.start)
                    .where('schedule.slot.end', '==', data.schedule.slot.end)
                    .where('status', '==', 'accepted'))
                    .valueChanges().pipe(first()).toPromise();
                    // console.log('bookings', bookings);
      
                    if (bookings.length < slotLimit) {
                        if (this.vendorService.vendorSettings.isMultiVendorAvailable) {
                            if (data.vendor.id) {
                                const vendorDetails: any = await this.vendorService.getVendorData(data.vendor.id);
                                if (vendorDetails && 'appointmentSlotLimit' in vendorDetails && vendorDetails.appointmentSlotLimit) {
                                    const vendorBookings = await this.afs.collection('bookings', ref => ref
                                        .where('vendor.id', '==', data.vendor.id)
                                        .where('schedule.date', '==', data.schedule.date)
                                        .where('schedule.slot.start', '==', data.schedule.slot.start)
                                        .where('schedule.slot.end', '==', data.schedule.slot.end)
                                        .where('status', '==', 'accepted'))
                                        .valueChanges().pipe(first()).toPromise();
                                    if (vendorBookings.length < vendorDetails.appointmentSlotLimit) {
                                        slotAvailable = true;
                                    } else {
                                        slotAvailable = false;
                                    }
                                } else {
                                    slotAvailable = true;
                                }
                            } else {
                                slotAvailable = true;
                            }
                        } else {
                            slotAvailable = true;
                        }
      
                    } else {
                        slotAvailable = false;
                    }
                } else {
                  slotAvailable = true;
                }
                if (slotAvailable) {
                    const bookingRef = await this.afs.collection('bookings').add(data);
                    resolve({status: 'added', id: bookingRef.id});
                } else {
                    resolve({status: 'not_available', id: ''});
                }
            } catch (error) {
                // console.log(error);
                resolve({status: 'error', id: ''});
            }
        });
    }

async getUserBookings() {
    return new Promise<any[]>(async (resolve) => {
        try {
            const userId = await this.sharedService.getStorageUid();
            this.userBookingsSub = await this.afs.collection('bookings', ref => ref
                .orderBy('createdAt', 'desc')
                .where('user.id', '==', userId))
                .snapshotChanges()
                .pipe(map(snaps => convertSnaps(snaps)))
                .subscribe(bookings => {
                  resolve(bookings);
                  this.userBookings$.next([...bookings]);
                });
        } catch (error) {
            // console.log(error);
            resolve([]);
        }
    });
}

  async startBookingProcess(product) {
        const isCartAvailable = await this.cartService.checkCartAvailability(product.inactiveByVendor);
        if(!isCartAvailable) {
            return;
        }
        const uid = await this.storage.get('uid');
        if(!uid) {
            this.sharedService.openLoginModal();
            return;
        }
        let addOns = {totalPrice: 0, selectedOptions: []};
        if(product.templateId) {
			await this.sharedService.presentLoading();
            const template = await this.getTemplateData(product.templateId);
            this.sharedService.dismissLoading();
            if(!template) {
                this.openBookingSchedule(product, addOns);
                return;
            }

            const modal = await this.modalController.create({
                component: SelectAddonPage,
                componentProps: {
                    item: {
                        name: product.prodName,
                        price: product.discountedPrice,
                        id: product.id,
                        templateId: product.templateId,
                        description: product.prodShortDesc,
                        mrp: product.prodPrice
                    },
					template
                }
            });
            modal.onDidDismiss().then(res => {
                if(res && res.data && !res.data.closed) {
                    addOns = res.data;
                    this.openBookingSchedule(product, addOns);
                }
            });
            await modal.present();
        } else {
            this.openBookingSchedule(product, addOns);
        }
    }

    async openBookingSchedule(product, addOns) {
		const modal = await this.modalController.create({
                component: BookingSchedulePage,
				cssClass: 'custom-modal',
                componentProps: {
                    data: {
						item: {
							name: product.prodName,
							price: product.discountedPrice,
							coverPic: product.coverPic,
							id: product.id,
							allowPayment: product.allowPayment || false,
							allowAddress: product.allowAddress || false,
							addOns,
							gst: {
								value: product.gst || 0,
								isExclusive: product.gstExclusive || false
							},
                            additionalInfo: product.additionalInfo ? product.additionalInfo : null,
                            shortDescription: product.prodShortDesc
						},
						vendor: {
							id: product.vendorId || ''
						},
						scheduleData: product.scheduleData
                	}
                }
            });

		await modal.present();
    }

	async getBookingData(id: string) {
        const bookingData = await this.afs.collection('bookings').doc(id).valueChanges().pipe(first()).toPromise();
        return bookingData || {};
    }

	async getTemplateData(templateId: string) {
        const templateData = await this.afs.collection('templates').doc(templateId).valueChanges().pipe(first()).toPromise();
        return templateData || null;
    }
}