import * as tslib_1 from "tslib";
import { Events } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { UserService } from './user/user.service';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "./user/user.service";
var ChatService = /** @class */ (function () {
    function ChatService(events, afs, userService) {
        this.events = events;
        this.afs = afs;
        this.userService = userService;
        this.allMsgs = [];
        this.lastMsgsData = [];
    }
    ChatService.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('chat:sendMsg', function (msg, uid) {
            _this.sendMsg(msg, uid);
        });
        this.events.subscribe('chat:getMsgs', function (id, role) {
            _this.getMsgs(id, role);
        });
        this.events.subscribe('chat:getMoreMsgs', function (id) {
            _this.getMoreMsgs(id);
        });
        this.events.subscribe('chat:getLastMsgs', function () {
            _this.getLastMsgs();
        });
        this.events.subscribe('chat:loadMoreMessagesForAdminHome', function () {
            _this.loadMoreMessagesForAdminHome();
        });
        this.events.subscribe('chat:getPaymentDetails', function () {
            _this.getPaymentDetails();
        });
        this.events.subscribe('chat:removeLastMsgSubscription', function () {
            if (_this.lastMsgsSub) {
                //// // console.log('removeLastMsgSucscription...');
                _this.lastMsgsSub.unsubscribe();
            }
        });
        this.events.subscribe('chat:removeGetMsgsSubscription', function () {
            if (_this.getMsgsSub) {
                //// // console.log('removeGetMsgsSubscription...');
                _this.getMsgsSub.unsubscribe();
            }
        });
        this.events.subscribe('chat:getUnreadMsgOfAdmin', function (uid) {
            _this.getUnreadMsgOfAdmin(uid);
        });
        this.chatRef = this.afs.collection('chats');
    };
    ChatService.prototype.sendMsg = function (msg, uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var lastMsgData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.chatRef.doc(uid).valueChanges().pipe(first()).toPromise()];
                    case 1:
                        lastMsgData = _a.sent();
                        msg.published = true;
                        if (msg.type === 'txt') {
                            this.chatRef.doc(uid).update({ lastMessage: msg.message, lastMessageAt: msg.createdAt, totalMsgs: lastMsgData.totalMsgs + 1 });
                        }
                        else if (msg.type === 'order' && msg.status === 'Rejected') {
                            this.chatRef.doc(uid).update({ lastMessage: 'Your order is Rejected.',
                                lastMessageAt: msg.createdAt, totalMsgs: lastMsgData.totalMsgs + 1 });
                        }
                        else if (msg.type === 'order' && msg.status === 'Cancelled') {
                            this.chatRef.doc(uid).update({ lastMessage: 'Your order is cancelled.',
                                lastMessageAt: msg.createdAt, totalMsgs: lastMsgData.totalMsgs + 1 });
                        }
                        else if (msg.type === 'order' && msg.status === 'Confirmed') {
                            this.chatRef.doc(uid).update({ lastMessage: 'Your order is confirmed.',
                                lastMessageAt: msg.createdAt, totalMsgs: lastMsgData.totalMsgs + 1 });
                        }
                        else if (msg.type === 'order' && msg.status === 'Dispatched') {
                            this.chatRef.doc(uid).update({ lastMessage: 'Your order is dispatched.',
                                lastMessageAt: msg.createdAt, totalMsgs: lastMsgData.totalMsgs + 1 });
                        }
                        else if (msg.type === 'order' && msg.status === 'Delivered') {
                            this.chatRef.doc(uid).update({ lastMessage: 'Your order is delivered.',
                                lastMessageAt: msg.createdAt, totalMsgs: lastMsgData.totalMsgs + 1 });
                        }
                        else if (msg.type === 'order' && msg.status === 'Returned') {
                            this.chatRef.doc(uid).update({ lastMessage: 'Your order is returned.',
                                lastMessageAt: msg.createdAt, totalMsgs: lastMsgData.totalMsgs + 1 });
                        }
                        else if (msg.type === 'order' && msg.status === 'PaymentMsg') {
                            this.chatRef.doc(uid).update({ lastMessage: 'Payment is successful.',
                                lastMessageAt: msg.createdAt, totalMsgs: lastMsgData.totalMsgs + 1 });
                        }
                        else if (msg.type === 'order' && msg.status === 'PaymentRequest') {
                            this.chatRef.doc(uid).update({ lastMessage: 'Please do the payment of your order.',
                                lastMessageAt: msg.createdAt, totalMsgs: lastMsgData.totalMsgs + 1 });
                        }
                        else if (msg.type === 'order' && msg.status === 'deliveryStarted') {
                            this.chatRef.doc(uid).update({ lastMessage: 'Delivery has started of this order.',
                                lastMessageAt: msg.createdAt, totalMsgs: lastMsgData.totalMsgs + 1 });
                        }
                        if (!(msg.author === 'user')) return [3 /*break*/, 6];
                        if (!(lastMsgData.adminActive === false)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.chatRef.doc(uid).collection('messages').add(msg)];
                    case 2:
                        _a.sent();
                        this.chatRef.doc(uid).update({ unreadMsgs: lastMsgData.unreadMsgs + 1 });
                        return [3 /*break*/, 5];
                    case 3:
                        msg.isRead = true;
                        return [4 /*yield*/, this.chatRef.doc(uid).collection('messages').add(msg)];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 10];
                    case 6:
                        if (!(lastMsgData.userActive === false)) return [3 /*break*/, 8];
                        return [4 /*yield*/, this.chatRef.doc(uid).collection('messages').add(msg)];
                    case 7:
                        _a.sent();
                        this.chatRef.doc(uid).update({ unreadAdminMsgs: lastMsgData.unreadAdminMsgs + 1 });
                        return [3 /*break*/, 10];
                    case 8:
                        msg.isRead = true;
                        return [4 /*yield*/, this.chatRef.doc(uid).collection('messages').add(msg)];
                    case 9:
                        _a.sent();
                        _a.label = 10;
                    case 10: return [2 /*return*/];
                }
            });
        });
    };
    ChatService.prototype.getTotalMsg = function (uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var chatData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.chatRef.doc(uid).valueChanges().pipe(first()).toPromise()];
                    case 1:
                        chatData = _a.sent();
                        //// // console.log('chatData', chatData);
                        return [2 /*return*/, chatData.totalMsgs];
                }
            });
        });
    };
    ChatService.prototype.getMsgs = function (uid, role) {
        var _this = this;
        this.getMsgsSub = this.afs.collection('chats').doc(uid).collection('messages', function (ref) { return ref.orderBy('createdAt', 'desc').limit(30); })
            .snapshotChanges().subscribe(function (response) {
            if (!response.length) {
                //// // console.log('No msgs');
                return false;
            }
            _this.allMsgs = [];
            _this.firstResponse = response[response.length - 1].payload.doc;
            for (var _i = 0, response_1 = response; _i < response_1.length; _i++) {
                var msg = response_1[_i];
                _this.allMsgs.push({ msgData: msg.payload.doc.data(), id: msg.payload.doc.id });
                if (role === 'admin') {
                    _this.afs.collection('chats').doc(uid).collection('messages').doc(msg.payload.doc.id).update({ isRead: true });
                }
            }
            if (role === 'admin') {
                _this.chatRef.doc(uid).update({ unreadMsgs: 0 });
            }
            else {
                _this.chatRef.doc(uid).update({ unreadAdminMsgs: 0 });
            }
            _this.events.publish('chat:publishMsgs', _this.allMsgs.reverse());
        });
    };
    ChatService.prototype.getMoreMsgs = function (uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.afs.collection('chats').doc(uid).collection('messages', function (ref) { return ref.orderBy('createdAt', 'desc')
                    .limit(30).startAfter(_this.firstResponse); }).snapshotChanges().pipe(first()).subscribe(function (response) {
                    if (!response.length) {
                        //// // console.log('No msgs');
                        _this.events.publish('chat:noMoreMsgs');
                        return false;
                    }
                    _this.firstResponse = response[response.length - 1].payload.doc;
                    for (var _i = 0, response_2 = response; _i < response_2.length; _i++) {
                        var msg = response_2[_i];
                        _this.allMsgs.unshift({ msgData: msg.payload.doc.data(), id: msg.payload.doc.id });
                    }
                    _this.events.publish('chat:publishMoreMsgs', _this.allMsgs);
                });
                return [2 /*return*/];
            });
        });
    };
    ChatService.prototype.getLastMsgs = function () {
        var _this = this;
        //// // console.log('in getLastMsgs');
        this.lastMsgsData = [];
        var lastMsgRef = this.afs.collection('chats', function (ref) { return ref
            .orderBy('lastMessageAt', 'desc')
            .limit(environment.scrollLimit); });
        var lastMsgs = lastMsgRef.snapshotChanges();
        this.lastMsgsSub = lastMsgs.subscribe(function (res) {
            _this.lastResponseForAdminMsgs = res[res.length - 1].payload.doc;
            for (var _i = 0, res_1 = res; _i < res_1.length; _i++) {
                var msg = res_1[_i];
                _this.lastMsgsData.push(tslib_1.__assign({ id: msg.payload.doc.id }, msg.payload.doc.data()));
            }
            _this.events.publish('chat:publishLastMsgs', _this.lastMsgsData);
        });
    };
    ChatService.prototype.loadMoreMessagesForAdminHome = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                //// // console.log('in loadMoreMessagesForAdminHome');
                try {
                    this.lastMsgsSub = this.afs.collection('chats', function (ref) { return ref
                        .orderBy('lastMessageAt', 'desc')
                        .limit(environment.scrollLimit)
                        .startAfter(_this.lastResponseForAdminMsgs); })
                        .snapshotChanges()
                        .subscribe(function (response) {
                        if (!response.length) {
                            //// // console.log('No Data Available');
                            _this.events.publish('chat:msgsForAdminHomeLimitReached');
                            return false;
                        }
                        _this.lastResponseForAdminMsgs = response[response.length - 1].payload.doc;
                        for (var _i = 0, response_3 = response; _i < response_3.length; _i++) {
                            var msg = response_3[_i];
                            _this.lastMsgsData.push(tslib_1.__assign({ id: msg.payload.doc.id }, msg.payload.doc.data()));
                        }
                        _this.events.publish('chat:publishLastMsgs', _this.lastMsgsData);
                    }, function (error) {
                    });
                }
                catch (err) {
                    console.dir(err);
                }
                return [2 /*return*/];
            });
        });
    };
    ChatService.prototype.getUnreadMsgOfAdmin = function (uid) {
        var _this = this;
        try {
            //// // console.log('in getUnreadMsgOfAdmin...');
            if (uid && uid !== undefined) {
                this.afs.collection('chats').doc(uid).valueChanges().subscribe(function (response) {
                    //// // console.log('getUnreadMsgOfAdmin number', response.unreadAdminMsgs);
                    _this.events.publish('chat:publishUnreadMsgOfAdmin', response && response.unreadAdminMsgs ? response.unreadAdminMsgs : 0);
                });
            }
        }
        catch (error) {
            console.dir(error);
        }
    };
    ChatService.prototype.makeadminActiveTrue = function (uid) {
        this.chatRef.doc(uid).update({ adminActive: true });
    };
    ChatService.prototype.makeadminActiveFalse = function (uid) {
        this.chatRef.doc(uid).update({ adminActive: false });
    };
    ChatService.prototype.makeUserActiveTrue = function (uid) {
        this.chatRef.doc(uid).update({ userActive: true });
    };
    ChatService.prototype.makeUserActiveFalse = function (uid) {
        this.chatRef.doc(uid).update({ userActive: false });
    };
    ChatService.prototype.getPaymentDetails = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var paymentData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.afs.collection('info').doc('payment').valueChanges().pipe(first()).toPromise()];
                    case 1:
                        paymentData = _a.sent();
                        this.events.publish('chat:publishPaymentDetails', paymentData);
                        return [2 /*return*/];
                }
            });
        });
    };
    ChatService.prototype.removeSubscriptions = function () {
        this.events.unsubscribe('chat:sendMsg');
        this.events.unsubscribe('chat:getMsgs');
        this.events.unsubscribe('chat:getMoreMsgs');
        this.events.unsubscribe('chat:getLastMsgs');
        this.events.unsubscribe('chat:getPaymentDetails');
    };
    ChatService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChatService_Factory() { return new ChatService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.UserService)); }, token: ChatService, providedIn: "root" });
    return ChatService;
}());
export { ChatService };
