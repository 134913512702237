import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.page.html',
  styleUrls: ['./video-modal.page.scss'],
})
export class VideoModalPage implements OnInit {
  videoID;
  videoURL 

  constructor(private domSanitizer: DomSanitizer) { }

  ngOnInit() {
    let videoURL = 'https://www.youtube.com/embed/' + this.videoID;
    this.videoURL = this.domSanitizer.bypassSecurityTrustResourceUrl(videoURL); 
  }

}
