import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SharedService } from '../services/shared/shared.service';
import { UserService } from '../services/user/user.service';

@Component({
  selector: 'app-body-measurement',
  templateUrl: './body-measurement.page.html',
  styleUrls: ['./body-measurement.page.scss'],
})
export class BodyMeasurementPage implements OnInit {


  allowEdit: boolean = false;
  userId: string = '';
  bodyMeasurement = {
    profileName: '',
    bodyLength: null,
    fullHeight: null,
    blouseLength:null,
    shirtLength:null,
    jacketLength:null,
    kurthiHeight:null,
    shoulderToWaistBack:null,
    frontShoulder:null,
    backShoulder:null,
    armhole:null,
    wrist:null,
    bicep:null,
    strapLength:null,
    sleeveLength:null,
    sleeveCircumf:null,
    threeFourthSleeveLengthCircular:null,
    threeFourthSleeveLength:null,
    sleeveLengthHalf:null,
    upperChest:null,
    blouseChest:null,
    bust:null,
    blouseBelowBust:null,
    napeToApex:null,
    shoulderToApex:null,
    neck:null,
    frontNeckWidth:null,
    backNeckWidth:null,
    frontNeckDepth:null,
    backNeckDepth:null,
    frontNeckDepth2:null,
    backNeckDepth2:null,
    bottomLength:null,
    bottomWaist:null,
    bottomHip:null,
    seat:null,
    waistToHip:null,
    inseam:null,
    shortsLength:null,
    thighCircular:null,
    thighs:null,
    thighRound:null,
    aboveKnee:null,
    belowKnee:null,
    calf:null,
    ankle:null,
    sweepLength:null,
    frontSlit:null,
    sideSlit:null,
    apexToApex:null
  }

  constructor(
    private sharedService: SharedService,
    private modalController: ModalController,
    private userService: UserService
  ) { }

  ngOnInit() {
  }

  async ionViewWillEnter() {
    // console.log("userId: ", this.userId);
    // console.log("bodyMeasurement: ", this.bodyMeasurement);
    // console.log("allowEdit: ", this.allowEdit);
  }

  async close() {
    this.modalController.dismiss();
  }

  async save() {
    // await this.sharedService.presentLoading();
    // const res = await this.userService.updateUserInfo(this.userId, { bodyMeasurement: this.bodyMeasurement });
    // await this.sharedService.loading.dismiss();
    // this.modalController.dismiss(this.bodyMeasurement);
    // if (res) {
      //   await this.sharedService.presentAlert('Measurement updated successfully');
      // } else {
        //   await this.sharedService.presentAlert('Measurement update failed!');
        // }
    const profileName = this.bodyMeasurement.profileName.trim().toLowerCase();
    if (profileName === '') {
      await this.sharedService.presentAlert('name required!');
      return;
    }
    this.modalController.dismiss(this.bodyMeasurement);
  }

}
