<!-- <app-page-header [backButton]="true" [menuBtn]="false" headerTxt="{{'USER_BOOKING_DETAILS.header_text' | translate}}">
	</app-page-header> -->

  <ion-content>
    <div *ngIf="showLoader; else showData" class="spinner">
      <ion-spinner color="primary"></ion-spinner>
    </div>
  
    <ng-template #showData>
  
      <!-- booking schedule -->
      <div class="content">
      <div class="header-close">
        <h6>{{'USER_BOOKING_DETAILS.booking_schedule' | translate}}</h6>
          <ion-buttons slot="end">
             <ion-button (click)="modalDismiss()">
             <ion-icon slot="icon-only" name="close-circle"></ion-icon>
             </ion-button>
          </ion-buttons>
      </div>
        <p *ngIf="booking.schedule">
          <span class="f-w-b">{{'USER_BOOKING_DETAILS.booking_date' | translate}}: </span>
          <span>{{booking.schedule.date}} at {{convert24to12(booking.schedule.slot.start)}} to {{convert24to12(booking.schedule.slot.end)}}</span>
        </p>
        <p>
          <span class="f-w-b">{{'USER_BOOKING_DETAILS.booked_at' | translate}}: </span>
          <span>{{booking.createdAt.toDate() | date: 'dd/MM/yyyy'}}</span>
        </p>
      </div>
      <div class="separator"></div>
      <!-- booking schedule -->
  
      <!-- address -->
      <ng-container *ngIf="booking?.user.address">
        <div class="content">
          <h6>{{'USER_BOOKING_DETAILS.address' | translate}}</h6>
          <p>{{booking.user.address.name}}</p>
          <p>{{booking.user.address.address}}</p>
          <p>{{booking.user.address.phoneNo}}</p>
        </div>
        <div class="separator"></div>
      </ng-container>
      <!-- address -->
  
      <!-- payment info -->
      <ng-container *ngIf="booking?.payment?.mode">
        <div class="content">
          <ng-container *ngIf="!isPartialAvailable(); else paidPartially;">
            <h6>{{'USER_BOOKING_DETAILS.payment' | translate}}</h6>
            <p>
              <span class="f-w-b">{{'USER_BOOKING_DETAILS.mode' | translate}}: </span>
              <span class="ion-text-capitalize">{{booking.payment.mode}}</span>
            </p>
            <p *ngIf="booking.payment?.status">
              <span class="f-w-b">{{'USER_BOOKING_DETAILS.status' | translate}}: </span>
              <span class="ion-text-capitalize">{{booking.payment.status}}</span>
            </p>
    
            <div class="payment-ss" *ngIf="booking.payment.mode === 'upiManual'">
              <p>
                {{'USER_BOOKING_DETAILS.uploaded_payment_ss' | translate}}
              </p>
              <img [src]="booking.payment.screenshot" (click)="imgZoom(booking.payment.screenshot)">
            </div>
    
            <div class="payment-ss" *ngIf="booking.payment.mode === 'custom'">
              <div *ngIf="booking.payment.textDetails">
                <p>
                  {{'USER_BOOKING_DETAILS.details' | translate}}: {{booking.payment.textDetails}}
                </p>
              </div>
              <div *ngIf="booking.payment.screenshot">
                <p>
                  {{'USER_BOOKING_DETAILS.uploaded_payment_ss' | translate}}
                </p>
                <img [src]="booking.payment.screenshot" (click)="imgZoom(booking.payment.screenshot)">
              </div>
            </div>
          </ng-container>
          
          <ng-template #paidPartially>
            <h6>{{'USER_BOOKING_DETAILS.partial_payment_heading' | translate}}</h6>
  
            <!-- online mode -->
            <p>
              <span class="f-w-b">{{'USER_BOOKING_DETAILS.online_mode' | translate}}: </span>
              <span class="ion-text-capitalize">{{booking.payment.mode}}</span>
            </p>
            <p>
              <span class="f-w-b">{{'USER_BOOKING_DETAILS.amount' | translate}}: </span>
              <span>{{booking.payment.partial.online | currency: currencyCode:true:'.2-2'}}</span>
            </p>
            <p *ngIf="booking.payment?.status">
              <span class="f-w-b">{{'USER_BOOKING_DETAILS.status' | translate}}: </span>
              <span class="ion-text-capitalize">{{booking.payment.status}}</span>
            </p>
  
            <hr class="line">
  
            <!-- offline mode -->
            <p>
              <span class="f-w-b">{{'USER_BOOKING_DETAILS.offline_mode' | translate}}: </span>
              <span class="ion-text-capitalize">{{'USER_BOOKING_DETAILS.cash' | translate}}</span>
            </p>
            <p>
              <span class="f-w-b">{{'USER_BOOKING_DETAILS.amount' | translate}}: </span>
              <span>{{booking.payment.partial.cod | currency: currencyCode:true:'.2-2'}}</span>
            </p>
            <p>
              <span class="f-w-b">{{'USER_BOOKING_DETAILS.status' | translate}}: </span>
              <span class="ion-text-capitalize">{{booking.payment.partial.status}}</span>
            </p>     
          </ng-template>
          
          
        </div>
        <div class="separator"></div>
      </ng-container>
      <!-- payment info -->
  
      <!-- invoice info -->
      <ng-container *ngIf="booking?.invoice?.url">
        <div class="content">
          <h6>{{'USER_BOOKING_DETAILS.invoice' | translate}}</h6>
          <ion-item button (click)="viewInvoice()" detail lines="none"
            class="order-invoice ion-no-padding">
            <ion-label>
              <h4>{{'USER_BOOKING_DETAILS.view_invoice' | translate}}</h4>
            </ion-label>
          </ion-item>
        </div>
        <div class="separator"></div>
      </ng-container>
      <!-- invoice info -->
  
      <!-- custom input -->
      <ng-container *ngIf="isCustomInputAvailable()">
        <div class="content">
          <h6 class="ion-text-capitalize">{{booking.additionalInfo.customInput.label}}</h6>
          <p>{{booking.additionalInfo.customInput.value}}</p>
        </div>
        <div class="separator"></div>
      </ng-container>
      <!-- custom input -->
  
      <!-- booking info -->
      <div class="content booking-info">
        <h6>
          <span>
            {{'USER_BOOKING_DETAILS.booking_info' | translate}}
          </span>
          <span class="gst-inc-txt" *ngIf="!booking.item.gst.isExclusive">
            ({{'USER_BOOKING_DETAILS.gst_inc' | translate}})
          </span>
        </h6>
        <div class="name">
          <span>{{booking.item.name}}</span>
          <span>{{getBookingItemPrice() | currency: currencyCode:true:'.2-2'}}</span>
        </div>
  
        <div class="options" *ngIf="booking.item.addOns.selectedOptions.length">
          <ng-container *ngFor="let section of booking.item.addOns.selectedOptions">
            <ng-container *ngFor="let option of section.options">
              <div class="option">
                <span>{{option.name}}</span>
                <span>{{option.price | currency: currencyCode:true:'.2-2'}}</span>
              </div>
            </ng-container>
          </ng-container>
        </div>
  
        <div class="price-summary">
  
          <div class="d-flex" *ngIf="booking.payment.extraChargeOnPayment?.charge as extraCharge;">
            <span>{{booking.payment.extraChargeOnPayment.name || 'Payment Gateway Charge'}}</span>
            <span>{{extraCharge | currency: currencyCode:true:'.2-2'}}</span>
          </div>
  
          <div class="d-flex" *ngIf="booking.item.gst.isExclusive">
            <span>{{taxType}}</span>
            <span>{{getTotalAmount().totalGst | currency: currencyCode:true:'.2-2'}}</span>
          </div>
  
          <div class="total-price">
            <span>{{'BOOKING_SCHEDULE.total_price' | translate}}</span>
            <span>{{getTotalAmount().totalAmount | currency: currencyCode:true:'.2-2'}}</span>
          </div>
  
        </div>
      </div>
      <!-- booking info -->
    </ng-template>
  
  </ion-content>