import { Component, OnInit } from '@angular/core';
import { AlertController, Events, LoadingController, ModalController } from '@ionic/angular';
import { LabelService } from 'src/app/services/label/label.service';

@Component({
  selector: 'app-rate-order',
  templateUrl: './rate-order.page.html',
  styleUrls: ['./rate-order.page.scss'],
})
export class RateOrderPage implements OnInit {

  reviewPh = '';
  SHARED_LABELS: any;
  RATE_ORDER_LABELS: any;
  ratingData = {
    rating: 5,
    review: '',
    photos: [],
    createdAt: new Date()
  }
  loading: any;
  orderId: string;
  type:'';
  constructor(private modalController: ModalController,
              private events: Events,
              private labelService: LabelService,
              private loadingController: LoadingController,
              private alertController: AlertController) { }

  ngOnInit() {
    this.SHARED_LABELS = this.labelService.labels['SHARED'];
    this.RATE_ORDER_LABELS = this.labelService.labels['RATE_ORDER'];
    this.reviewPh = this.RATE_ORDER_LABELS['review_placeholder'];
  }

  async ionViewWillEnter() {
    this.initializeSubscriptions();
  }

  ionViewWillLeave() {
    this.removeSubscriptions();
  }

  initializeSubscriptions() {
    this.events.subscribe('rating:submitOrderRatingSuccess', () => {
      if(this.loading){ this.loading.dismiss() };
      this.presentAlert(this.RATE_ORDER_LABELS['thanks_for_rating'], true);
    });
  }

  closeModal(status?) {
   /* const data = status && status === 'order_rated' ? this.ratingData : null;
    if(data.photos.length) {
      data.photos.forEach(img => {
        img['thumb'] = img.url;
      });
    }
    this.modalController.dismiss(data);*/
    this.modalController.dismiss();
  }


  async submit() {
    await this.presentLoading();
    this.events.publish('rating:submitOrderRating', this.ratingData, this.orderId);
  }

  async rate(data: any) {
    this.ratingData.rating = data.rating;
    this.ratingData.review = data.review;
    this.ratingData.photos = data.photos;
    await this.submit();
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      message: this.SHARED_LABELS['please_wait'],
      duration: 10000,
    });
    await this.loading.present();
  }

  async presentAlert(msg: string, action: boolean) {
    const alert = await this.alertController.create({
      message: msg,
      backdropDismiss: false,
      buttons: [{
        text: this.SHARED_LABELS['ok'],
        handler: () => {
          if(action) {
            this.closeModal('order_rated');
          }
        }
      }]
    });
    await alert.present();
  }

  removeSubscriptions() {
    this.events.unsubscribe('rating:submitOrderRatingSuccess');
  }

}
