import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController, Events, AlertController, LoadingController, ToastController, Platform } from '@ionic/angular';
import { UserService } from '../services/user/user.service';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { CreateSubscriptionPage } from '../user-product-subscription/create-subscription/create-subscription.page';
import { ConfigService } from 'src/app/services/config/config.service';
import { SharedService } from '../services/shared/shared.service';
import { CartService } from '../services/cart/cart.service';
import { UtilsService } from '../services/utils/utils.service';
import { LabelService } from 'src/app/services/label/label.service';
import { SharedNewService } from '../services/shared-new/shared-new.service';
import { AppointmentSchedulesPage } from '../appointment-schedules/appointment-schedules.page';
var PricelistModalPage = /** @class */ (function () {
    function PricelistModalPage(modalController, events, alertController, loadingController, userService, router, storage, configService, toastController, sharedService, utilsService, labelService, platform, cartService, sharedNewService) {
        this.modalController = modalController;
        this.events = events;
        this.alertController = alertController;
        this.loadingController = loadingController;
        this.userService = userService;
        this.router = router;
        this.storage = storage;
        this.configService = configService;
        this.toastController = toastController;
        this.sharedService = sharedService;
        this.utilsService = utilsService;
        this.labelService = labelService;
        this.platform = platform;
        this.cartService = cartService;
        this.sharedNewService = sharedNewService;
        this.cartProducts = [];
        this.listOfWeights = [];
        this.mainListOfWeightsAndPrices = [];
        this.priceForRetail = false;
        this.SHARED_LABELS = {};
        this.PRICELIST_MODAL_LABLES = {};
        this.productURL = '';
    }
    PricelistModalPage.prototype.ngOnInit = function () {
        var _this = this;
        this.platform.ready().then(function () {
            var deviceWidth = _this.platform.width();
            if (deviceWidth > 786) {
                _this.useThumb = false;
            }
            else {
                _this.useThumb = true;
            }
        });
        this.currencyCode = this.configService.environment.currencyCode;
        this.priceForRetail = this.configService.environment.priceForRetail;
    };
    PricelistModalPage.prototype.modalDismiss = function () {
        this.modalController.dismiss();
    };
    PricelistModalPage.prototype.goToPage = function (page) {
        this.modalDismiss();
        this.router.navigate([page]);
    };
    PricelistModalPage.prototype.ionViewWillEnter = function () {
        this.userId = this.userService.getUserId();
        this.initialDisplayOfWeights();
        this.SHARED_LABELS = this.labelService.labels['SHARED'];
        this.PRICELIST_MODAL_LABLES = this.labelService.labels['SHARED'];
    };
    PricelistModalPage.prototype.getDiscountPercent = function (discountedPrice, prodPrice) {
        if (prodPrice > discountedPrice) {
            var discount = Math.round(((prodPrice - discountedPrice) / prodPrice) * 100);
            return discount;
        }
        else {
            return 0;
        }
    };
    PricelistModalPage.prototype.initialDisplayOfWeights = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var subRole, _a, _loop_1, this_1, _i, _b, pl;
            var _this = this;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.storage.get('userSubRole')];
                    case 1:
                        subRole = _c.sent();
                        _a = this.product;
                        return [4 /*yield*/, this.sharedService.updateProductPrices(this.product.data, subRole)];
                    case 2:
                        _a.data = _c.sent();
                        _loop_1 = function (pl) {
                            var index = this_1.cartProducts.findIndex(function (c) { return c.productId === _this.product.id && c.pack.weight === pl.weight; });
                            if (index !== -1) {
                                pl.cartQty = this_1.cartProducts[index].quantity;
                                pl.cartId = this_1.cartProducts[index].id;
                            }
                        };
                        this_1 = this;
                        for (_i = 0, _b = this.product.data.priceList; _i < _b.length; _i++) {
                            pl = _b[_i];
                            _loop_1(pl);
                        }
                        this.mainListOfWeightsAndPrices = this.product.data.priceList;
                        return [2 /*return*/];
                }
            });
        });
    };
    PricelistModalPage.prototype.addProductToCart = function (index) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var isCartAvailable, cartObj, cartId;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.cartService.checkCartAvailability(this.product.data.inactiveByVendor)];
                    case 1:
                        isCartAvailable = _a.sent();
                        if (!isCartAvailable) return [3 /*break*/, 3];
                        cartObj = this.cartService.getPriceListCartObj(this.product, index);
                        return [4 /*yield*/, this.cartService.addProductToCart(cartObj)];
                    case 2:
                        cartId = _a.sent();
                        this.mainListOfWeightsAndPrices[index].cartId = cartId;
                        this.mainListOfWeightsAndPrices[index].cartQty = 1;
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PricelistModalPage.prototype.decrementQuantity = function (index) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.mainListOfWeightsAndPrices[index].cartQty > 1)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.cartService.updateCartState(this.mainListOfWeightsAndPrices[index].cartId, this.mainListOfWeightsAndPrices[index].cartQty - 1)];
                    case 1:
                        res = _a.sent();
                        if (!res)
                            return [2 /*return*/];
                        this.mainListOfWeightsAndPrices[index].cartQty -= 1;
                        this.mainListOfWeightsAndPrices[index]['cartUpdateTimer'] = this.mainListOfWeightsAndPrices[index]['cartUpdateTimer'] || null;
                        clearTimeout(this.mainListOfWeightsAndPrices[index]['cartUpdateTimer']);
                        this.mainListOfWeightsAndPrices[index]['cartUpdateTimer'] = setTimeout(function () {
                            _this.cartService.updateCartProduct(_this.mainListOfWeightsAndPrices[index].cartId, _this.mainListOfWeightsAndPrices[index].cartQty);
                        }, 800);
                        return [3 /*break*/, 3];
                    case 2:
                        this.cartService.deleteCartProduct(this.mainListOfWeightsAndPrices[index].cartId);
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PricelistModalPage.prototype.incrementQuantity = function (index) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.cartService.updateCartState(this.mainListOfWeightsAndPrices[index].cartId, this.mainListOfWeightsAndPrices[index].cartQty + 1)];
                    case 1:
                        res = _a.sent();
                        if (!res)
                            return [2 /*return*/];
                        this.mainListOfWeightsAndPrices[index].cartQty += 1;
                        this.mainListOfWeightsAndPrices[index]['cartUpdateTimer'] = this.mainListOfWeightsAndPrices[index]['cartUpdateTimer'] || null;
                        clearTimeout(this.mainListOfWeightsAndPrices[index]['cartUpdateTimer']);
                        this.mainListOfWeightsAndPrices[index]['cartUpdateTimer'] = setTimeout(function () {
                            _this.cartService.updateCartProduct(_this.mainListOfWeightsAndPrices[index].cartId, _this.mainListOfWeightsAndPrices[index].cartQty);
                        }, 800);
                        return [2 /*return*/];
                }
            });
        });
    };
    PricelistModalPage.prototype.closePriceListModal = function () {
        this.modalController.dismiss();
    };
    PricelistModalPage.prototype.goToCart = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var isCartAvailable;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.cartService.checkCartAvailability()];
                    case 1:
                        isCartAvailable = _a.sent();
                        if (isCartAvailable) {
                            this.closePriceListModal();
                            this.router.navigate(['user-cart']);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    PricelistModalPage.prototype.presentAlert = function (msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            message: msg,
                            buttons: this.SHARED_LABELS['ok']
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PricelistModalPage.prototype.presentLoading = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loadingController.create({
                                message: this.SHARED_LABELS['please_wait'],
                                duration: 5000
                            })];
                    case 1:
                        _a.loading = _b.sent();
                        return [4 /*yield*/, this.loading.present()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PricelistModalPage.prototype.getDiscountedProduct = function (price, discount) {
        var discountedPrice = Math.ceil(price - (price * ((discount * 1) / 100)));
        return discountedPrice;
    };
    PricelistModalPage.prototype.maxQtyReached = function () {
        this.presentToast("" + this.PRICELIST_MODAL_LABLES['max_qty_reached']);
    };
    PricelistModalPage.prototype.presentToast = function (msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toast;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.toastController.create({
                            message: msg,
                            duration: 2000
                        })];
                    case 1:
                        toast = _a.sent();
                        toast.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    PricelistModalPage.prototype.subscribe = function (i) {
        this.product.data.prodPrice = this.product.data.priceList[i].price;
        this.product.data.discountedPrice = this.product.data.priceList[i].discountedPrice;
        this.product.data['variantValue'] = this.product.data.priceList[i].weight;
        this.product.data['productCode'] = 'sku' in this.product.data.priceList[i] ? this.product.data.priceList[i].sku : this.product.data.productCode ? this.product.data.productCode : '';
        this.product.data['barcodeNo'] = this.product.data.priceList[i].barcodeNo || '';
        this.closePriceListModal();
        this.modalController.create({
            component: CreateSubscriptionPage,
            cssClass: 'custom-modal',
            componentProps: {
                product: this.product,
                subSettings: this.subSettings,
            }
        }).then(function (modalEl) { modalEl.present(); });
    };
    PricelistModalPage.prototype.isProductInCart = function (product) {
        return this.cartService.isProductInCart(product);
    };
    PricelistModalPage.prototype.showQtyLeftMsg = function (qty) {
        return this.sharedNewService.showPriceListQtyLeftMsg(qty || 0);
    };
    PricelistModalPage.prototype.getPriceListWeight = function (element) {
        if (this.product.data.variantType !== 'pieces') {
            return element.weight;
        }
        else {
            if ('name' in element) {
                return element.name;
            }
            else {
                return element.weight;
            }
        }
    };
    PricelistModalPage.prototype.bookAppointment = function (index) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var variant, modal;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        variant = this.product.data.appointment.schedules.variant.filter(function (s) { return s.name === _this.product.data.priceList[index].weight; });
                        return [4 /*yield*/, this.modalController.create({
                                component: AppointmentSchedulesPage,
                                componentProps: {
                                    item: {
                                        name: this.product.data.prodName,
                                        price: this.product.data.priceList[index].discountedPrice,
                                        coverPic: this.product.data.coverPic,
                                        id: this.product.id,
                                        variant: this.product.data.priceList[index].weight
                                    },
                                    vendor: {
                                        id: this.product.data.vendorId || ''
                                    },
                                    maxDays: variant.length ? variant[0].maxDays : 0,
                                    schedules: variant.length ? variant[0].schedules : []
                                }
                            })];
                    case 1:
                        modal = _a.sent();
                        return [4 /*yield*/, modal.present()];
                    case 2:
                        _a.sent();
                        modal.onDidDismiss()
                            .then(function (data) {
                            if (data.data) {
                                if (data.data.booked) {
                                    setTimeout(function () {
                                        _this.modalDismiss();
                                    }, 100);
                                }
                            }
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    return PricelistModalPage;
}());
export { PricelistModalPage };
