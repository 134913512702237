import * as tslib_1 from "tslib";
import { NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Events, AlertController } from '@ionic/angular';
import { first } from 'rxjs/operators';
import { UserService } from '../user/user.service';
import { FirebaseAuthentication } from '@ionic-native/firebase-authentication/ngx';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import * as firebase from 'firebase';
import { CartService } from '../cart/cart.service';
import { ConfigService } from '../config/config.service';
import { SharedService } from '../shared/shared.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "@angular/fire/auth";
import * as i3 from "@ionic/angular";
import * as i4 from "../user/user.service";
import * as i5 from "@ionic-native/firebase-authentication/ngx/index";
import * as i6 from "@angular/router";
import * as i7 from "@ionic/storage";
import * as i8 from "../cart/cart.service";
import * as i9 from "../config/config.service";
import * as i10 from "../shared/shared.service";
var AuthService = /** @class */ (function () {
    function AuthService(afs, fireAuth, events, userService, firebaseAuthentication, router, ngZone, storage, cartService, configService, alertController, sharedService) {
        this.afs = afs;
        this.fireAuth = fireAuth;
        this.events = events;
        this.userService = userService;
        this.firebaseAuthentication = firebaseAuthentication;
        this.router = router;
        this.ngZone = ngZone;
        this.storage = storage;
        this.cartService = cartService;
        this.configService = configService;
        this.alertController = alertController;
        this.sharedService = sharedService;
        this.routeFromLoginPage = false;
        this.loggedOut = false;
    }
    AuthService.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('auth:login', function (phoneNo) {
            _this.login(phoneNo);
        });
        this.events.subscribe('auth:sendotp', function (otp) {
            _this.sendotp(otp);
        });
        this.events.subscribe('auth:checkUserStatus', function (phoneNo) {
            _this.checkUserStatus(phoneNo);
        });
        this.fireAuth.authState.subscribe(function (user) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var usersData;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.cartService.setCartProducts();
                        if (!user) return [3 /*break*/, 2];
                        this.storage.set('uid', user.uid);
                        this.userService.addUserId(user.uid);
                        this.userService.addPhoneNo(user.phoneNumber);
                        return [4 /*yield*/, this.afs.doc("users/" + user.uid).valueChanges().pipe(first()).toPromise()];
                    case 1:
                        usersData = _a.sent();
                        if (usersData) {
                            this.storage.set('userName', usersData.name);
                            this.storage.set('userPic', usersData.dP);
                            this.storage.set('userRole', usersData.role);
                            this.storage.set('userDefaultAddress', usersData.defaultAddress);
                            if (this.configService.environment.priceReqFeature) {
                                if (usersData.hasOwnProperty('showPrices')) {
                                    this.storage.set('showPrices', usersData.showPrices);
                                }
                                else {
                                    this.storage.set('showPrices', false);
                                }
                            }
                            if (this.configService.environment.appAccessOnApproval) {
                                this.storage.set('accessByAdmin', 'accessByAdmin' in usersData ? usersData.accessByAdmin : false);
                            }
                            if (usersData.hasOwnProperty('subRole')) {
                                this.storage.set('userSubRole', usersData.subRole);
                            }
                            this.userService.addUserName(usersData.name);
                            if (usersData.email) {
                                this.userService.addUserEmail(usersData.email);
                            }
                            this.saveMetaData(user.uid);
                        }
                        this.events.publish('wishlist:getWishlist');
                        this.cartService.setCartProducts();
                        return [3 /*break*/, 3];
                    case 2:
                        if (this.configService.environment.priceReqFeature) {
                            this.storage.set('showPrices', false);
                        }
                        _a.label = 3;
                    case 3:
                        setTimeout(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!this.configService.environment.appAccessOnApproval) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this.sharedService.checkForAppAccesss()];
                                    case 1:
                                        _a.sent();
                                        _a.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        }); }, 2000);
                        this.events.publish('admin-settings:getStoreInfo');
                        this.events.publish('auth:hideSplashScreen');
                        return [2 /*return*/];
                }
            });
        }); });
        this.events.subscribe('auth:logout', function () {
            _this.loggedOut = true;
            _this.logout();
        });
        this.events.subscribe('auth:fbLogin', function (phoneNo) {
            _this.fbLogin(phoneNo);
        });
        this.events.subscribe('auth:googleLogin', function (phoneNo) {
            _this.googleLogin(phoneNo);
        });
    };
    AuthService.prototype.saveMetaData = function (uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var analytics;
            return tslib_1.__generator(this, function (_a) {
                this.afs.doc("users/" + uid).update({ lastAccessAt: new Date() });
                analytics = {
                    lastAccessAt: new Date(),
                };
                this.afs.collection('users').doc(uid).collection('analytics').doc('access').collection('data').doc('web').set(analytics);
                return [2 /*return*/];
            });
        });
    };
    AuthService.prototype.checkUserStatus = function (phoneNo) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var checkUserExistenceByPhone;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    checkUserExistenceByPhone = firebase.functions().httpsCallable('users-checkUserExistenceByPhone');
                    checkUserExistenceByPhone(phoneNo).then(function (res) {
                        var resData = res.data;
                        if (resData.isExist) {
                            _this.events.publish('auth:phoneAlreadyInUse', resData.mode);
                        }
                        else {
                            _this.events.publish('auth:newUser');
                        }
                    });
                }
                catch (error) {
                    console.dir(error);
                }
                return [2 /*return*/];
            });
        });
    };
    AuthService.prototype.fbLogin = function (phoneNo) {
        var provider = new firebase.auth.FacebookAuthProvider();
        this.socialSignin(provider, phoneNo, 'facebook');
    };
    AuthService.prototype.googleLogin = function (phoneNo) {
        var provider = new firebase.auth.GoogleAuthProvider();
        this.socialSignin(provider, phoneNo, 'google');
    };
    AuthService.prototype.socialSignin = function (provider, phoneNo, type) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                firebase.auth().signInWithPopup(provider).then(function (result) {
                    var token = result.credential.accessToken;
                    var user = result.user;
                    _this.userService.socialSignInUserCheck(user.uid, user.displayName, user.email, phoneNo, type);
                }).catch(function (error) {
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    alert(errorMessage);
                    var email = error.email;
                    var credential = error.credential;
                });
                return [2 /*return*/];
            });
        });
    };
    AuthService.prototype.login = function (phoneNo) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.firebaseAuthentication.verifyPhoneNumber(phoneNo, 0).then(function (verificationId) {
                    _this.verificationId = verificationId;
                    _this.events.publish('auth:gotVerificationId');
                    _this.userService.addPhoneNo(phoneNo);
                    _this.routeFromLoginPage = true;
                }).catch(function (err) {
                    // console.log(err);
                    if (err = "We have blocked all requests from this device due to unusual activity. Try again later.") {
                        _this.events.publish('auth:blockUserForTooManyOTP');
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    AuthService.prototype.sendotp = function (otp) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var credential;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, firebase.auth.PhoneAuthProvider.credential(this.verificationId, otp)];
                    case 1:
                        credential = _a.sent();
                        firebase.auth().signInWithCredential(credential).then(function (res) {
                            _this.userService.checkUserAlreadyExistsOrNot(res.user.uid);
                        }).catch(function (err) {
                            if (err === "The user account has been disabled by an administrator.") {
                                _this.events.publish('auth:incorrectOTP', 'Admin has blocked you!');
                            }
                            else {
                                _this.events.publish('auth:incorrectOTP', 'OTP did not match');
                            }
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.logout = function () {
        var _this = this;
        try {
            this.storage.get('storeInfo').then(function (storeInfo) {
                _this.storage.get('checkPincode').then(function (isPincodeChecked) {
                    _this.storage.get('deliverySettings').then(function (deliverySettings) {
                        _this.storage.clear();
                        _this.storage.set('storeInfo', storeInfo);
                        _this.storage.set('checkPincode', isPincodeChecked);
                        _this.storage.set('deliverySettings', deliverySettings);
                        _this.ngZone.run(function () {
                            _this.router.navigate(['']);
                            ;
                        });
                    });
                });
            });
            this.fireAuth.auth.signOut();
            this.firebaseAuthentication.signOut();
            this.userService.addUserId('');
            this.userService.addPhoneNo('');
            this.cartService.cartProducts = [];
            this.cartService.cartProductsUpdated.next([]);
            this.events.publish('auth:logoutSuccess');
            this.logoutAlert();
            this.router.navigate(['/']).then(function () {
                window.location.reload();
            });
        }
        catch (err) {
            console.dir(err);
        }
    };
    AuthService.prototype.logoutAlert = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            message: 'You logout successfully',
                            buttons: ['OK']
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.AngularFireAuth), i0.ɵɵinject(i3.Events), i0.ɵɵinject(i4.UserService), i0.ɵɵinject(i5.FirebaseAuthentication), i0.ɵɵinject(i6.Router), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i7.Storage), i0.ɵɵinject(i8.CartService), i0.ɵɵinject(i9.ConfigService), i0.ɵɵinject(i3.AlertController), i0.ɵɵinject(i10.SharedService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
