import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { WidgetsService } from '../services/widgets/widgets.service';

@Component({
  selector: 'app-offer-details',
  templateUrl: './offer-details.page.html',
  styleUrls: ['./offer-details.page.scss'],
})
export class OfferDetailsPage implements OnInit {
  offerSlideOpts = {
    initialSlide: 0,
    speed: 400,
    disableOnInteraction: false,
    autoplay: {
      delay: 5000
    }
  };
offerData
  constructor(private modalController: ModalController,public navParams: NavParams, private widgetService: WidgetsService) {
    this.offerData = navParams.get('data');
   }

  ngOnInit() {
  }

  modalDismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  goToOffer(link: any) {
    this.modalDismiss();
    // console.log('link', link);
    this.widgetService.bannerLink(link);
  }

}
