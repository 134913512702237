import * as tslib_1 from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import { first, map } from 'rxjs/operators';
import { convertSnaps } from '../db-utils';
import { SharedService } from '../shared/shared.service';
import { VendorService } from '../vendor/vendor.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "../shared/shared.service";
import * as i3 from "../vendor/vendor.service";
var AppointmentService = /** @class */ (function () {
    function AppointmentService(afs, sharedService, vendorService) {
        this.afs = afs;
        this.sharedService = sharedService;
        this.vendorService = vendorService;
    }
    AppointmentService.prototype.bookAppointment = function (data, slotLimit) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var slotAvailable, appointments, vendorDetails, vendorAppointments, error_1;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 18, , 19]);
                                    slotAvailable = false;
                                    appointments = [];
                                    if (!('variant' in data.item)) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.afs.collection('appointments', function (ref) { return ref
                                            .where('item.id', '==', data.item.id)
                                            .where('item.variant', '==', data.item.variant)
                                            .where('schedule.date', '==', data.schedule.date)
                                            .where('schedule.slot.start', '==', data.schedule.slot.start)
                                            .where('schedule.slot.end', '==', data.schedule.slot.end)
                                            .where('status', '==', 'accepted'); })
                                            .valueChanges().pipe(first()).toPromise()];
                                case 1:
                                    appointments = _a.sent();
                                    return [3 /*break*/, 4];
                                case 2: return [4 /*yield*/, this.afs.collection('appointments', function (ref) { return ref
                                        .where('item.id', '==', data.item.id)
                                        .where('schedule.date', '==', data.schedule.date)
                                        .where('schedule.slot.start', '==', data.schedule.slot.start)
                                        .where('schedule.slot.end', '==', data.schedule.slot.end)
                                        .where('status', '==', 'accepted'); })
                                        .valueChanges().pipe(first()).toPromise()];
                                case 3:
                                    appointments = _a.sent();
                                    _a.label = 4;
                                case 4:
                                    if (!(appointments.length < slotLimit)) return [3 /*break*/, 13];
                                    if (!this.vendorService.vendorSettings.isMultiVendorAvailable) return [3 /*break*/, 11];
                                    if (!data.vendor.id) return [3 /*break*/, 9];
                                    return [4 /*yield*/, this.vendorService.getVendorData(data.vendor.id)];
                                case 5:
                                    vendorDetails = _a.sent();
                                    if (!(vendorDetails && 'appointmentSlotLimit' in vendorDetails && vendorDetails.appointmentSlotLimit)) return [3 /*break*/, 7];
                                    return [4 /*yield*/, this.afs.collection('appointments', function (ref) { return ref
                                            .where('vendor.id', '==', data.vendor.id)
                                            .where('schedule.date', '==', data.schedule.date)
                                            .where('schedule.slot.start', '==', data.schedule.slot.start)
                                            .where('schedule.slot.end', '==', data.schedule.slot.end)
                                            .where('status', '==', 'accepted'); })
                                            .valueChanges().pipe(first()).toPromise()];
                                case 6:
                                    vendorAppointments = _a.sent();
                                    if (vendorAppointments.length < vendorDetails.appointmentSlotLimit) {
                                        slotAvailable = true;
                                    }
                                    else {
                                        slotAvailable = false;
                                    }
                                    return [3 /*break*/, 8];
                                case 7:
                                    slotAvailable = true;
                                    _a.label = 8;
                                case 8: return [3 /*break*/, 10];
                                case 9:
                                    slotAvailable = true;
                                    _a.label = 10;
                                case 10: return [3 /*break*/, 12];
                                case 11:
                                    slotAvailable = true;
                                    _a.label = 12;
                                case 12: return [3 /*break*/, 14];
                                case 13:
                                    slotAvailable = false;
                                    _a.label = 14;
                                case 14:
                                    if (!slotAvailable) return [3 /*break*/, 16];
                                    return [4 /*yield*/, this.afs.collection('appointments').add(data)];
                                case 15:
                                    _a.sent();
                                    resolve('added');
                                    return [3 /*break*/, 17];
                                case 16:
                                    resolve('not_available');
                                    _a.label = 17;
                                case 17: return [3 /*break*/, 19];
                                case 18:
                                    error_1 = _a.sent();
                                    // console.log(error);
                                    resolve('error');
                                    return [3 /*break*/, 19];
                                case 19: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    AppointmentService.prototype.getUserAppointments = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var userId_1, appointments, error_2;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 3, , 4]);
                                    return [4 /*yield*/, this.sharedService.getStorageUid()];
                                case 1:
                                    userId_1 = _a.sent();
                                    return [4 /*yield*/, this.afs.collection('appointments', function (ref) { return ref
                                            .orderBy('createdAt', 'desc')
                                            .where('user.id', '==', userId_1); })
                                            .snapshotChanges()
                                            .pipe(map(function (snaps) { return convertSnaps(snaps); }))
                                            .pipe(first()).toPromise()];
                                case 2:
                                    appointments = _a.sent();
                                    resolve(appointments);
                                    return [3 /*break*/, 4];
                                case 3:
                                    error_2 = _a.sent();
                                    // console.log(error);
                                    resolve([]);
                                    return [3 /*break*/, 4];
                                case 4: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    AppointmentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppointmentService_Factory() { return new AppointmentService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.SharedService), i0.ɵɵinject(i3.VendorService)); }, token: AppointmentService, providedIn: "root" });
    return AppointmentService;
}());
export { AppointmentService };
