import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { LabelService } from 'src/app/services/label/label.service';
import { Events } from '@ionic/angular';
import { SharedService } from 'src/app/services/shared/shared.service';
import { ModalController } from '@ionic/angular';
var CustomPaymentOptionPage = /** @class */ (function () {
    function CustomPaymentOptionPage(labelService, events, sharedService, modalController) {
        this.labelService = labelService;
        this.events = events;
        this.sharedService = sharedService;
        this.modalController = modalController;
        this.userScreenShot = '';
        this.textDetails = '';
        this.successRoute = 'tabs/tabs/user-order-history';
        this.pageType = 'page';
    }
    CustomPaymentOptionPage.prototype.ngOnInit = function () {
        this.SHARED_LABELS = this.labelService.labels['SHARED'];
        this.CUSTOM_PAYMENT_OPTION_LABELS = this.labelService.labels['CUSTOM_PAYMENT_OPTION'];
        this.headerText = this.customOption.name;
        if (this.customOption.successRoute) {
            this.successRoute = this.customOption.successRoute;
        }
    };
    CustomPaymentOptionPage.prototype.uploadPaymentSS = function (files) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var i, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        i = 0;
                        _b.label = 1;
                    case 1:
                        if (!(i < files.length)) return [3 /*break*/, 4];
                        _a = this;
                        return [4 /*yield*/, this.readBase64(files.item(i))];
                    case 2:
                        _a.userScreenShot = _b.sent();
                        _b.label = 3;
                    case 3:
                        i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CustomPaymentOptionPage.prototype.readBase64 = function (file) {
        var _this = this;
        return new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var reader;
            return tslib_1.__generator(this, function (_a) {
                reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function (event) {
                    resolve(event.target.result);
                };
                return [2 /*return*/];
            });
        }); });
    };
    CustomPaymentOptionPage.prototype.removeSS = function () {
        this.userScreenShot = '';
    };
    CustomPaymentOptionPage.prototype.completePayment = function () {
        if (this.customOption.textMandatory && !this.textDetails) {
            this.sharedService.presentAlert(this.CUSTOM_PAYMENT_OPTION_LABELS['add_text_alert']);
            return;
        }
        if (this.customOption.imageMandatory && !this.userScreenShot) {
            this.sharedService.presentAlert(this.CUSTOM_PAYMENT_OPTION_LABELS['upload_img_alert']);
            return;
        }
        var data = { textDetails: this.textDetails, image: this.userScreenShot, optionName: this.customOption.name };
        if (this.pageType == 'modal') {
            this.modalController.dismiss({ closed: false, response: data });
        }
        else {
            this.events.publish('order:ac_completeCustomOptionPayment', this.order, data);
        }
    };
    CustomPaymentOptionPage.prototype.closeModal = function () {
        this.modalController.dismiss({ closed: true });
    };
    return CustomPaymentOptionPage;
}());
export { CustomPaymentOptionPage };
