import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Events } from '@ionic/angular';
import * as firebase from 'firebase';
import { first, map } from 'rxjs/operators';
import { LogglyLoggerService } from '../loggly-logger/loggly-logger.service';

@Injectable({
  providedIn: 'root'
})
export class MultiRegionService {
  multiRegionRef = this.afs.collection('features').doc('multiRegion').collection('regions');
  constructor(private events: Events,
              private afs: AngularFirestore,
              private logglyService: LogglyLoggerService) { }

  initializeSubscriptions() {
    this.events.subscribe('multi-region:saveRegion', (regionData) => {
      this.saveRegion(regionData);
    });
    this.events.subscribe('multi-region:toggleMultiRegionActive', (status) => {
      this.toggleMultiRegionActive(status);
    });
    this.events.subscribe('multi-region:getActiveStatus', () => {
      this.getActiveStatus();
    });
    this.events.subscribe('multi-region:getAllRegions', () => {
      this.getAllRegions();
    });
    this.events.subscribe('multi-region:toggleRegionActive', (status, id) => {
      this.toggleRegionActive(status, id);
    });
    this.events.subscribe('multi-region:deleteRegion', (id) => {
      this.deleteRegion(id);
    });
    this.events.subscribe('multi-region:getAllActiveRegions', () => {
      this.getAllActiveRegions();
    });
  }

  async saveRegion(regionData: any) {
    try {
      let regionId = '';
      let regionClone = JSON.parse(JSON.stringify(regionData));
      if(regionClone.hasOwnProperty('id')) {
        regionId = regionClone.id;
        delete regionData.id;
      } else {
        regionId = this.multiRegionRef.ref.doc().id;
      }
      regionData['createdAt'] = new Date();
      await this.multiRegionRef.doc(regionId).set(regionData);
      this.events.publish('multi-region:regionSaved');
      this.events.publish('multi-region:getAllRegions');
    } catch (error) {
      console.dir(error);
      error['location'] = 'multi-region-service:saveRegion'; 
      this.logglyService.log(error);
    }
  }

  async toggleMultiRegionActive(status: boolean) {
    try {
      await this.afs.collection('features').doc('multiRegion').set({active:status});
      this.events.publish('multi-region:multiRegionActiveChanged');
    } catch (error) {
      console.dir(error);
      error['location'] = 'multi-region-service:toogleMultiRegionActive'; 
      this.logglyService.log(error);
    }
  }

  async getActiveStatus(route?) {
    try {
      const multiRegionDoc = await this.afs.collection('features').doc('multiRegion').valueChanges().pipe(first()).toPromise();
      if(route === 'service') {
        return multiRegionDoc;
      } else {
        this.events.publish('multi-region:publishActiveStatus', multiRegionDoc);
      }
    } catch (error) {
      console.dir(error);
      error['location'] = 'multi-region-service:getActiveStatus'; 
      this.logglyService.log(error);
    }
  }

  async getAllRegions() {
    try {
      const multiRegions = await this.afs.collection('features').doc('multiRegion').collection('regions', ref => ref
      .orderBy('createdAt', 'desc')).snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        }))
      ).pipe(first()).toPromise();
      // console.log('multiRegions', multiRegions);
      this.events.publish('multi-region:publishAllRegions', multiRegions);
    } catch (error) {
      console.dir(error);
      error['location'] = 'multi-region-service:getAllRegions'; 
      this.logglyService.log(error);
    }
  }

  async toggleRegionActive(status, id) {
    try {
      await this.multiRegionRef.doc(id).update({active: status});
      this.events.publish('multi-region:regionActiveChanged');
    } catch (error) {
      console.dir(error);
      error['location'] = 'multi-region-service:toggleRegionActive'; 
      this.logglyService.log(error);
    }
  }

  async deleteRegion(id) {
    try {
      await this.multiRegionRef.doc(id).delete();
      this.events.publish('multi-region:regionDeleted');
      this.events.publish('multi-region:getAllRegions');
    } catch (error) {
      console.dir(error);
      error['location'] = 'multi-region-service:deleteRegion'; 
      this.logglyService.log(error);
    }
  }

  async getAllActiveRegions(callType?: string) {
    try {
      const multiRegions = await this.afs.collection('features').doc('multiRegion').collection('regions', ref => ref
      .where('active', '==', true)).snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        }))
      ).pipe(first()).toPromise();
      // console.log('multiRegions', multiRegions);
      if(callType && callType === 'return') {
        return multiRegions;
      } else {
        this.events.publish('multi-region:publishAllActiveRegions', multiRegions);
      }
    } catch (error) {
      console.dir(error);
      error['location'] = 'multi-region-service:getAllRegions'; 
      this.logglyService.log(error);
    }
  }

  async getNearestStoreRegion(address: any) {
    return new Promise(async (resolve, reject) => {
      try {
        const regions = await this.getAllActiveRegions('return');
        const storeRegion = firebase.functions().httpsCallable('orders-getNearestStoreRegion');
        storeRegion({address, regions}).then((res: any) => {
          resolve(res.data);
        });
      } catch (error) {
        // console.log(error);
        error['location'] = 'multi-region-service:getNearestStoreRegion'; 
        this.logglyService.log(error);
        resolve({status: 'failure'});
      }
    })
  }
}
