import { Storage } from '@ionic/storage';
import { Injectable } from '@angular/core';
import { Events } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { first, map } from 'rxjs/operators';
import * as firebase from 'firebase';
import { LogglyLoggerService } from '../loggly-logger/loggly-logger.service';
import { convertSnaps } from '../db-utils';

@Injectable({
  providedIn: 'root'
})
export class DeliverySettingsService {
  deliveryRef = this.afs.collection('features').doc('delivery');
  constructor(private events: Events,
              private afs: AngularFirestore,
              private storage: Storage,
              private logglyService: LogglyLoggerService) { }

  initializeSubscriptions() {
    this.events.subscribe('delivery-settings:saveDeliverySettings', (data) => {
      this.saveDeliverySettings(data);
    });
    this.events.subscribe('delivery-settings:getDeliverySettingsData', () => {
      this.getDeliverySettingsData();
    });
    this.events.subscribe('delivery-settings:getOrderPaymentDetails', (address, products, isGstApplicable, customDeliverySettings, additionalInfo) => {
      this.getOrderPaymentDetails(address, products, isGstApplicable, customDeliverySettings, additionalInfo);
    });
    this.events.subscribe('delivery-settings:checkPincode', (pincode) => {
      this.checkPincode(pincode);
    });
    this.events.subscribe('delivery-settings:getSlotsWithDate', (date) => {
      this.getSlotsWithDate(date);
    });
    this.events.subscribe('delivery-settings:updateSlot', (data) => {
      this.updateSlot(data);
    });
    this.events.subscribe('delivery-settings:getManageSlots', () => {
      this.getManageSlots();
    });
  }

  async saveDeliverySettings(data: any) {
    try {
      await this.deliveryRef.set(data);
      this.events.publish('delivery-settings:saveDeliverySettingsSuccess');
    } catch (error) {
      console.dir(error);
      error['location'] = 'delivery-settings-service:saveDeliverySettings'; 
      this.logglyService.log(error);
    }
  }

  async getDeliverySettingsData() {
    try {
      const deliveryData: any = await this.deliveryRef.valueChanges().pipe(first()).toPromise();
      this.events.publish('delivery-settings:publishDeliverySettingsData', deliveryData);
    } catch (error) {
      console.dir(error);
      error['location'] = 'delivery-settings-service:getDeliverySettingsData'; 
      this.logglyService.log(error);
    }
  }


  async getDeliverySettingsDataInSessionStorage() {
    return new Promise(async (resolve, reject) => {
        let data = JSON.parse(sessionStorage.getItem("DeliverySettingsData")) || {};
        if (!(Object.keys(data).length)) {
          data = await this.deliveryRef.valueChanges().pipe(first()).toPromise();
            sessionStorage.setItem("DeliverySettingsData", JSON.stringify(data));
        }
        resolve(data);
    });
}

  async deliverySettingsDataInStorage() {
    try {
      const deliveryData: any = await this.deliveryRef.valueChanges().pipe(first()).toPromise();
      this.storage.set('deliverySettings', deliveryData);
    } catch (error) {
      console.dir(error);
      error['location'] = 'delivery-settings-service:deliverySettingsDataInStorage'; 
      this.logglyService.log(error);
    }
  }

  async getOrderPaymentDetails(address, products, isGstApplicable, customDeliverySettings, additionalInfo) {
    try {
      // console.log('customDeliverySettings', customDeliverySettings);
      let orderPaymentDetails = firebase.functions().httpsCallable('orders-getOrderPaymentDetails');
      orderPaymentDetails({address, products, isGstApplicable, customDeliverySettings, additionalInfo}).then((response) => {
        // // console.log('response orderPaymentDetails', response.data);
        this.events.publish('delivery-settings:publishOrderPaymentDetails', response.data);
      });
    } catch (error) {
      console.dir(error);
      error['location'] = 'delivery-settings-service:getOrderPaymentDetails'; 
      this.logglyService.log(error);
    }
  }

  async checkPincode(pincode: number) {
    try {
      const deliveryData: any = await this.deliveryRef.valueChanges().pipe(first()).toPromise();
      const pincodes = deliveryData.deliveryPincodes;
      let isAvailable = false;
      for (const p of pincodes) {
        if (p.pincode == pincode) {
          isAvailable = true;
          break;
        }
      }
      if (deliveryData.isAllowAllPincodes) {
        this.events.publish('delivery-settings:pincodeDeliverable');
      } else {
        if (isAvailable) {
          this.events.publish('delivery-settings:pincodeDeliverable');
        } else {
          this.events.publish('delivery-settings:pincodeNotDeliverable');
        }
      }
    } catch (error) {
      console.dir(error);
      error['location'] = 'delivery-settings-service:checkPincode'; 
      this.logglyService.log(error);
    }
  }

  async getSlotsWithDate(date: any) {
    try {
      const slotsDoc: any = await this.deliveryRef.collection('manageSlots').doc(date).valueChanges().pipe(first()).toPromise();
      this.events.publish('delivery-settings:slotsWithDate', slotsDoc);
    } catch (error) {
      console.dir(error);
      error['location'] = 'delivery-settings-service:getSlotsWithDate'; 
      this.logglyService.log(error);
    }
  }

  async updateSlot(slotData: any) {
    try {
      const date = slotData.date.toDateString();
      await this.deliveryRef.collection('manageSlots').doc(date).set({slots: slotData.slots});
      this.events.publish('delivery-settings:slotUpdated');
    } catch (error) {
      console.dir(error);
      error['location'] = 'delivery-settings-service:updateSlot'; 
      this.logglyService.log(error);
    }
    
  }

  async getManageSlots() {
    try {
      const manageSlots = await this.deliveryRef.collection('manageSlots').snapshotChanges().pipe(
        map((actions: any) => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        }))
      ).pipe(first()).toPromise();
      this.events.publish('delivery-settings:publishManageSlots', manageSlots);
    } catch (error) {
      console.dir(error);
      error['location'] = 'delivery-settings-service:getManageSlots'; 
      this.logglyService.log(error);
    }
  }

  async getCustomDelivery() {
    return new Promise<any>(async (resolve) => {
      const customDelivery = await this.afs.collection('features').doc('delivery').collection('custom')
      .snapshotChanges()
      .pipe(map(snaps => convertSnaps(snaps)))
      .pipe(first()).toPromise();
      resolve(customDelivery || []);
    });
  }
}
