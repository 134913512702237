import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavController } from '@ionic/angular';
import { LabelService } from 'src/app/services/label/label.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { WidgetsService } from 'src/app/services/widgets/widgets.service';
import { DomSanitizer } from '@angular/platform-browser';
var WidgetFormComponent = /** @class */ (function () {
    function WidgetFormComponent(widgetsService, sharedService, labelService, domSanitizer, navCtrl, modalController) {
        this.widgetsService = widgetsService;
        this.sharedService = sharedService;
        this.labelService = labelService;
        this.domSanitizer = domSanitizer;
        this.navCtrl = navCtrl;
        this.modalController = modalController;
        this.source = 'page';
        this.formData = {
            formTitle: '',
            banner: { mob: '', thumb: '', url: '' },
            inputs: [],
            buttonName: '',
            multipleSubmissions: false
        };
        this.isSubmitted = false;
    }
    WidgetFormComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var widgetData, _i, _a, key, response, group_1, msg;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.CO_WIDGET_FORM_LABELS = this.labelService.labels['CO_WIDGET_FORM'];
                        return [4 /*yield*/, this.widgetsService.getWidgetData(this.widgetID)];
                    case 1:
                        widgetData = _b.sent();
                        if (widgetData) {
                            for (_i = 0, _a = Object.keys(this.formData); _i < _a.length; _i++) {
                                key = _a[_i];
                                this.formData[key] = widgetData[key];
                            }
                            if (widgetData && widgetData.style && Object.keys(widgetData.style).length != 0) {
                                this.widgetStyle = widgetData.style;
                                if (this.widgetStyle.backgroundImage) {
                                    this.background = this.domSanitizer.bypassSecurityTrustStyle("url(" + this.widgetStyle.backgroundImage + ")");
                                }
                                else if (this.widgetStyle.backgroundColor) {
                                    this.background = this.widgetStyle.backgroundColor;
                                }
                                else {
                                    //this.background = 'transparent';
                                }
                            }
                            this.formType = "formType" in widgetData ? widgetData.formType : "others";
                        }
                        return [4 /*yield*/, this.widgetsService.checkFormAlreadySubmitted(this.formData.multipleSubmissions)];
                    case 2:
                        response = _b.sent();
                        this.response = response;
                        if (response === 'not_submitted') {
                            //making form controls
                            if (this.formData.formTitle) {
                                group_1 = {};
                                this.formData.inputs.forEach(function (input) {
                                    var validators = [];
                                    if (input.required) {
                                        validators.push(Validators.required);
                                    }
                                    if (input.type === 'email') {
                                        validators.push(Validators.email);
                                    }
                                    group_1[input.name] = new FormControl('', validators);
                                });
                                this.dynamicForm = new FormGroup(group_1);
                            }
                        }
                        else {
                            msg = '';
                            switch (response) {
                                case 'error':
                                    msg = this.labelService.labels['SHARED']['some_issue_msg'];
                                    break;
                                case 'submitted':
                                    msg = this.CO_WIDGET_FORM_LABELS['already_submitted'];
                                    break;
                                // case 'no_login':
                                //     msg = this.CO_WIDGET_FORM_LABELS['login_to_continue'];
                                //     break;
                            }
                            if (msg != '') {
                                this.sharedService.presentAlert(msg);
                                if (this.source === 'page') {
                                    this.navCtrl.navigateRoot(['']);
                                }
                                else {
                                    this.modalController.dismiss();
                                }
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    WidgetFormComponent.prototype.getControl = function (formControlName) {
        return this.dynamicForm.get(formControlName);
    };
    WidgetFormComponent.prototype.onSubmit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, _a, response;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this.response == 'no_login') {
                            this.sharedService.presentAlert(this.CO_WIDGET_FORM_LABELS['login_to_continue']);
                            return [2 /*return*/];
                        }
                        this.isSubmitted = true;
                        if (!!this.dynamicForm.valid) return [3 /*break*/, 1];
                        this.sharedService.presentAlert('Please fill all the fields');
                        return [2 /*return*/];
                    case 1: return [4 /*yield*/, this.sharedService.presentLoading(1000000)];
                    case 2:
                        _b.sent();
                        _a = {
                            formData: this.dynamicForm.value,
                            widgetId: this.widgetID
                        };
                        return [4 /*yield*/, this.sharedService.getStorageUid()];
                    case 3:
                        _a.userId = _b.sent(),
                            _a.createdAt = new Date(),
                            _a.formType = this.formType;
                        return [4 /*yield*/, this.sharedService.getStorageUserName()];
                    case 4:
                        data = (_a.name = _b.sent(),
                            _a);
                        return [4 /*yield*/, this.widgetsService.submitForm(data)];
                    case 5:
                        response = _b.sent();
                        this.sharedService.loading.dismiss();
                        if (response) {
                            this.sharedService.presentAlert(this.CO_WIDGET_FORM_LABELS['submitted_msg']);
                            if (this.source === 'page') {
                                this.navCtrl.navigateRoot(['']);
                            }
                            else {
                                this.modalController.dismiss();
                            }
                        }
                        else {
                            this.sharedService.presentAlert(this.CO_WIDGET_FORM_LABELS['issue_in_submitting_form']);
                        }
                        _b.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    WidgetFormComponent.prototype.getBannerSrc = function () {
        return this.formData.banner.mob ? 'mob' : 'url';
    };
    WidgetFormComponent.prototype.isValid = function (name) {
        var control = this.getControl(name);
        return this.isSubmitted && (control.errors && (control.errors.required || control.errors.email)) ? false : true;
    };
    WidgetFormComponent.prototype.resetForm = function () {
        this.isSubmitted = false;
        this.dynamicForm.reset();
    };
    WidgetFormComponent.prototype.chooseFile = function (name, files) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var i, base64Image, control;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        i = 0;
                        _a.label = 1;
                    case 1:
                        if (!(i < files.length)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.readBase64(files.item(i))];
                    case 2:
                        base64Image = _a.sent();
                        control = this.getControl(name);
                        control.setValue(base64Image);
                        _a.label = 3;
                    case 3:
                        i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    WidgetFormComponent.prototype.readBase64 = function (file) {
        var _this = this;
        return new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var reader;
            return tslib_1.__generator(this, function (_a) {
                reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function (event) {
                    resolve(event.target.result);
                };
                return [2 /*return*/];
            });
        }); });
    };
    return WidgetFormComponent;
}());
export { WidgetFormComponent };
