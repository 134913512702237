<ion-content class="page-content" *ngIf="name">

  <div class="header">
    <h6>{{'REPEAT_ADDON_CHOICE.header_text' | translate}}</h6>
    <ion-icon name="close" (click)="closeModal()"></ion-icon>
  </div>

  <div class="addons-info">
    <div class="product-name">
      {{name}}
    </div>
    <div class="addons">
      <ng-container *ngFor="let addOn of lastAddon; let i=index">
        <ng-container *ngFor="let option of addOn.options; let j=index">
          <span> {{option.name}} </span>
          <span *ngIf="i !== lastAddon.length -1 || j !== addOn.options.length -1">+</span>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div class="action-btns">
    <ion-button (click)="saveChoice('new')" expand="block" fill="outline" class="footer-button">
      {{'REPEAT_ADDON_CHOICE.add_new' | translate}}
    </ion-button>
    <ion-button (click)="saveChoice('repeat')" expand="block" fill="outline" class="footer-button">
      {{'REPEAT_ADDON_CHOICE.repeat_last' | translate}}
    </ion-button>
  </div>
</ion-content>