import * as tslib_1 from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { first, map } from 'rxjs/operators';
import { BookingSchedulePage } from 'src/app/booking/booking-schedule/booking-schedule.page';
import { SelectAddonPage } from 'src/app/pages/select-addon/select-addon.page';
import { convertSnaps } from '../db-utils';
import { SharedService } from '../shared/shared.service';
import { VendorService } from '../vendor/vendor.service';
import { CartService } from '../cart/cart.service';
import { BehaviorSubject } from 'rxjs';
import { Storage } from '@ionic/storage';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "../shared/shared.service";
import * as i3 from "../vendor/vendor.service";
import * as i4 from "@ionic/angular";
import * as i5 from "@angular/router";
import * as i6 from "../cart/cart.service";
import * as i7 from "@ionic/storage";
var BookingService = /** @class */ (function () {
    function BookingService(afs, sharedService, vendorService, modalController, router, cartService, storage) {
        this.afs = afs;
        this.sharedService = sharedService;
        this.vendorService = vendorService;
        this.modalController = modalController;
        this.router = router;
        this.cartService = cartService;
        this.storage = storage;
        this.userBookings$ = new BehaviorSubject([]);
    }
    BookingService.prototype.confirmBooking = function (data, slotLimit) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var slotAvailable, bookings, vendorDetails, vendorBookings, bookingRef, error_1;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 17, , 18]);
                                    slotAvailable = false;
                                    if (!(data.schedule && data.schedule.active)) return [3 /*break*/, 12];
                                    bookings = [];
                                    return [4 /*yield*/, this.afs.collection('bookings', function (ref) { return ref
                                            .where('item.id', '==', data.item.id)
                                            .where('schedule.date', '==', data.schedule.date)
                                            .where('schedule.slot.start', '==', data.schedule.slot.start)
                                            .where('schedule.slot.end', '==', data.schedule.slot.end)
                                            .where('status', '==', 'accepted'); })
                                            .valueChanges().pipe(first()).toPromise()];
                                case 1:
                                    bookings = _a.sent();
                                    if (!(bookings.length < slotLimit)) return [3 /*break*/, 10];
                                    if (!this.vendorService.vendorSettings.isMultiVendorAvailable) return [3 /*break*/, 8];
                                    if (!data.vendor.id) return [3 /*break*/, 6];
                                    return [4 /*yield*/, this.vendorService.getVendorData(data.vendor.id)];
                                case 2:
                                    vendorDetails = _a.sent();
                                    if (!(vendorDetails && 'appointmentSlotLimit' in vendorDetails && vendorDetails.appointmentSlotLimit)) return [3 /*break*/, 4];
                                    return [4 /*yield*/, this.afs.collection('bookings', function (ref) { return ref
                                            .where('vendor.id', '==', data.vendor.id)
                                            .where('schedule.date', '==', data.schedule.date)
                                            .where('schedule.slot.start', '==', data.schedule.slot.start)
                                            .where('schedule.slot.end', '==', data.schedule.slot.end)
                                            .where('status', '==', 'accepted'); })
                                            .valueChanges().pipe(first()).toPromise()];
                                case 3:
                                    vendorBookings = _a.sent();
                                    if (vendorBookings.length < vendorDetails.appointmentSlotLimit) {
                                        slotAvailable = true;
                                    }
                                    else {
                                        slotAvailable = false;
                                    }
                                    return [3 /*break*/, 5];
                                case 4:
                                    slotAvailable = true;
                                    _a.label = 5;
                                case 5: return [3 /*break*/, 7];
                                case 6:
                                    slotAvailable = true;
                                    _a.label = 7;
                                case 7: return [3 /*break*/, 9];
                                case 8:
                                    slotAvailable = true;
                                    _a.label = 9;
                                case 9: return [3 /*break*/, 11];
                                case 10:
                                    slotAvailable = false;
                                    _a.label = 11;
                                case 11: return [3 /*break*/, 13];
                                case 12:
                                    slotAvailable = true;
                                    _a.label = 13;
                                case 13:
                                    if (!slotAvailable) return [3 /*break*/, 15];
                                    return [4 /*yield*/, this.afs.collection('bookings').add(data)];
                                case 14:
                                    bookingRef = _a.sent();
                                    resolve({ status: 'added', id: bookingRef.id });
                                    return [3 /*break*/, 16];
                                case 15:
                                    resolve({ status: 'not_available', id: '' });
                                    _a.label = 16;
                                case 16: return [3 /*break*/, 18];
                                case 17:
                                    error_1 = _a.sent();
                                    // console.log(error);
                                    resolve({ status: 'error', id: '' });
                                    return [3 /*break*/, 18];
                                case 18: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    BookingService.prototype.getUserBookings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var userId_1, _a, error_2;
                        var _this = this;
                        return tslib_1.__generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    _b.trys.push([0, 3, , 4]);
                                    return [4 /*yield*/, this.sharedService.getStorageUid()];
                                case 1:
                                    userId_1 = _b.sent();
                                    _a = this;
                                    return [4 /*yield*/, this.afs.collection('bookings', function (ref) { return ref
                                            .orderBy('createdAt', 'desc')
                                            .where('user.id', '==', userId_1); })
                                            .snapshotChanges()
                                            .pipe(map(function (snaps) { return convertSnaps(snaps); }))
                                            .subscribe(function (bookings) {
                                            resolve(bookings);
                                            _this.userBookings$.next(bookings.slice());
                                        })];
                                case 2:
                                    _a.userBookingsSub = _b.sent();
                                    return [3 /*break*/, 4];
                                case 3:
                                    error_2 = _b.sent();
                                    // console.log(error);
                                    resolve([]);
                                    return [3 /*break*/, 4];
                                case 4: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    BookingService.prototype.startBookingProcess = function (product) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var isCartAvailable, uid, addOns, template, modal;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.cartService.checkCartAvailability(product.inactiveByVendor)];
                    case 1:
                        isCartAvailable = _a.sent();
                        if (!isCartAvailable) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.storage.get('uid')];
                    case 2:
                        uid = _a.sent();
                        if (!uid) {
                            this.sharedService.openLoginModal();
                            return [2 /*return*/];
                        }
                        addOns = { totalPrice: 0, selectedOptions: [] };
                        if (!product.templateId) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.sharedService.presentLoading()];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.getTemplateData(product.templateId)];
                    case 4:
                        template = _a.sent();
                        this.sharedService.dismissLoading();
                        if (!template) {
                            this.openBookingSchedule(product, addOns);
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.modalController.create({
                                component: SelectAddonPage,
                                componentProps: {
                                    item: {
                                        name: product.prodName,
                                        price: product.discountedPrice,
                                        id: product.id,
                                        templateId: product.templateId,
                                        description: product.prodShortDesc,
                                        mrp: product.prodPrice
                                    },
                                    template: template
                                }
                            })];
                    case 5:
                        modal = _a.sent();
                        modal.onDidDismiss().then(function (res) {
                            if (res && res.data && !res.data.closed) {
                                addOns = res.data;
                                _this.openBookingSchedule(product, addOns);
                            }
                        });
                        return [4 /*yield*/, modal.present()];
                    case 6:
                        _a.sent();
                        return [3 /*break*/, 8];
                    case 7:
                        this.openBookingSchedule(product, addOns);
                        _a.label = 8;
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    BookingService.prototype.openBookingSchedule = function (product, addOns) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: BookingSchedulePage,
                            cssClass: 'custom-modal',
                            componentProps: {
                                data: {
                                    item: {
                                        name: product.prodName,
                                        price: product.discountedPrice,
                                        coverPic: product.coverPic,
                                        id: product.id,
                                        allowPayment: product.allowPayment || false,
                                        allowAddress: product.allowAddress || false,
                                        addOns: addOns,
                                        gst: {
                                            value: product.gst || 0,
                                            isExclusive: product.gstExclusive || false
                                        },
                                        additionalInfo: product.additionalInfo ? product.additionalInfo : null,
                                        shortDescription: product.prodShortDesc
                                    },
                                    vendor: {
                                        id: product.vendorId || ''
                                    },
                                    scheduleData: product.scheduleData
                                }
                            }
                        })];
                    case 1:
                        modal = _a.sent();
                        return [4 /*yield*/, modal.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BookingService.prototype.getBookingData = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var bookingData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.afs.collection('bookings').doc(id).valueChanges().pipe(first()).toPromise()];
                    case 1:
                        bookingData = _a.sent();
                        return [2 /*return*/, bookingData || {}];
                }
            });
        });
    };
    BookingService.prototype.getTemplateData = function (templateId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var templateData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.afs.collection('templates').doc(templateId).valueChanges().pipe(first()).toPromise()];
                    case 1:
                        templateData = _a.sent();
                        return [2 /*return*/, templateData || null];
                }
            });
        });
    };
    BookingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BookingService_Factory() { return new BookingService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.SharedService), i0.ɵɵinject(i3.VendorService), i0.ɵɵinject(i4.ModalController), i0.ɵɵinject(i5.Router), i0.ɵɵinject(i6.CartService), i0.ɵɵinject(i7.Storage)); }, token: BookingService, providedIn: "root" });
    return BookingService;
}());
export { BookingService };
