<ion-header>
  <ion-toolbar>
    <ion-title>Body Measurement</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- Body Measurements -->
  <div class="">
    <ion-grid>
      <h6>Body Measurement (inches)</h6>
      <ion-row>
        <ion-col size="12">
          <div class="input-wrap">
            <ion-label>Profile Name</ion-label>
            <ion-input type="text" [(ngModel)]="bodyMeasurement.profileName" class="form-input"
              placeholder="Profile Name" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/shirt_length.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Length</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.bodyLength" class="form-input"
              placeholder="length" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Full Height.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Full Height</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.fullHeight" class="form-input"
              placeholder="Full Height" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Blouse Length.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Blouse Length</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.blouseLength" class="form-input"
              placeholder="Blouse Length" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Shirt Length.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Shirt Length</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.shirtLength" class="form-input"
              placeholder="Shirt Length" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Jacket Length.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Jacket Length</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.jacketLength" class="form-input"
              placeholder="Jacket Length" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col> 
        <ion-col size="2"><img src="../../assets/img/measurements/Kurthi Height.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Kurthi Height</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.kurthiHeight" class="form-input"
              placeholder="Kurthi Height" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>       
        <ion-col size="2"><img src="../../assets/img/measurements/Shoulder to Waist Back.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Shoulder to Waist Back</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.shoulderToWaistBack" class="form-input"
              placeholder="Shoulder to Waist Back" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Front Shoulder.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Front Shoulder</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.frontShoulder" class="form-input"
              placeholder="Front Shoulder" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Back Shoulder.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Back Shoulder</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.backShoulder" class="form-input"
              placeholder="Back Shoulder" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/arm_hole.png"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Armhole (All Around)</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.armhole" class="form-input"
              placeholder="Armhole" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Wrist.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Wrist</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.wrist" class="form-input"
              placeholder="Wrist" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Bicep.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Bicep</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.bicep" class="form-input"
              placeholder="Bicep" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Strap Length.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Strap Length</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.strapLength" class="form-input"
              placeholder="Strap Length" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/sleeve_length.png"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Sleeve Length</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.sleeveLength" class="form-input"
              placeholder="Sleeve Length" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/elbow_circular.png"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Sleeve Circumf. (All Around)</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.sleeveCircumf" class="form-input"
              placeholder="Sleeve Circumf" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Three-fourth Sleeve length Circular.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Three-fourth Sleeve length Circular</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.threeFourthSleeveLengthCircular" class="form-input"
              placeholder="Three-fourth Sleeve length Circular" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Three-Fourth Sleeve Length.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Three-Fourth Sleeve Length</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.threeFourthSleeveLength" class="form-input"
              placeholder="Three-Fourth Sleeve Length" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Sleeve Length Half.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Sleeve Length Half</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.sleeveLengthHalf" class="form-input"
              placeholder="Sleeve Length Half" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/upper_chest.png"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Upper Chest (All Around)</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.upperChest" class="form-input"
              placeholder="Upper Chest" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/blouseChest.png"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Blouse (chest)</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.blouseChest" class="form-input"
              placeholder="Blouse (chest)" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/chest.png"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Bust (All Around)</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.bust" class="form-input" placeholder="Bust"
              [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/blouseBelowBust.png"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Blouse (below bust)</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.blouseBelowBust" class="form-input"
              placeholder="Blouse (below bust)" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Nape to Apex.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Nape to Apex</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.napeToApex" class="form-input"
              placeholder="Nape to Apex" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Shoulder to Apex.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Shoulder to Apex</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.shoulderToApex" class="form-input"
              placeholder="Shoulder to Apex" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Apex to Apex.jpeg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Apex to Apex</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.apexToApex" class="form-input"
              placeholder="Sweep Length" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Neck.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Neck</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.neck" class="form-input"
              placeholder="Neck" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Front Neck Width.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Front Neck Width</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.frontNeckWidth" class="form-input"
              placeholder="Front Neck Width" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Back Neck Width.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Back Neck Width</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.backNeckWidth" class="form-input"
              placeholder="Back Neck Width" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Front Neck Depth.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Front Neck Depth</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.frontNeckDepth" class="form-input"
              placeholder="Front Neck Depth" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Back Neck Depth 2.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Back Neck Depth</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.backNeckDepth" class="form-input"
              placeholder="Back Neck Depth" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/neck_front.png"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Front Neck Depth 2</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.frontNeckDepth2" class="form-input"
              placeholder="Front Neck Depth 2" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/neck_back.png"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Back Neck Depth 2</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.backNeckDepth2" class="form-input"
              placeholder="Back Neck Depth 2" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/bottom_length.png"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Bottom's Length</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.bottomLength" class="form-input"
              placeholder="Bottom's Length" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/waist.png"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Bottom's Waist (All Around)</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.bottomWaist" class="form-input"
              placeholder="Bottom's Waist (All Around)" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/hip.png"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Bottom's Hip (All Around)</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.bottomHip" class="form-input"
              placeholder="Bottom's Hip (All Around)" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Seat.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Seat</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.seat" class="form-input"
              placeholder="Seat" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Waist to hip.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Waist to hip</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.waistToHip" class="form-input"
              placeholder="Waist to hip" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Inseam.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Inseam</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.inseam" class="form-input"
              placeholder="Inseam" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Shorts Length.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Shorts Length</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.shortsLength" class="form-input"
              placeholder="Shorts Length" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Front Slit.jpeg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Front Slit</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.frontSlit" class="form-input"
              placeholder="Sweep Length" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Side Slit.jpeg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Side Slit</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.sideSlit" class="form-input"
              placeholder="Sweep Length" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Thigh Circular.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Thigh Circular</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.thighCircular" class="form-input"
              placeholder="Thigh Circular" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Thighs.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Thighs</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.thighs" class="form-input"
              placeholder="Thighs" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Thigh Round.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Thigh Round</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.thighRound" class="form-input"
              placeholder="Thigh Round" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Above Knee.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Above Knee</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.aboveKnee" class="form-input"
              placeholder="Above Knee" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Below Knee.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Below Knee</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.belowKnee" class="form-input"
              placeholder="Below Knee" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Calf.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Calf</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.calf" class="form-input"
              placeholder="Calf" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <!-- <ion-col size="2"><img src="../../assets/img/measurements/calf.png"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Knee / Calf (All Around)</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.kneeOrCalf" class="form-input"
              placeholder="Knee / Calf (All Around)" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col> -->
        <ion-col size="2"><img src="../../assets/img/measurements/ankle.png"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Ankle (All Around)</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.ankle" class="form-input"
              placeholder="Ankle (All Around)" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
        <ion-col size="2"><img src="../../assets/img/measurements/Sweep Length.jpg"></ion-col>
        <ion-col size="10">
          <div class="input-wrap">
            <ion-label>Sweep Length</ion-label>
            <ion-input type="number" min="0" [(ngModel)]="bodyMeasurement.sweepLength" class="form-input"
              placeholder="Sweep Length" [disabled]="!allowEdit"></ion-input>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <!-- Body Measurements -->
</ion-content>

<ion-footer>
  <ng-container *ngIf="allowEdit; else editNotAllowed">
    <ion-button (click)="save()" class="footer-button" expand="full" color="success">
      Save
    </ion-button>
  </ng-container>

  <ng-template #editNotAllowed>
    <ion-button (click)="close()" class="footer-button" expand="full">
      Close
    </ion-button>
  </ng-template>
</ion-footer>