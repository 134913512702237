<ion-header class="sf-header">
  <ion-toolbar>
    <ion-title>{{'SHOP_FILTERS.header_text' | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button color="primary" fill="clear" (click)="clearAllFilters()">
        {{'SHOP_FILTERS.clear_all' | translate}}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">


  <!-- parent filter -->
  <ion-list class="sf-list" *ngIf="parentFilter?.length > 0">
    <ion-list-header>
      <ion-label *ngIf="categoryId" [ngClass]="{'sf-active-header': filtersActive.parent}">{{'SHOP_FILTERS.brands' | translate}}</ion-label>
      <ion-label *ngIf="brandId" [ngClass]="{'sf-active-header': filtersActive.parent}">{{'SHOP_FILTERS.categories' | translate}}</ion-label>
      <ion-button color="dark" fill="clear" (click)="toggleFiltersActive('parent', true)" *ngIf="!filtersActive.parent">
        <i class="flaticon-plus"></i>
      </ion-button>
      <ion-button color="primary" fill="clear" (click)="toggleFiltersActive('parent', false)"
        *ngIf="filtersActive.parent">
        <i class="flaticon-minus"></i>
      </ion-button>
    </ion-list-header>
    <hr class="line">
    <div *ngIf="filtersActive.parent">
      <ion-item *ngFor="let item of parentFilter; let i=index;" lines="none" (click)="selectFilters('parent', i)">
        <ion-label>{{item.name}}</ion-label>
        <ion-checkbox color="primary" slot="end" [checked]="item.isChecked"></ion-checkbox>
      </ion-item>
    </div>
  </ion-list>
  <!-- /parent filter -->


  <!-- price filter -->
  <ion-list class="sf-list">
    <ion-list-header>
      <ion-label [ngClass]="{'sf-active-header': filtersActive.price}">{{'SHOP_FILTERS.price_range' | translate}} ({{currencyCode}})</ion-label>
      <ion-button color="dark" fill="clear" (click)="toggleFiltersActive('price', true)" *ngIf="!filtersActive.price">
        <i class="flaticon-plus"></i>
      </ion-button>
      <ion-button color="primary" fill="clear" (click)="toggleFiltersActive('price', false)"
        *ngIf="filtersActive.price">
        <i class="flaticon-minus"></i>
      </ion-button>
    </ion-list-header>
    <hr class="line">
    <div *ngIf="filtersActive.price">
      <div class="sf-price-range"><span *ngIf="currencyCode === 'INR'">&#8377;</span>{{priceRange.lower}} - <span
          *ngIf="currencyCode === 'INR'">&#8377;</span>{{priceRange.upper}}<span *ngIf="priceRange.upper === priceRangeMax">+</span></div>
      <ion-item lines="none">
        <ion-range [min]="priceRangeMin" [max]="priceRangeMax" snaps [step]="priceRangeSteps" id="dual-range" mode="ios" dual-knobs [(ngModel)]="priceRange"
          color="primary">
        </ion-range>
      </ion-item>
    </div>
  </ion-list>
  <!-- /price filter -->

  <!-- discount filter -->
  <ion-list class="sf-list">
    <ion-list-header>
      <ion-label [ngClass]="{'sf-active-header': filtersActive.discount}">{{'SHOP_FILTERS.discount' | translate}}</ion-label>
      <ion-button color="dark" fill="clear" (click)="toggleFiltersActive('discount', true)"
        *ngIf="!filtersActive.discount">
        <i class="flaticon-plus"></i>
      </ion-button>
      <ion-button color="primary" fill="clear" (click)="toggleFiltersActive('discount', false)"
        *ngIf="filtersActive.discount">
        <i class="flaticon-minus"></i>
      </ion-button>
    </ion-list-header>
    <hr class="line">
    <div *ngIf="filtersActive.discount">
      <ion-item *ngFor="let item of discountFilter; let i=index;" lines="none" (click)="selectRadioFilters('discount', i)">
        <ion-label style="text-transform: none;">{{item.filter}}</ion-label>
        <ion-radio color="primary" slot="end" [checked]="item.isChecked"></ion-radio>
      </ion-item>
    </div>
  </ion-list>
  <!-- /discount filter -->

  <!-- rating filter -->
  <ion-list class="sf-list" *ngIf="productRatings">
    <ion-list-header>
      <ion-label [ngClass]="{'sf-active-header': filtersActive.rating}">{{'SHOP_FILTERS.customer_rating' | translate}}</ion-label>
      <ion-button color="dark" fill="clear" (click)="toggleFiltersActive('rating', true)"
        *ngIf="!filtersActive.rating">
        <i class="flaticon-plus"></i>
      </ion-button>
      <ion-button color="primary" fill="clear" (click)="toggleFiltersActive('rating', false)"
        *ngIf="filtersActive.rating">
        <i class="flaticon-minus"></i>
      </ion-button>
    </ion-list-header>
    <hr class="line">
    <div *ngIf="filtersActive.rating">
      <ion-item *ngFor="let item of ratingFilter; let i=index;" lines="none" (click)="selectRadioFilters('rating', i)">
        <ion-label style="text-transform: none;">{{i + 1}}&#9733; {{'SHOP_FILTERS.and_above' | translate}}</ion-label>
        <ion-radio color="primary" slot="end" [checked]="item.isChecked"></ion-radio>
      </ion-item>
    </div>
  </ion-list>
  <!-- /rating filter -->

  <!-- admin filters -->
  <div *ngIf="adminFilters.length > 0">
    <ion-list class="sf-list" *ngFor="let filter of adminFilters; let i=index" [hidden]="hideFilter(filter)">
      <ion-list-header>
        <ion-label [ngClass]="{'sf-active-header': filter.active}">{{filter.name}}</ion-label>
        <ion-button color="dark" fill="clear" (click)="toggleAdminFiltersActive(i)"
         *ngIf="!filter.active">
          <i class="flaticon-plus"></i>
        </ion-button>
        <ion-button color="primary" fill="clear" (click)="toggleAdminFiltersActive(i)"
          *ngIf="filter.active">
          <i class="flaticon-minus"></i>
        </ion-button>
      </ion-list-header>
      <hr class="line">
      <div *ngIf="filter.active">
        <ion-item *ngFor="let filterValue of filter.values; let j=index;" lines="none" (click)="selectAdminFilters(i, j)">
          <ion-label>{{filterValue.value}}</ion-label>
          <ion-checkbox color="primary" slot="end" [checked]="filterValue.isChecked"></ion-checkbox>
        </ion-item>
      </div>
    </ion-list>
  </div>
  
  <!-- admin filters -->


</ion-content>


<ion-footer>
  <ion-grid class="ion-no-padding">
    <ion-row>
      <ion-col size="6" class="ion-no-padding"><ion-button class="btn-1" (click)="closeModal()" color="danger"  expand="full">
        {{'SHOP_FILTERS.close' | translate}}
      </ion-button></ion-col>
      <ion-col size="6" class="ion-no-padding"> <ion-button class="btn-1" (click)="applyFilters()" color="success"  expand="full" [disabled]="disableApplyBtn()">
        {{'SHOP_FILTERS.apply' | translate}}
      </ion-button></ion-col>
    </ion-row>
  </ion-grid>
 
</ion-footer>