import * as tslib_1 from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
var ThemeService = /** @class */ (function () {
    function ThemeService(angularFirestore) {
        this.angularFirestore = angularFirestore;
    }
    ThemeService.prototype.initializeThemeSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, css, head, style;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.angularFirestore.collection('settings').doc('theme').valueChanges().pipe(first()).toPromise()];
                    case 1:
                        data = _a.sent();
                        css = ":root {--primary-color: " + data.colors.primary + ";}";
                        head = document.getElementsByTagName('head')[0];
                        style = document.createElement('style');
                        style.type = 'text/css';
                        style.appendChild(document.createTextNode(css));
                        head.appendChild(style);
                        return [2 /*return*/];
                }
            });
        });
    };
    ThemeService.prototype.getThemeSttings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var data;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    data = JSON.parse(sessionStorage.getItem("themeSettingsData")) || {};
                                    if (!!(Object.keys(data).length)) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.angularFirestore.collection('settings').doc('theme').valueChanges().pipe(first()).toPromise()];
                                case 1:
                                    data = _a.sent();
                                    sessionStorage.setItem("themeSettingsData", JSON.stringify(data));
                                    _a.label = 2;
                                case 2:
                                    resolve(data);
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    ThemeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ThemeService_Factory() { return new ThemeService(i0.ɵɵinject(i1.AngularFirestore)); }, token: ThemeService, providedIn: "root" });
    return ThemeService;
}());
export { ThemeService };
