import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AdminSettingsService } from '../admin-settings/admin-settings.service';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class LoadScriptsService {
	private _renderer2: Renderer2;
  	constructor(rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document: Document,
  					private adminSettingsService: AdminSettingsService, private configService: ConfigService) {
		this._renderer2 = rendererFactory.createRenderer(null, null);
  	}

  	async loadPaymentLibraries() {
		const paymentLibraries = [
			/* use cashfree's production library in case the cashfree.development environment variable
				is set to false; else use cashfree's development library*/
			{
				'gateway': 'cashfree',
				'gatewaySrc': this.configService.environment.cashfree && this.configService.environment.cashfree.development ?
								'https://sdk.cashfree.com/js/ui/1.0.26/dropinClient.sandbox.js' :
								'https://sdk.cashfree.com/js/ui/1.0.26/dropinClient.prod.js'
			},
			// {'gateway': 'stripe', 'gatewaySrc': 'https://js.stripe.com/v3/'},
			// {'gateway': 'razorpay', 'gatewaySrc': 'https://checkout.razorpay.com/v1/checkout.js'}
		]

		for (const lib of paymentLibraries) {
			const gatewayType = lib['gateway'];
			const gatewayScriptSrc = lib['gatewaySrc'];

			let paymentGatewayData = await this.adminSettingsService.getPaymentGateway(gatewayType);

			if (paymentGatewayData && paymentGatewayData.active) {
				const scriptId = gatewayType.concat('Script');

				if (!this.document.getElementById(scriptId)) {
					let scriptElement = this._renderer2.createElement('script');
					this._renderer2.setAttribute(scriptElement, 'src', gatewayScriptSrc)
					this._renderer2.setAttribute(scriptElement, 'defer', '');

					// provide id to the script tag so that it is not appended to the head twice.
					this._renderer2.setAttribute(scriptElement, 'id', scriptId);
					this._renderer2.appendChild(this.document.head, scriptElement);
				}
			}
		}
  	}

	loadGoogleMapsLibrary() {
		if (!this.document.getElementById('googleMapsScript')) {
			let scriptElement = this._renderer2.createElement('script');
			this._renderer2.setAttribute(scriptElement, 'src', 'https://maps.google.com/maps/api/js?key=AIzaSyBi8lJj0oSwvEdVcUkM-jiXsGpzKPbp-44');

			// provide id to the script tag so that it is not appended to the head twice.
			this._renderer2.setAttribute(scriptElement, 'id', 'googleMapsScript');
			this._renderer2.appendChild(this.document.head, scriptElement);
		}
	}
}
