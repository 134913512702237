import { DateAgoPipe } from './date-ago.pipe';
import { NgModule } from '@angular/core';
import { SafeHtmlPipe } from './safe-html.pipe';

@NgModule({
  imports: [
  ],
  declarations: [ 
    DateAgoPipe, SafeHtmlPipe
  ],
  exports: [
    DateAgoPipe,
    SafeHtmlPipe
  ]
})
export class ApplicationPipesModule {}