/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./rate-order.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../components/rating/rating.component.ngfactory";
import * as i6 from "../../components/rating/rating.component";
import * as i7 from "../../services/shared/shared.service";
import * as i8 from "../../services/label/label.service";
import * as i9 from "../../services/user/user.service";
import * as i10 from "./rate-order.page";
var styles_RateOrderPage = [i0.styles];
var RenderType_RateOrderPage = i1.ɵcrt({ encapsulation: 0, styles: styles_RateOrderPage, data: {} });
export { RenderType_RateOrderPage as RenderType_RateOrderPage };
export function View_RateOrderPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "ion-header", [], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 12, "ion-toolbar", [["class", "toolbar"]], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(3, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 4, "ion-title", [["text-center", ""]], null, null, null, i2.View_IonTitle_0, i2.RenderType_IonTitle)), i1.ɵdid(5, 49152, null, 0, i3.IonTitle, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵdid(6, 16384, null, 0, i3.CssUtilsDeprecations, [i1.ElementRef], null, null), (_l()(), i1.ɵted(7, 0, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, 0, 5, "ion-buttons", [["slot", "end"]], null, null, null, i2.View_IonButtons_0, i2.RenderType_IonButtons)), i1.ɵdid(10, 49152, null, 0, i3.IonButtons, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(11, 0, null, 0, 3, "ion-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(12, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(13, 0, null, 0, 1, "ion-icon", [["name", "close-circle"], ["slot", "icon-only"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(14, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 3, "ion-content", [], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(16, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(17, 0, null, 0, 1, "app-rating", [["type", "order"]], null, [[null, "rateEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("rateEvent" === en)) {
        var pd_0 = (_co.rate($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_RatingComponent_0, i5.RenderType_RatingComponent)), i1.ɵdid(18, 114688, null, 0, i6.RatingComponent, [i7.SharedService, i8.LabelService, i3.ModalController, i3.ActionSheetController, i9.UserService], { type: [0, "type"] }, { rateEvent: "rateEvent" })], function (_ck, _v) { var currVal_1 = "close-circle"; _ck(_v, 14, 0, currVal_1); var currVal_2 = "order"; _ck(_v, 18, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("RATE_ORDER.header_text")); _ck(_v, 7, 0, currVal_0); }); }
export function View_RateOrderPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-rate-order", [], null, null, null, View_RateOrderPage_0, RenderType_RateOrderPage)), i1.ɵdid(1, 114688, null, 0, i10.RateOrderPage, [i3.ModalController, i3.Events, i8.LabelService, i3.LoadingController, i3.AlertController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RateOrderPageNgFactory = i1.ɵccf("app-rate-order", i10.RateOrderPage, View_RateOrderPage_Host_0, {}, {}, []);
export { RateOrderPageNgFactory as RateOrderPageNgFactory };
