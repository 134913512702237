import * as tslib_1 from "tslib";
import { LabelService } from 'src/app/services/label/label.service';
import { OnInit } from '@angular/core';
import { ModalController, Events, AlertController } from '@ionic/angular';
import { ConfigService } from 'src/app/services/config/config.service';
import { FiltersService } from '../services/filters/filters.service';
var ShopFiltersPage = /** @class */ (function () {
    function ShopFiltersPage(events, modalController, alertController, labelService, configService, filtersService) {
        this.events = events;
        this.modalController = modalController;
        this.alertController = alertController;
        this.labelService = labelService;
        this.configService = configService;
        this.filtersService = filtersService;
        this.parentFilter = [];
        this.noCategories = false;
        this.noBrands = false;
        this.showLoader = true;
        this.filtersActive = { parent: false, price: false, discount: false, rating: false };
        this.discountFilter = [
            {
                filter: '10% and above',
                range: [10, 100],
                isChecked: false
            },
            {
                filter: '20% and above',
                range: [20, 100],
                isChecked: false
            },
            {
                filter: '30% and above',
                range: [30, 100],
                isChecked: false
            },
            {
                filter: '40% and above',
                range: [40, 100],
                isChecked: false
            },
            {
                filter: '50% and above',
                range: [50, 100],
                isChecked: false
            },
            {
                filter: '60% and above',
                range: [60, 100],
                isChecked: false
            },
        ];
        this.ratingFilter = [
            {
                range: [1, 5],
                isChecked: false
            },
            {
                range: [2, 5],
                isChecked: false
            },
            {
                range: [3, 5],
                isChecked: false
            },
            {
                range: [4, 5],
                isChecked: false
            }
        ];
        this.discountRange = [0, 100];
        this.ratingRange = [0, 5];
        this.priceRange = { lower: 1, upper: 2500 };
        this.priceRangeMin = 1;
        this.priceRangeMax = 2500;
        this.priceRangeSteps = 100;
        this.SHARED_LABELS = {};
        this.productRatings = false;
    }
    ShopFiltersPage.prototype.ngOnInit = function () {
        // // console.log('categoryId', this.categoryId);
        // // console.log('brandId', this.brandId);
        this.currencyCode = this.configService.environment.currencyCode;
        this.productRatings = this.configService.environment.productRatings;
        this.SHARED_LABELS = this.labelService.labels['SHARED'];
    };
    ShopFiltersPage.prototype.ionViewWillEnter = function () {
        this.initializeSubscriptions();
        if (this.categoryId) {
            this.events.publish('brands:getAllBrandsForUser');
        }
        if (this.brandId) {
            this.events.publish('product:getAllCategoriesForShop');
        }
        if (this.priceRange.upper === 10000000000000) {
            this.priceRange.upper = this.priceRangeMax;
        }
        // if(!this.adminFilters.length) {
        //   this.events.publish('filters:getAllActiveFilters');
        // }
    };
    ShopFiltersPage.prototype.ionViewWillLeave = function () {
        this.removeSubscriptions();
    };
    ShopFiltersPage.prototype.initializeSubscriptions = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var filters;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.events.subscribe('product:publishAllCategoriesForShop', function (categories) {
                            if (_this.brandId) {
                                categories.forEach(function (c) {
                                    _this.parentFilter.push({ id: c.id, name: c.name, isChecked: false });
                                });
                                if (_this.appliedParentFilter && _this.appliedParentFilter.hasOwnProperty('ids')) {
                                    _this.parentFilter.forEach(function (p) {
                                        if (_this.appliedParentFilter.ids.includes(p.id)) {
                                            p.isChecked = true;
                                        }
                                    });
                                }
                                _this.showLoader = false;
                            }
                        });
                        this.events.subscribe('product:noCategoryAvailable', function () {
                            _this.showLoader = false;
                            _this.noCategories = true;
                        });
                        this.events.subscribe('brands:publishAllBrandsForUser', function (brands) {
                            if (_this.categoryId) {
                                brands.forEach(function (b) {
                                    _this.parentFilter.push({ id: b.id, name: b.name, isChecked: false });
                                });
                                if (_this.appliedParentFilter && _this.appliedParentFilter.hasOwnProperty('ids')) {
                                    _this.parentFilter.forEach(function (p) {
                                        if (_this.appliedParentFilter.ids.includes(p.id)) {
                                            p.isChecked = true;
                                        }
                                    });
                                }
                            }
                        });
                        this.events.subscribe('brands:noBrandAvailableForUser', function () {
                            _this.noBrands = true;
                            _this.showLoader = false;
                        });
                        if (!!this.adminFilters.length) return [3 /*break*/, 2];
                        this.adminFilters = [];
                        return [4 /*yield*/, this.filtersService.getAllActiveFilters()];
                    case 1:
                        filters = _a.sent();
                        if (filters.length) {
                            filters.map(function (f) {
                                f.active = false;
                                var values = [];
                                f.values.map(function (v) {
                                    values.push({ value: v, isChecked: false });
                                });
                                f.values = values;
                            });
                            this.adminFilters = filters;
                            // console.log('adminFilters', this.adminFilters);
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ShopFiltersPage.prototype.closeModal = function () {
        this.modalController.dismiss();
    };
    ShopFiltersPage.prototype.clearAllFilters = function () {
        this.modalController.dismiss({
            clearAll: true
        });
    };
    ShopFiltersPage.prototype.applyFilters = function () {
        var priceRange = { lower: this.priceRange.lower };
        var parentObj = { type: this.categoryId ? 'brands' : 'categories', ids: [] };
        if (this.priceRange.upper === this.priceRangeMax) {
            priceRange['upper'] = 10000000000000;
        }
        else {
            priceRange['upper'] = this.priceRange.upper;
        }
        for (var _i = 0, _a = this.discountFilter; _i < _a.length; _i++) {
            var d = _a[_i];
            if (d.isChecked) {
                this.discountRange = d.range;
                break;
            }
        }
        for (var _b = 0, _c = this.ratingFilter; _b < _c.length; _b++) {
            var r = _c[_b];
            if (r.isChecked) {
                this.ratingRange = r.range;
                break;
            }
        }
        for (var _d = 0, _e = this.parentFilter; _d < _e.length; _d++) {
            var p = _e[_d];
            if (p.isChecked) {
                // if(this.brandId) {
                //   parentObj.ids.push(p.name)
                // } else {
                //   parentObj.ids.push(p.id);
                // }
                parentObj.ids.push(p.id);
            }
        }
        this.modalController.dismiss({
            parentObj: parentObj,
            priceRange: priceRange,
            discountRange: this.discountRange,
            ratingRange: this.ratingRange,
            adminFilters: this.adminFilters,
            clearAll: false
        });
    };
    ShopFiltersPage.prototype.toggleFiltersActive = function (type, status) {
        this.filtersActive[type] = status;
    };
    ShopFiltersPage.prototype.toggleAdminFiltersActive = function (i) {
        this.adminFilters[i].active = !this.adminFilters[i].active;
    };
    ShopFiltersPage.prototype.selectFilters = function (type, i) {
        if (type === 'parent') {
            this.parentFilter[i].isChecked = !this.parentFilter[i].isChecked;
        }
    };
    ShopFiltersPage.prototype.selectAdminFilters = function (i, j) {
        this.adminFilters[i].values[j].isChecked = !this.adminFilters[i].values[j].isChecked;
    };
    ShopFiltersPage.prototype.selectRadioFilters = function (type, i) {
        if (type === 'discount') {
            for (var index = 0; index < this.discountFilter.length; index++) {
                if (i === index) {
                    this.discountFilter[index].isChecked = true;
                }
                else {
                    this.discountFilter[index].isChecked = false;
                }
            }
        }
        if (type === 'rating') {
            for (var index = 0; index < this.ratingFilter.length; index++) {
                if (i === index) {
                    this.ratingFilter[index].isChecked = true;
                }
                else {
                    this.ratingFilter[index].isChecked = false;
                }
            }
        }
    };
    ShopFiltersPage.prototype.presentAlert = function (msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            message: msg,
                            buttons: ["" + this.SHARED_LABELS['ok']]
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ShopFiltersPage.prototype.disableApplyBtn = function () {
        var disable = true;
        this.discountFilter.forEach(function (d) {
            if (d.isChecked) {
                disable = false;
            }
        });
        if (disable) {
            this.ratingFilter.forEach(function (r) {
                if (r.isChecked) {
                    disable = false;
                }
            });
            if (disable) {
                this.parentFilter.forEach(function (p) {
                    if (p.isChecked) {
                        disable = false;
                    }
                });
                if (disable) {
                    if (this.priceRange.lower !== 1 || this.priceRange.upper !== this.priceRangeMax) {
                        disable = false;
                    }
                    if (disable) {
                        this.adminFilters.forEach(function (filter) {
                            filter.values.forEach(function (v) {
                                if (v.isChecked) {
                                    disable = false;
                                }
                            });
                        });
                    }
                }
            }
        }
        return disable;
    };
    ShopFiltersPage.prototype.hideFilter = function (filter) {
        if (this.categoryId) {
            if (!filter.hasOwnProperty('categories')) {
                return false;
            }
            if (filter.hasOwnProperty('categories') && filter.categories.includes(this.categoryId)) {
                return false;
            }
            else {
                return true;
            }
        }
        if (this.brandId) {
            if (!filter.hasOwnProperty('brands')) {
                return false;
            }
            if (filter.hasOwnProperty('brands') && filter.brands.includes(this.brandId)) {
                return false;
            }
            else {
                return true;
            }
        }
    };
    ShopFiltersPage.prototype.removeSubscriptions = function () {
        this.events.unsubscribe('product:publishAllCategoriesForShop');
        this.events.unsubscribe('product:noCategoryAvailable');
        this.events.unsubscribe('brands:publishAllBrandsForUser');
        this.events.unsubscribe('brands:noBrandAvailableForUser');
        this.events.unsubscribe('filters:publishAllActiveFilters');
    };
    return ShopFiltersPage;
}());
export { ShopFiltersPage };
