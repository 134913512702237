import { NgModule } from '@angular/core';
import { ImagePreloader } from './img-placeholder.directive';

@NgModule({
  imports: [
  ],
  declarations: [ 
    ImagePreloader
  ],
  exports: [
    ImagePreloader
  ]
})
export class ApplicationDirectivesModule {}