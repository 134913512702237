import * as tslib_1 from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import { Events } from '@ionic/angular';
import { first } from 'rxjs/operators';
import { LogglyLoggerService } from '../loggly-logger/loggly-logger.service';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "../loggly-logger/loggly-logger.service";
var ReferralService = /** @class */ (function () {
    function ReferralService(events, afs, logglyService) {
        this.events = events;
        this.afs = afs;
        this.logglyService = logglyService;
        this.referralRef = this.afs.collection('settings').doc('referral');
    }
    ReferralService.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('referral:saveSettings', function (data) {
            _this.saveSettings(data);
        });
        this.events.subscribe('referral:getReferralSettings', function () {
            _this.getReferralSettings();
        });
    };
    ReferralService.prototype.getReferralSettings = function (route) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var settings, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.referralRef.valueChanges().pipe(first()).toPromise()];
                    case 1:
                        settings = _a.sent();
                        if (!route) {
                            this.events.publish('referral:publishReferralSettings', settings);
                        }
                        else {
                            return [2 /*return*/, settings];
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.dir(error_1);
                        this.logglyService.log(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ReferralService.prototype.saveSettings = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.referralRef.set(data)];
                    case 1:
                        _a.sent();
                        this.events.publish('referral:saveSettingsSuccess');
                        this.events.publish('referral:getReferralSettings');
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        console.dir(error_2);
                        this.logglyService.log(error_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ReferralService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReferralService_Factory() { return new ReferralService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.LogglyLoggerService)); }, token: ReferralService, providedIn: "root" });
    return ReferralService;
}());
export { ReferralService };
