import * as tslib_1 from "tslib";
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Events, LoadingController, ModalController, NavController } from '@ionic/angular';
import { NgZone, OnInit } from '@angular/core';
import { LabelService } from 'src/app/services/label/label.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/services/config/config.service';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
var SelectLanguagePage = /** @class */ (function () {
    function SelectLanguagePage(events, modalController, router, labelService, translateService, storage, ngZone, navController, route, loadingController, configService, splashScreen) {
        this.events = events;
        this.modalController = modalController;
        this.router = router;
        this.labelService = labelService;
        this.translateService = translateService;
        this.storage = storage;
        this.ngZone = ngZone;
        this.navController = navController;
        this.route = route;
        this.loadingController = loadingController;
        this.configService = configService;
        this.splashScreen = splashScreen;
        this.headerText = '';
        this.showLoader = true;
        this.languages = [];
        this.routeFromSidemenu = false;
    }
    SelectLanguagePage.prototype.ngOnInit = function () {
        this.SHARED_LABELS = this.labelService.labels['SHARED'];
        this.SELECT_LANGUAGE_LABELS = this.labelService.labels['SELECT_LANGUAGE'];
        this.headerText = this.SELECT_LANGUAGE_LABELS['header_text'];
    };
    SelectLanguagePage.prototype.ionViewDidEnter = function () {
        this.splashScreen.hide();
    };
    SelectLanguagePage.prototype.ionViewWillEnter = function () {
        this.initializeSubscriptions();
        this.events.publish('language:getAddedLanguages');
    };
    SelectLanguagePage.prototype.ionViewWillLeave = function () {
        this.removeSubscriptions();
    };
    SelectLanguagePage.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('language:publishAddedLanguages', function (langs) {
            _this.storage.get('appLang').then(function (langCode) {
                if (langCode && langCode !== undefined) {
                    langs.map(function (lang) {
                        if (lang.code === langCode) {
                            lang.isDefault = true;
                        }
                        else {
                            lang.isDefault = false;
                        }
                    });
                }
                _this.languages = langs;
                _this.showLoader = false;
            });
        });
    };
    SelectLanguagePage.prototype.selectLang = function (i) {
        for (var index = 0; index < this.languages.length; index++) {
            if (i === index) {
                this.languages[index].isDefault = true;
            }
            else {
                this.languages[index].isDefault = false;
            }
        }
    };
    SelectLanguagePage.prototype.onClickSelect = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.languages.length) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.presentLoading()];
                    case 1:
                        _a.sent();
                        this.languages.forEach(function (lang) {
                            if (lang.isDefault) {
                                _this.translateService.setDefaultLang(lang.code);
                                _this.translateService.use(lang.code);
                                _this.labelService.intializeSubscriptions();
                                _this.storage.set('appLang', lang.code);
                                _this.loading.dismiss();
                                _this.ngZone.run(function () {
                                    _this.navController.navigateRoot(['']);
                                });
                                _this.modalDismiss();
                            }
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        this.storage.set('appLang', this.configService.environment.DEFAULT_APP_LANG);
                        this.ngZone.run(function () {
                            _this.navController.navigateRoot(['']);
                        });
                        this.modalDismiss();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SelectLanguagePage.prototype.presentLoading = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loadingController.create({
                                duration: 2000,
                            })];
                    case 1:
                        _a.loading = _b.sent();
                        return [4 /*yield*/, this.loading.present()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SelectLanguagePage.prototype.removeSubscriptions = function () {
        this.events.unsubscribe('language:publishAddedLanguages');
    };
    SelectLanguagePage.prototype.modalDismiss = function () {
        this.modalController.dismiss();
    };
    return SelectLanguagePage;
}());
export { SelectLanguagePage };
