import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ConfigService } from 'src/app/services/config/config.service';
import { LabelService } from 'src/app/services/label/label.service';
import { SharedNewService } from 'src/app/services/shared-new/shared-new.service';
var SelectAddonPage = /** @class */ (function () {
    function SelectAddonPage(sharedNewService, configService, modalController, labelService) {
        this.sharedNewService = sharedNewService;
        this.configService = configService;
        this.modalController = modalController;
        this.labelService = labelService;
    }
    SelectAddonPage.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                // this.template = await this.sharedNewService.getTemplateData(this.item.templateId);
                this.currencyCode = this.configService.environment.currencyCode;
                return [2 /*return*/];
            });
        });
    };
    SelectAddonPage.prototype.getSelectOptionTxt = function (section) {
        var txt = '';
        switch (section.multiple) {
            case true:
                txt = "Select up to " + section.options.length + " options";
                break;
            case false:
                txt = "Select 1 out of " + section.options.length + " options";
                break;
        }
        return txt;
    };
    SelectAddonPage.prototype.selectOption = function (sectionIndex, option, optionIndex) {
        option.checked = !option.checked;
        if (!this.template.sections[sectionIndex].multiple) {
            if (option.checked) {
                this.template.sections[sectionIndex].options.forEach(function (el, index) {
                    if (optionIndex !== index) {
                        el.checked = false;
                    }
                });
            }
        }
        this.getAddonsPrice();
    };
    SelectAddonPage.prototype.onChangeOption = function (event, sectionIndex) {
        var optionIndex = event.target.value;
        this.template.sections[sectionIndex].options.forEach(function (el, index) {
            if (optionIndex !== index) {
                el.checked = false;
            }
            else {
                el.checked = true;
            }
        });
        this.getAddonsPrice();
    };
    SelectAddonPage.prototype.getSelectedOptions = function () {
        var _this = this;
        var selectedOptions = [];
        this.template.sections.forEach(function (section) {
            var options = section.options.filter(function (option) { return option.checked; });
            if (section.required && !section.multiple && !options.length) {
                section.options[0].checked = true;
                options = [section.options[0]];
            }
            if (options.length) {
                selectedOptions.push({ name: section.name, quantity: _this.item.quantity || 1, options: options });
            }
        });
        if (!selectedOptions.length) {
            selectedOptions.push({ name: 'No add-on', quantity: this.item.quantity || 1, options: [{ name: 'No add-on', price: 0 }] });
        }
        return selectedOptions;
    };
    SelectAddonPage.prototype.getAddonsPrice = function () {
        var totalOptionsPrice = 0;
        var selectedOptions = this.getSelectedOptions();
        selectedOptions.forEach(function (el) {
            el.options.forEach(function (option) {
                totalOptionsPrice += (option.price * el.quantity);
            });
        });
        return totalOptionsPrice || 0;
    };
    SelectAddonPage.prototype.getActiveSections = function () {
        var activeSections = this.template.sections.filter(function (section) { return section.active; });
        return activeSections.length ? activeSections : null;
    };
    SelectAddonPage.prototype.addItems = function () {
        var isValid = this.checkAnyRequiredSection();
        if (!isValid) {
            this.sharedNewService.presentAlert({
                msg: this.labelService.labels['SELECT_ADDON']['select_required_options'],
                backdropDismiss: true,
                page: ''
            });
            return;
        }
        this.modalController.dismiss({ totalPrice: this.getAddonsPrice(), selectedOptions: this.getSelectedOptions(), closed: false });
    };
    SelectAddonPage.prototype.checkAnyRequiredSection = function () {
        var isValid = true;
        for (var _i = 0, _a = this.template.sections; _i < _a.length; _i++) {
            var section = _a[_i];
            var options = section.options.filter(function (option) { return option.checked; });
            if (section.required && section.multiple && !options.length) {
                isValid = false;
                break;
            }
        }
        return isValid;
    };
    SelectAddonPage.prototype.closeModal = function () {
        this.modalController.dismiss({ totalPrice: 0, selectedOptions: [], closed: true });
    };
    SelectAddonPage.prototype.isCheckboxDisabled = function (optionIndex, sectionIndex) {
        var isDisabled = false;
        var section = this.template.sections[sectionIndex];
        if (!section.multiple && !section.required) {
            this.template.sections[sectionIndex].options.forEach(function (option, index) {
                if (optionIndex !== index) {
                    if (option.checked) {
                        isDisabled = true;
                    }
                }
            });
        }
        return isDisabled;
    };
    return SelectAddonPage;
}());
export { SelectAddonPage };
