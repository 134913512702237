import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AlertController, Events, LoadingController, ModalController } from '@ionic/angular';
import { LabelService } from 'src/app/services/label/label.service';
var RateOrderPage = /** @class */ (function () {
    function RateOrderPage(modalController, events, labelService, loadingController, alertController) {
        this.modalController = modalController;
        this.events = events;
        this.labelService = labelService;
        this.loadingController = loadingController;
        this.alertController = alertController;
        this.reviewPh = '';
        this.ratingData = {
            rating: 5,
            review: '',
            photos: [],
            createdAt: new Date()
        };
    }
    RateOrderPage.prototype.ngOnInit = function () {
        this.SHARED_LABELS = this.labelService.labels['SHARED'];
        this.RATE_ORDER_LABELS = this.labelService.labels['RATE_ORDER'];
        this.reviewPh = this.RATE_ORDER_LABELS['review_placeholder'];
    };
    RateOrderPage.prototype.ionViewWillEnter = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.initializeSubscriptions();
                return [2 /*return*/];
            });
        });
    };
    RateOrderPage.prototype.ionViewWillLeave = function () {
        this.removeSubscriptions();
    };
    RateOrderPage.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('rating:submitOrderRatingSuccess', function () {
            if (_this.loading) {
                _this.loading.dismiss();
            }
            ;
            _this.presentAlert(_this.RATE_ORDER_LABELS['thanks_for_rating'], true);
        });
    };
    RateOrderPage.prototype.closeModal = function (status) {
        /* const data = status && status === 'order_rated' ? this.ratingData : null;
         if(data.photos.length) {
           data.photos.forEach(img => {
             img['thumb'] = img.url;
           });
         }
         this.modalController.dismiss(data);*/
        this.modalController.dismiss();
    };
    RateOrderPage.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.presentLoading()];
                    case 1:
                        _a.sent();
                        this.events.publish('rating:submitOrderRating', this.ratingData, this.orderId);
                        return [2 /*return*/];
                }
            });
        });
    };
    RateOrderPage.prototype.rate = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.ratingData.rating = data.rating;
                        this.ratingData.review = data.review;
                        this.ratingData.photos = data.photos;
                        return [4 /*yield*/, this.submit()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    RateOrderPage.prototype.presentLoading = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loadingController.create({
                                message: this.SHARED_LABELS['please_wait'],
                                duration: 10000,
                            })];
                    case 1:
                        _a.loading = _b.sent();
                        return [4 /*yield*/, this.loading.present()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    RateOrderPage.prototype.presentAlert = function (msg, action) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            message: msg,
                            backdropDismiss: false,
                            buttons: [{
                                    text: this.SHARED_LABELS['ok'],
                                    handler: function () {
                                        if (action) {
                                            _this.closeModal('order_rated');
                                        }
                                    }
                                }]
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    RateOrderPage.prototype.removeSubscriptions = function () {
        this.events.unsubscribe('rating:submitOrderRatingSuccess');
    };
    return RateOrderPage;
}());
export { RateOrderPage };
