import * as tslib_1 from "tslib";
import { OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { LabelService } from '../services/label/label.service';
import { SharedService } from '../services/shared/shared.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { WidgetsService } from '../services/widgets/widgets.service';
var PromoModalPage = /** @class */ (function () {
    function PromoModalPage(router, utilsService, modalController, labelService, ngZone, sharedService, widgetService) {
        this.router = router;
        this.utilsService = utilsService;
        this.modalController = modalController;
        this.labelService = labelService;
        this.ngZone = ngZone;
        this.sharedService = sharedService;
        this.widgetService = widgetService;
    }
    PromoModalPage.prototype.ngOnInit = function () {
        // // console.log(this.popup);
        this.PROMO_POPUP_LABELS = this.labelService.labels['PROMO_POPUP'];
        this.CO_CATEGORY_GRID_LABELS = this.labelService.labels['CO_CATEGORY_GRID'];
    };
    PromoModalPage.prototype.onClickImgDocument = function (event) {
        var _this = this;
        // console.log('onClickImgDocument')
        if (!this.imageBox.nativeElement.contains(event.target)) { // or some similar check
            this.ngZone.run(function () {
                _this.closePopup();
            });
        }
    };
    PromoModalPage.prototype.closePopup = function () {
        this.modalController.dismiss();
    };
    PromoModalPage.prototype.onClickPopup = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.closePopup();
                this.widgetService.bannerLink(this.popup);
                return [2 /*return*/];
            });
        });
    };
    return PromoModalPage;
}());
export { PromoModalPage };
