import * as tslib_1 from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import { Events } from '@ionic/angular';
import * as firebase from 'firebase';
import { AngularFireStorage } from '@angular/fire/storage';
import { first, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "@angular/fire/storage";
var BannersService = /** @class */ (function () {
    function BannersService(events, afs, fbStorage) {
        this.events = events;
        this.afs = afs;
        this.fbStorage = fbStorage;
    }
    BannersService.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('banners:uploadBanner', function (index, base64Image, size) {
            _this.uploadBanner(index, base64Image, size);
        });
        this.events.subscribe('banners:getBanners', function () {
            _this.getBanners();
        });
        this.events.subscribe('banners:getSubscribedBanners', function () {
            _this.getSubscribedBanners();
        });
        this.events.subscribe('banners:getBannersActiveStatus', function () {
            _this.getBannersActiveStatus();
        });
        this.events.subscribe('banners:changeBannersStatus', function (status) {
            _this.changeBannersStatus(status);
        });
        this.events.subscribe('banners:removeBanner', function (index) {
            _this.removeBanner(index);
        });
    };
    BannersService.prototype.uploadBanner = function (index, base64Image, size) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var imgRef, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.afs.collection('features').doc('banners').collection('images').doc("image" + index).set({
                                uploadedAt: firebase.firestore.FieldValue.serverTimestamp(),
                                size: size
                            })];
                    case 1:
                        _a.sent();
                        imgRef = this.fbStorage.ref("banners/images/image" + index + "/image" + index + ".png");
                        return [4 /*yield*/, imgRef.putString(base64Image, 'data_url')];
                    case 2:
                        _a.sent();
                        this.events.publish('banners:uploadBannerSuccess');
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.dir(error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    BannersService.prototype.getBanners = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var bannersRef, bannersData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        bannersRef = this.afs.collection('features').doc('webbanners').collection('images');
                        return [4 /*yield*/, bannersRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 1:
                        bannersData = _a.sent();
                        this.events.publish('banners:publishBanners', bannersData);
                        return [2 /*return*/];
                }
            });
        });
    };
    BannersService.prototype.getSubscribedBanners = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var bannersRef, bannersData;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        bannersRef = this.afs.collection('features').doc('banners').collection('images');
                        return [4 /*yield*/, bannersRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); }))];
                    case 1:
                        bannersData = _a.sent();
                        bannersData.subscribe(function (banners) {
                            _this.events.publish('banners:publishSubscribedBanners', banners);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    BannersService.prototype.getBannersActiveStatus = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var activeStatus, error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('features').doc('webbanners').valueChanges().pipe(first()).toPromise()];
                    case 1:
                        activeStatus = _a.sent();
                        this.events.publish('banners:publishBannersActiveStatus', activeStatus);
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        console.dir(error_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    BannersService.prototype.changeBannersStatus = function (status) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        //// // console.log('status in service', status);
                        return [4 /*yield*/, this.afs.collection('features').doc('banners').set({
                                isActive: status
                            })];
                    case 1:
                        //// // console.log('status in service', status);
                        _a.sent();
                        this.events.publish('banners:changeBannersStatusSuccess');
                        return [3 /*break*/, 3];
                    case 2:
                        error_3 = _a.sent();
                        console.dir(error_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    BannersService.prototype.removeBanner = function (index) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('features').doc('banners').collection('images').doc("image" + index).delete()];
                    case 1:
                        _a.sent();
                        this.events.publish('banners:removeBannerSuccess');
                        return [3 /*break*/, 3];
                    case 2:
                        error_4 = _a.sent();
                        console.dir(error_4);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    BannersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BannersService_Factory() { return new BannersService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.AngularFireStorage)); }, token: BannersService, providedIn: "root" });
    return BannersService;
}());
export { BannersService };
