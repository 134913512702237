<ng-container *ngIf="title">
  <div *ngIf="titleStyle?.style == 'style-one' || !titleStyle || !titleStyle?.style"  class="section-title-one" [ngStyle]="{'color': titleStyle?.color?.length > 0 ? titleStyle.color : 'inherit' }">
    <h3>{{title}}</h3>
  </div> 
</ng-container>

<ng-container *ngIf="title || subTitle">
  <div *ngIf="titleStyle?.style == 'style-two'" class="section-title-two" [ngStyle]="{'color': titleStyle?.color?.length > 0 ? titleStyle.color : 'inherit' }">
    <h3>{{title}}</h3>
    <p *ngIf="subTitle">{{subTitle}}</p>
  </div>
</ng-container>