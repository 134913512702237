<ion-header>
  <ion-toolbar class="toolbar">
    <ion-title text-center>{{'RATE_ORDER.header_text' | translate}}</ion-title>
    <ion-buttons slot="end"  >
      <ion-button (click)="closeModal()">
        <ion-icon slot="icon-only" name="close-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>


<ion-content>
  <app-rating type="order" (rateEvent)="rate($event)"></app-rating>
</ion-content>
