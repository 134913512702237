import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BookingService } from 'src/app/services/booking/booking.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { ImageModalPage } from '../../image-modal/image-modal.page';
import { SharedService } from 'src/app/services/shared/shared.service';
var UserBookingDetailsPage = /** @class */ (function () {
    function UserBookingDetailsPage(bookingService, modalController, configService, sharedService) {
        this.bookingService = bookingService;
        this.modalController = modalController;
        this.configService = configService;
        this.sharedService = sharedService;
        this.booking = {};
        this.showLoader = true;
    }
    UserBookingDetailsPage.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        _b = [{ id: this.bookingId }];
                        return [4 /*yield*/, this.bookingService.getBookingData(this.bookingId)];
                    case 1:
                        _a.booking = tslib_1.__assign.apply(void 0, _b.concat([_c.sent()]));
                        this.showLoader = false;
                        this.currencyCode = this.configService.environment.currencyCode;
                        this.taxType = this.configService.environment.taxType;
                        return [2 /*return*/];
                }
            });
        });
    };
    UserBookingDetailsPage.prototype.getImageUrl = function () {
        var imgType = 'thumb';
        if (this.booking.item.coverPic && this.booking.item.coverPic[imgType]) {
            return this.booking.item.coverPic[imgType];
        }
        if (this.booking.item.coverPic && !this.booking.item.coverPic[imgType] && this.booking.item.coverPic.url) {
            return this.booking.item.coverPic.url;
        }
        if (!this.booking.item.coverPic || (!this.booking.item.coverPic[imgType] && !this.booking.item.coverPic.url)) {
            return "assets/img/placeholder-img.jpg";
        }
    };
    UserBookingDetailsPage.prototype.imgZoom = function (img) {
        this.modalController.create({
            component: ImageModalPage,
            cssClass: 'photo-modal-class',
            componentProps: {
                imgs: [{ url: img }],
                index: 0
            }
        }).then(function (modal) { return modal.present(); });
    };
    UserBookingDetailsPage.prototype.getTotalAmount = function () {
        return {
            totalAmount: this.booking.item.price + (this.booking.payment.extraChargeOnPayment && this.booking.payment.extraChargeOnPayment.charge ? this.booking.payment.extraChargeOnPayment.charge : 0),
            totalGst: this.booking.item.totalGst,
        };
    };
    UserBookingDetailsPage.prototype.viewInvoice = function () {
        var url = this.booking.invoice.url;
        window.open(url, '_blank');
    };
    UserBookingDetailsPage.prototype.getBookingItemPrice = function () {
        return this.booking.item.price - this.booking.item.addOns.totalPrice - (this.booking.item.gst.isExclusive ? this.booking.item.gst.total : 0);
    };
    UserBookingDetailsPage.prototype.modalDismiss = function () {
        this.modalController.dismiss();
    };
    UserBookingDetailsPage.prototype.convert24to12 = function (time) {
        return this.sharedService.convert24to12(time);
    };
    UserBookingDetailsPage.prototype.isPartialAvailable = function () {
        return this.booking.payment.partial && this.booking.payment.partial.cod;
    };
    UserBookingDetailsPage.prototype.isCustomInputAvailable = function () {
        return this.booking.additionalInfo && this.booking.additionalInfo.customInput && this.booking.additionalInfo.customInput.value;
    };
    return UserBookingDetailsPage;
}());
export { UserBookingDetailsPage };
