/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./test-page.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "./test-page.page";
import * as i5 from "../../services/design-studio/design-studio.service";
var styles_TestPagePage = [i0.styles];
var RenderType_TestPagePage = i1.ɵcrt({ encapsulation: 0, styles: styles_TestPagePage, data: {} });
export { RenderType_TestPagePage as RenderType_TestPagePage };
export function View_TestPagePage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ion-content", [], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(1, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null)], null, null); }
export function View_TestPagePage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-test-page", [], null, null, null, View_TestPagePage_0, RenderType_TestPagePage)), i1.ɵdid(1, 114688, null, 0, i4.TestPagePage, [i5.DesignStudioService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TestPagePageNgFactory = i1.ɵccf("app-test-page", i4.TestPagePage, View_TestPagePage_Host_0, {}, {}, []);
export { TestPagePageNgFactory as TestPagePageNgFactory };
