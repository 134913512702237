import * as tslib_1 from "tslib";
import { first } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
var ConfigService = /** @class */ (function () {
    function ConfigService(angularFirestore) {
        this.angularFirestore = angularFirestore;
        this.environment = environment;
    }
    ConfigService.prototype.loadConfig = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.angularFirestore.collection('settings').doc('environment').valueChanges().pipe(first()).toPromise().then(function (config) {
                        var configKeys = Object.keys(config);
                        var envKeys = Object.keys(_this.environment);
                        envKeys.forEach(function (key) {
                            if (!configKeys.includes(key)) {
                                config[key] = _this.environment[key];
                            }
                        });
                        _this.environment = config;
                        // // console.log('this.environment ', this.environment);
                    })];
            });
        });
    };
    ConfigService.prototype.getConfig = function () {
        return this.environment;
    };
    ConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigService_Factory() { return new ConfigService(i0.ɵɵinject(i1.AngularFirestore)); }, token: ConfigService, providedIn: "root" });
    return ConfigService;
}());
export { ConfigService };
