import { UserService } from 'src/app/services/user/user.service';
import { Storage } from '@ionic/storage';
import { first, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Events } from '@ionic/angular';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { SharedService } from '../shared/shared.service';

@Injectable({
    providedIn: 'root'
})
export class ServicesFeatureService {
    serviceRef = this.afs.collection('services');
    constructor(private events: Events,
        private afs: AngularFirestore,
        private fbStorage: AngularFireStorage,
        private storage: Storage,
        private userService: UserService,
        private sharedService: SharedService) { }

    initializeSubscriptions() {
        this.events.subscribe('services-feature:getAllServices', () => {
            this.getAllServices();
        });
        this.events.subscribe('services-feature:getAllServicesForHome', () => {
            this.getAllServicesForHome();
        });
        this.events.subscribe('services-feature:getServicesActiveStatus', () => {
            this.getServicesActiveStatus();
        });
        this.events.subscribe('services-feature:changeServiceStatus', (status) => {
            this.changeServiceStatus(status);
        });
        this.events.subscribe('services-feature:deleteService', (sid) => {
            this.deleteService(sid);
        });
        this.events.subscribe('services-feature:saveService', (data, banner, sid) => {
            this.saveService(data, banner, sid);
        });
        this.events.subscribe('services-feature:submitResponse', (res, imgs) => {
            this.submitResponse(res, imgs);
        });
        this.events.subscribe('services-feature:getUserRequests', () => {
            this.getUserRequests();
        });
        this.events.subscribe('services-feature:completeRequest', (rid) => {
            this.completeRequest(rid);
        });
        this.events.subscribe('services-feature:getAllRequests', () => {
            this.getAllRequests();
        });

        this.setServicesInLocalStorage();
    }

    async getServiceWithId(id: string) {
        return new Promise(async (resolve, reject) => {
            const serviceData = await this.afs.collection('services').doc(id).valueChanges().pipe(first()).toPromise();
            resolve(serviceData);
        });
    }

    async getAllServices() {
        try {
            const services = await this.afs.collection('services', ref => ref
                .orderBy('createdAt', 'desc')).snapshotChanges().pipe(
                    map((actions: any) => actions.map(a => {
                        const data = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return { id, ...data };
                    }))
                ).pipe(first()).toPromise();
            this.events.publish('services-feature:publishAllServices', services);
        } catch (error) {
            console.dir(error);
        }
    }


    async getAllServicesForHome() {
        try {
            const region = await this.sharedService.checkRegionIdForApi();
            let regionId = region.regionId;
            let services;
            let servicesRef: AngularFirestoreCollection;
            if (regionId) {
                servicesRef = this.afs.collection('services', ref => ref
                    .orderBy('createdAt', 'desc')
                    .where('regionId', 'array-contains', regionId));
            } else {
                servicesRef = this.afs.collection('services', ref => ref
                    .orderBy('createdAt', 'desc'));
            }
            services = await servicesRef.snapshotChanges().pipe(
                map((actions: any) => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return { id, ...data };
                }))
            ).pipe(first()).toPromise();
            this.events.publish('services-feature:publishAllServicesForHome', services);
        } catch (error) {
            console.dir(error);
        }
    }

    async getServicesActiveStatus(route?) {
        try {
            const status = await this.afs.collection('features').doc('services').valueChanges().pipe(first()).toPromise();
            if (!route) {
                this.events.publish('services-feature:publishServicesActiveStatus', status);
            } else {
                return status;
            }
        } catch (error) {
            console.dir(error);
        }
    }

    async changeServiceStatus(status: boolean) {
        try {
            await this.afs.collection('features').doc('services').set({ isActive: status });
            this.events.publish('services-feature:statusChangeSuccess');
        } catch (error) {
            console.dir(error);
        }
    }

    async deleteService(sid: string) {
        try {
            await this.serviceRef.doc(sid).delete();
            this.events.publish('services-feature:getAllServices');
            this.events.publish('services-feature:deleteServiceSuccess');
        } catch (error) {
            console.dir(error);
        }
    }

    async saveService(data: any, banner: any, sid: string) {
        try {
            let baseImg: any = {};
            let storageImg: any = {};
            if (!sid) {
                sid = this.afs.collection('services').ref.doc().id;
            }
            if (banner.length) {
                banner.forEach(i => {
                    if (i.url.includes('data:image/jpeg;base64,')) {
                        baseImg = i;
                    } else {
                        storageImg = i;
                    }
                });
            }
            await this.afs.collection('services').doc(sid).set({
                name: data.name,
                description: data.description,
                imageMandatory: data.imageMandatory,
                createdAt: data.createdAt,
                banner: storageImg,
            });
            if (baseImg.hasOwnProperty('url')) {
                const imgRef: any = this.fbStorage.ref(`services/${sid}/banner/` + new Date().getTime().toString() + '.png');
                await imgRef.putString(baseImg.url, 'data_url');
                const downloadURL = await imgRef.getDownloadURL().pipe(first()).toPromise();
                await this.afs.collection('services').doc(sid).update({ banner: { url: downloadURL } });
            }
            this.events.publish('services-feature:saveServiceSuccess');
            this.getAllServices();
        } catch (error) {
            console.dir(error);
        }
    }

    async submitResponse(res: any, imgs: any) {
        try {
            res['userName'] = this.userService.getUserName();
            res['userPhone'] = this.userService.getPhoneNo();
            res['userId'] = this.userService.getUserId();
            const sid = this.afs.collection('serviceRequests').ref.doc().id;
            await this.afs.collection('serviceRequests').doc(sid).set(res);
            if (imgs.length) {
                for (const img of imgs) {
                    const imgRef: any = this.fbStorage.ref(`serviceRequests/${sid}/images/` + new Date().getTime().toString() + '.png');
                    await imgRef.putString(img.url, 'data_url');
                }
            }
            this.events.publish('services-feature:submitResponseSuccess');
            this.getUserRequests();
        } catch (error) {
            console.dir(error);
            this.events.publish('services-feature:submitResponseFailure');
        }

    }

    async getUserRequests() {
        const uid = await this.getStorageUid();
        const requests = await this.afs.collection('serviceRequests', ref => ref
            .orderBy('responseAt', 'desc')
            .where('userId', '==', uid))
            .snapshotChanges().pipe(
                map((actions: any) => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return { id, ...data };
                }))
            ).pipe(first()).toPromise();
        this.events.publish('services-feature:publishUserRequests', requests);
    }

    async getStorageUid(): Promise<string> {
        return new Promise(async (resolve, reject) => {
            this.storage.get('uid').then((val: string) => {
                resolve(val);
            });
        });
    }

    async getAllRequests() {
        const requests = await this.afs.collection('serviceRequests', ref => ref
            .orderBy('responseAt', 'desc'))
            .snapshotChanges().pipe(
                map((actions: any) => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return { id, ...data };
                }))
            ).pipe(first()).toPromise();
        this.events.publish('services-feature:publishAllRequests', requests);
    }

    async completeRequest(rid: string) {
        try {
            await this.afs.collection('serviceRequests').doc(rid).update({ status: 'Completed' });
            this.events.publish('services-feature:completeRequestSuccess');
        } catch (error) {
            console.dir(error);
        }
    }

    async getServicesForMenu() {
        try {
            const storageServices = JSON.parse(localStorage.getItem('services')) || [];
            if (!storageServices.length) {
                const services = await this.setServicesInLocalStorage();
                return services;
            } else {
                return storageServices;
            }

        } catch (error) {
            console.dir(error);
        }
    }

    async setServicesInLocalStorage() {
        return new Promise(async (resolve, reject) => {
            const region = await this.sharedService.checkRegionIdForApi();
                let regionId = region.regionId;
                let services;
                let servicesRef: AngularFirestoreCollection;
                if (regionId) {
                    servicesRef = this.afs.collection('services', ref => ref
                        .orderBy('createdAt', 'desc')
                        .where('regionId', 'array-contains', regionId));
                } else {
                    servicesRef = this.afs.collection('services', ref => ref
                        .orderBy('createdAt', 'desc'));
                }
                services = await servicesRef.snapshotChanges().pipe(
                    map((actions: any) => actions.map(a => {
                        const data = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return { id, ...data };
                    }))
                ).pipe(first()).toPromise();

                localStorage.setItem('services', JSON.stringify(services));
                
                resolve(services);
        });
    }

}
