import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { first, map } from 'rxjs/operators';
import { convertSnaps } from '../db-utils';
import { SharedService } from '../shared/shared.service';
import { VendorService } from '../vendor/vendor.service';

@Injectable({
    providedIn: 'root'
})
export class AppointmentService {

    constructor(private afs: AngularFirestore,
        private sharedService: SharedService,
        private vendorService: VendorService) { }

    async bookAppointment(data, slotLimit: number) {
        return new Promise(async (resolve) => {
            try {
                //check slot availability
                let slotAvailable = false;
                let appointments = [];
                if ('variant' in data.item) {
                    appointments = await this.afs.collection('appointments', ref => ref
                        .where('item.id', '==', data.item.id)
                        .where('item.variant', '==', data.item.variant)
                        .where('schedule.date', '==', data.schedule.date)
                        .where('schedule.slot.start', '==', data.schedule.slot.start)
                        .where('schedule.slot.end', '==', data.schedule.slot.end)
                        .where('status', '==', 'accepted'))
                        .valueChanges().pipe(first()).toPromise();
                } else {
                    appointments = await this.afs.collection('appointments', ref => ref
                        .where('item.id', '==', data.item.id)
                        .where('schedule.date', '==', data.schedule.date)
                        .where('schedule.slot.start', '==', data.schedule.slot.start)
                        .where('schedule.slot.end', '==', data.schedule.slot.end)
                        .where('status', '==', 'accepted'))
                        .valueChanges().pipe(first()).toPromise();
                }
                // console.log('appointments', appointments);

                if (appointments.length < slotLimit) {
                    if (this.vendorService.vendorSettings.isMultiVendorAvailable) {
                        if (data.vendor.id) {
                            const vendorDetails: any = await this.vendorService.getVendorData(data.vendor.id);
                            if (vendorDetails && 'appointmentSlotLimit' in vendorDetails && vendorDetails.appointmentSlotLimit) {
                                const vendorAppointments = await this.afs.collection('appointments', ref => ref
                                    .where('vendor.id', '==', data.vendor.id)
                                    .where('schedule.date', '==', data.schedule.date)
                                    .where('schedule.slot.start', '==', data.schedule.slot.start)
                                    .where('schedule.slot.end', '==', data.schedule.slot.end)
                                    .where('status', '==', 'accepted'))
                                    .valueChanges().pipe(first()).toPromise();
                                if (vendorAppointments.length < vendorDetails.appointmentSlotLimit) {
                                    slotAvailable = true;
                                } else {
                                    slotAvailable = false;
                                }
                            } else {
                                slotAvailable = true;
                            }
                        } else {
                            slotAvailable = true;
                        }
                    } else {
                        slotAvailable = true;
                    }

                } else {
                    slotAvailable = false;
                }
                if (slotAvailable) {
                    await this.afs.collection('appointments').add(data);
                    resolve('added');
                } else {
                    resolve('not_available');
                }
            } catch (error) {
                // console.log(error);
                resolve('error');
            }
        });
    }

    async getUserAppointments() {
        return new Promise<any[]>(async (resolve) => {
            try {
                const userId = await this.sharedService.getStorageUid();
                const appointments = await this.afs.collection('appointments', ref => ref
                    .orderBy('createdAt', 'desc')
                    .where('user.id', '==', userId))
                    .snapshotChanges()
                    .pipe(map(snaps => convertSnaps(snaps)))
                    .pipe(first()).toPromise();
                resolve(appointments);
            } catch (error) {
                // console.log(error);
                resolve([]);
            }
        });
    }
}
