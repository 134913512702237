
<ion-content>
  <div class="product-container">
  <ion-button class="modal-dismiss" (click)="modalDismiss()" fill="clear" color="medium">
    <ion-icon slot="icon-only" name="close-circle"></ion-icon>
 </ion-button>

  <div class="pricelist-product-detail">
     <ion-grid >
        <ion-row>
           <ion-col size="4">
              <div class="prd-img">
               <ng-container *ngIf="useThumb">
                  <ion-img class="product-image" *ngIf="product.data.coverPic && product.data.coverPic.thumb"
                  [src]="product.data.coverPic.thumb" [alt]="product.data.prodName.trim()"></ion-img>
            
                  <ion-img class="product-image" *ngIf="product.data.coverPic && !product.data.coverPic.thumb && product.data.coverPic.mob"
                  [src]="product.data.coverPic.mob" [alt]="product.data.prodName.trim()"></ion-img>
            
                <ion-img class="product-image" *ngIf="product.data.coverPic && !product.data.coverPic.thumb &&  !product.data.coverPic.mob && product.data.coverPic.url"
                  [src]="product.data.coverPic.url" [alt]="product.data.prodName.trim()"></ion-img>
                <ion-img class="product-image" *ngIf="!product.data.coverPic" src="assets/img/placeholder-img.jpg"
                  [alt]="product.data.prodName.trim()"></ion-img>
                </ng-container>
            
                <ng-container *ngIf="!useThumb">
                  <ion-img class="product-image" *ngIf="product.data.coverPic && product.data.coverPic.mob"
                  [src]="product.data.coverPic.mob" [alt]="product.data.prodName.trim()"></ion-img>
            
                <ion-img class="product-image" *ngIf="product.data.coverPic && !product.data.coverPic.mob && product.data.coverPic.url"
                  [src]="product.data.coverPic.url" [alt]="product.data.prodName.trim()"></ion-img>
            
                  <ion-img class="product-image" *ngIf="product.data.coverPic && !product.data.coverPic.mob && !product.data.coverPic.url && product.data.coverPic.thumb"
                  [src]="product.data.coverPic.thumb" [alt]="product.data.prodName.trim()"></ion-img>
            
                <ion-img class="product-image" *ngIf="!product.data.coverPic" src="assets/img/placeholder-img.jpg"
                  [alt]="product.data.prodName.trim()"></ion-img>
                </ng-container>
              </div>
           </ion-col>
           <ion-col size="8">
              <div class="prd-details">
                 <h3>{{product.data.prodName}}</h3>
                <div class="btn-wrap">
                  <ion-button (click)="modalDismiss()" shape="round" class="btn-sml" fill="outline" [routerLink]="[productURL]">
                    View Details
                  </ion-button>
                </div>
              </div>
           </ion-col>
        </ion-row>
     </ion-grid>
  </div>
  <div class="list-container" *ngIf="mainListOfWeightsAndPrices && mainListOfWeightsAndPrices.length > 0">
     <div class="list-wrap" *ngFor="let element of mainListOfWeightsAndPrices; let i=index">
        <div class="cnt-wrap">
           <p class="item-name">{{getPriceListWeight(element)}}</p>
           <ng-container *ngIf="element.discountedPrice && (element.discountedPrice !== element.price); else showOriginalPrice;">
            <p class="item-price" > {{element.discountedPrice | currency: currencyCode:true:'.2-2'}}<del class="del-price">{{element.price | currency: currencyCode:true:'.2-2'}}</del> <span *ngIf="product.data.variantType === 'pieces'" class="per-piece"> / Pc</span></p>
            <span class="item-discount-label">
              {{getDiscountPercent(element.discountedPrice, element.price)}}% off
            </span>
           </ng-container>
           
           <ng-template #showOriginalPrice>
              <p class="item-price">{{element.price | currency: currencyCode:true:'.2-2'}}<span *ngIf="product.data.variantType === 'pieces'" class="per-piece"> / Pc</span></p>
           </ng-template>

           <!-- qty left msg -->
           <ion-text color="danger" *ngIf="showQtyLeftMsg(+element.totalQuantity)">
              <p>
                 {{'PRODUCT_DETAILS.qty_left_msg' | translate: {'qty': element.totalQuantity} }}
              </p>
           </ion-text>
           <!-- qty left msg -->

        </div>
        
        <ng-container *ngIf="product.data.productType === 'appointment'; else normalProduct;">
         <div class="cart-counter">
           <ion-button (click)="bookAppointment(i)" expand="block" size="small">
             {{'PRICELIST_MODAL.book_now' | translate}}
           </ion-button>
         </div>
       </ng-container>

       <ng-template #normalProduct>
       
         <div class="btn-wrap">
            <ion-button class="btn-2" color="danger" shape="round" *ngIf="product.data.stopWhenNoQty && element.totalQuantity && element.totalQuantity === '0'; else productAvailable;"> out of stock </ion-button>
            <ng-template #productAvailable>
               <ng-container *ngIf="forSubscribe; else noforSubscribe"><ion-button shape="round" class="btn-2 cart-btn" (click)="subscribe(i)" [disabled]="product.data.stopWhenNoQty && element.totalQuantity === '0'" *ngIf="forSubscribe"> <span class="text"> {{'BUYNOW_PRICELIST_MODAL.subscribe' | translate}}</span> <span class="icon"><i class="flaticon-null-28"></i></span> </ion-button></ng-container>
               <ng-template #noforSubscribe>
                  <ion-button shape="round" class="btn-2 cart-btn" (click)="addProductToCart(i)" *ngIf="!isProductInCart(element); else productInCart"> <span class="text">Add </span> <span class="icon"><i class="flaticon-null-28"></i></span> </ion-button> 
                  <ng-template #productInCart>
                     <div class="cart-btn-wrap prd-in-cart">
                        <ion-button class="btn-2 i-only decrement" (click)="decrementQuantity(i)"> <i class="flaticon-null-27"></i> </ion-button>
                        <span class="cart-qty">{{element.cartQty}}</span> 
                        <ng-container *ngIf="product.data.maxQty && (element.totalQuantity / 1)">
                           <ion-button class="btn-2 i-only increment" (click)="incrementQuantity(i)" *ngIf="(element.cartQty < product.data.maxQty) && (element.cartQty < (element.totalQuantity / 1));else cantAddMoreQty;"> <i class="flaticon-null-28"></i> </ion-button>
                           <ng-template #cantAddMoreQty> <ion-button class="btn-2 i-only increment" (click)="maxQtyReached()" [disabled]="true"> <i class="flaticon-null-28"></i> </ion-button> </ng-template>
                        </ng-container>
                        <ng-container *ngIf="!product.data.maxQty && (element.totalQuantity / 1)"> <ion-button class="btn-2 i-only increment" (click)="incrementQuantity(i)" *ngIf="element.cartQty < (element.totalQuantity / 1)"> <i class="flaticon-null-28"></i> </ion-button><ion-button class="btn-2 i-only increment" (click)="maxQtyReached()" *ngIf="element.cartQty >= (element.totalQuantity / 1)"> <i class="flaticon-null-28"></i> </ion-button> </ng-container>
                        <ng-container *ngIf="product.data.maxQty && !(element.totalQuantity / 1)"><ion-button class="btn-2 i-only increment" (click)="incrementQuantity(i)" *ngIf="element.cartQty < (product.data.maxQty)"> <i class="flaticon-null-28"></i> </ion-button><ion-button class="btn-2 i-only increment" (click)="maxQtyReached()" *ngIf="element.cartQty >= (product.data.maxQty)"> <i class="flaticon-null-28"></i> </ion-button> </ng-container>
                        <ng-container *ngIf="!product.data.maxQty && !(element.totalQuantity / 1)">
                           <ion-button class="btn-2 i-only increment" (click)="incrementQuantity(i)"> <i class="flaticon-null-28"></i> </ion-button>
                        </ng-container>
                     </div>
                  </ng-template>
               </ng-template>
            </ng-template>
         </div>
       </ng-template>
     </div>



  

  
  </div>
</div>
</ion-content>
