import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LabelService } from 'src/app/services/label/label.service';
import { SharedService } from 'src/app/services/shared/shared.service';

@Component({
  selector: 'app-cancelled-reason',
  templateUrl: './cancelled-reason.page.html',
  styleUrls: ['./cancelled-reason.page.scss'],
})
export class CancelledReasonPage implements OnInit {

  reason = '';
  constructor(private modalController: ModalController,
    private sharedService: SharedService,
    private labelService: LabelService) { }

  ngOnInit() {
  }

  submit() {
    if(!this.reason) {
      this.sharedService.presentAlert(this.labelService.labels['CANCELLED_REASON']['provide_valid_reason']);
      return;
    }
    this.closeModal(this.reason);
  }

  closeModal(reason?: string) {
    this.modalController.dismiss(reason);
  }


}
