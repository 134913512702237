/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./section-title.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./section-title.component";
var styles_SectionTitleComponent = [i0.styles];
var RenderType_SectionTitleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SectionTitleComponent, data: {} });
export { RenderType_SectionTitleComponent as RenderType_SectionTitleComponent };
function View_SectionTitleComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "section-title-one"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "color": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, ((((_co.titleStyle == null) ? null : ((_co.titleStyle.color == null) ? null : _co.titleStyle.color.length)) > 0) ? _co.titleStyle.color : "inherit")); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.title; _ck(_v, 5, 0, currVal_1); }); }
function View_SectionTitleComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SectionTitleComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((((_co.titleStyle == null) ? null : _co.titleStyle.style) == "style-one") || !_co.titleStyle) || !((_co.titleStyle == null) ? null : _co.titleStyle.style)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_SectionTitleComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.subTitle; _ck(_v, 1, 0, currVal_0); }); }
function View_SectionTitleComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "section-title-two"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "color": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SectionTitleComponent_5)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, ((((_co.titleStyle == null) ? null : ((_co.titleStyle.color == null) ? null : _co.titleStyle.color.length)) > 0) ? _co.titleStyle.color : "inherit")); _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.subTitle; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.title; _ck(_v, 5, 0, currVal_1); }); }
function View_SectionTitleComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SectionTitleComponent_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.titleStyle == null) ? null : _co.titleStyle.style) == "style-two"); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SectionTitleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SectionTitleComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SectionTitleComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.title || _co.subTitle); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_SectionTitleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-section-title", [], null, null, null, View_SectionTitleComponent_0, RenderType_SectionTitleComponent)), i1.ɵdid(1, 114688, null, 0, i3.SectionTitleComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SectionTitleComponentNgFactory = i1.ɵccf("app-section-title", i3.SectionTitleComponent, View_SectionTitleComponent_Host_0, { title: "title", subTitle: "subTitle", titleStyle: "titleStyle" }, {}, []);
export { SectionTitleComponentNgFactory as SectionTitleComponentNgFactory };
