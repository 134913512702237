import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Events, NavParams, ModalController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { Storage } from '@ionic/storage';
import { NewAddressPage } from "../new-address/new-address.page";
import { UtilsService } from '../services/utils/utils.service';
import { SharedService } from '../services/shared/shared.service';
var SelectAddressPage = /** @class */ (function () {
    function SelectAddressPage(events, router, storage, navParams, UtilsService, modalController, activatedRoute, sharedService) {
        this.events = events;
        this.router = router;
        this.storage = storage;
        this.navParams = navParams;
        this.UtilsService = UtilsService;
        this.modalController = modalController;
        this.activatedRoute = activatedRoute;
        this.sharedService = sharedService;
        this.addressesData = [];
        this.showLoader = true;
        this.showAddAddressBtn = false;
        this.addressType = 'shipping';
        this.pageType = 'page';
        if (this.pageType === 'page') {
            this.addressType = navParams.get('type');
        }
    }
    SelectAddressPage.prototype.ngOnInit = function () {
        var _this = this;
        this.initializeSubscription();
        this.events.publish('user:getAllSavedAddresses');
        setTimeout(function () {
            _this.showAddAddressBtn = true;
        }, 500);
    };
    SelectAddressPage.prototype.ionViewWillEnter = function () {
        var _this = this;
        this.storage.get('userDefaultAddress').then(function (address) {
            _this.addressFromStorage = address;
        });
    };
    SelectAddressPage.prototype.ngOnDestroy = function () {
        this.removeSubscription();
    };
    SelectAddressPage.prototype.initializeSubscription = function () {
        var _this = this;
        this.events.subscribe('user:publishAllSavedAddresses', function (allAddresses) {
            _this.addressesData = allAddresses;
            _this.UtilsService.consoleLog('address modal address', _this.addressesData);
            _this.showLoader = false;
        });
    };
    /*radioSelect(event) {
      //// // console.log(event.target.value);
      this.selectedAddress = event.target.value;
    }*/
    SelectAddressPage.prototype.modalDismiss = function () {
        this.modalController.dismiss();
    };
    SelectAddressPage.prototype.selectAddress = function (address) {
        this.selectedAddress = address;
    };
    SelectAddressPage.prototype.goToPage = function (page) {
        if (this.pageType === 'modal') {
            this.openNewAddressModal();
        }
        else {
            var navigationExtras = {
                state: {
                    routeFromSelectAddress: true,
                    type: this.addressType,
                }
            };
            this.router.navigate([page], navigationExtras);
        }
    };
    SelectAddressPage.prototype.addNewAddress = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: NewAddressPage,
                            cssClass: 'custom-modal',
                            componentProps: {
                                routeFromSelectAddress: true,
                                type: this.addressType
                            }
                        })];
                    case 1:
                        modal = _a.sent();
                        return [4 /*yield*/, modal.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SelectAddressPage.prototype.editAddress = function (address) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.pageType === 'modal')) return [3 /*break*/, 1];
                        this.openNewAddressModal(address);
                        return [3 /*break*/, 4];
                    case 1: return [4 /*yield*/, this.modalController.create({
                            component: NewAddressPage,
                            cssClass: 'custom-modal',
                            componentProps: {
                                addressData: address,
                                addressLength: this.addressesData.length,
                                routeFromSelectAddress: true,
                                type: this.addressType
                            }
                        })];
                    case 2:
                        modal = _a.sent();
                        return [4 /*yield*/, modal.present()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    SelectAddressPage.prototype.closeModal = function () {
        this.modalController.dismiss({ closed: true });
    };
    SelectAddressPage.prototype.onClickDeliverHere = function () {
        if (!this.selectedAddress) {
            if (this.addressesData[0]) {
                this.selectedAddress = this.addressesData[0];
            }
            else {
                this.sharedService.presentAlert('Please add address');
                return;
            }
        }
        if (this.addressType === 'shipping') {
            this.storage.set('userDefaultAddress', this.selectedAddress);
        }
        else {
            this.storage.set('userBillingAddress', this.selectedAddress);
        }
        if (this.pageType === 'modal') {
            this.modalController.dismiss({ closed: false });
        }
        else {
            this.router.navigate(['order-summary']);
        }
    };
    SelectAddressPage.prototype.removeSubscription = function () {
        this.events.unsubscribe('user:publishAllSavedAddresses');
    };
    SelectAddressPage.prototype.openNewAddressModal = function (address) {
        if (address === void 0) { address = null; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: NewAddressPage,
                            componentProps: {
                                pageType: 'modal',
                                editAddressData: address,
                                addressLength: this.addressesData.length,
                            }
                        })];
                    case 1:
                        modal = _a.sent();
                        modal.onDidDismiss().then(function (res) {
                            if (res && res.data && !res.data.closed) {
                                _this.events.publish('user:getAllSavedAddresses');
                            }
                        });
                        return [4 /*yield*/, modal.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return SelectAddressPage;
}());
export { SelectAddressPage };
