import { ConfigService } from '../config/config.service';
import * as i0 from "@angular/core";
import * as i1 from "../config/config.service";
var LogglyLoggerService = /** @class */ (function () {
    function LogglyLoggerService(configService) {
        this.configService = configService;
        if (typeof LogglyTracker !== 'undefined') {
            this.loggly = new LogglyTracker();
            if (this.loggly) {
                this.loggly.push({
                    logglyKey: this.configService.environment.LOGGLY_API_KEY,
                    sendConsoleErrors: true,
                    tag: "" + this.removeSpace(this.configService.environment.storeName)
                });
            }
        }
    }
    LogglyLoggerService.prototype.log = function (error) {
        if (this.loggly) {
            this.loggly.push({ message: error.message, stack: error.stack });
        }
    };
    LogglyLoggerService.prototype.removeSpace = function (value) {
        return value.replace(/\s/g, '_').toLowerCase();
    };
    LogglyLoggerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LogglyLoggerService_Factory() { return new LogglyLoggerService(i0.ɵɵinject(i1.ConfigService)); }, token: LogglyLoggerService, providedIn: "root" });
    return LogglyLoggerService;
}());
export { LogglyLoggerService };
