<ion-content class="ion-no-padding" (click)="closePopup()">
  <div class="promo-container">
    <div class="popup-close" >
      <i class="flaticon-null-19"></i>
    </div>
    <div class="popup-inner-content" >
      <ion-img *ngIf="popup.banner.mob" [src]="popup.banner.mob" useImg #imageBox (click)="onClickPopup()"></ion-img>
      <ion-img *ngIf="!popup.banner.mob && popup.banner.url" [src]="popup.banner.url" useImg #imageBox (click)="onClickPopup()"></ion-img>
    </div>
  </div>
</ion-content>

