/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-modal.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "../../components/widgets/widget-form/widget-form.component.ngfactory";
import * as i5 from "../../components/widgets/widget-form/widget-form.component";
import * as i6 from "../../services/widgets/widgets.service";
import * as i7 from "../../services/shared/shared.service";
import * as i8 from "../../services/label/label.service";
import * as i9 from "@angular/platform-browser";
import * as i10 from "./form-modal.page";
var styles_FormModalPage = [i0.styles];
var RenderType_FormModalPage = i1.ɵcrt({ encapsulation: 0, styles: styles_FormModalPage, data: {} });
export { RenderType_FormModalPage as RenderType_FormModalPage };
export function View_FormModalPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "ion-header", [["no-border", ""]], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 4, "ion-toolbar", [], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(3, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "ion-title", [], null, null, null, i2.View_IonTitle_0, i2.RenderType_IonTitle)), i1.ɵdid(5, 49152, null, 0, i3.IonTitle, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["Submit form"])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "ion-content", [["class", "ion-padding"]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(8, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "app-widget-form", [["source", "modal"]], null, null, null, i4.View_WidgetFormComponent_0, i4.RenderType_WidgetFormComponent)), i1.ɵdid(10, 114688, null, 0, i5.WidgetFormComponent, [i6.WidgetsService, i7.SharedService, i8.LabelService, i9.DomSanitizer, i3.NavController, i3.ModalController], { widgetID: [0, "widgetID"], source: [1, "source"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formId; var currVal_1 = "modal"; _ck(_v, 10, 0, currVal_0, currVal_1); }, null); }
export function View_FormModalPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-form-modal", [], null, null, null, View_FormModalPage_0, RenderType_FormModalPage)), i1.ɵdid(1, 114688, null, 0, i10.FormModalPage, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormModalPageNgFactory = i1.ɵccf("app-form-modal", i10.FormModalPage, View_FormModalPage_Host_0, {}, {}, []);
export { FormModalPageNgFactory as FormModalPageNgFactory };
