import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LoadScriptsService } from 'src/app/services/load-scripts/load-scripts.service';
import { OrderService } from 'src/app/services/order/order.service';
import { WalletService } from 'src/app/services/wallet/wallet.service';
var CashfreePage = /** @class */ (function () {
    function CashfreePage(orderService, modalController, loadScriptsService, walletService) {
        this.orderService = orderService;
        this.modalController = modalController;
        this.loadScriptsService = loadScriptsService;
        this.walletService = walletService;
        this.showLoader = true;
        this.loadScriptsService.loadPaymentLibraries();
    }
    CashfreePage.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cashfree, paymentDom, success, failure, token, _a, cashfreeToken, orderCashfree, dropConfig;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        cashfree = new Cashfree();
                        paymentDom = document.getElementById("payment-form");
                        if (!this.walletTopup) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.walletService.createOrderCashfree(this.orderData)];
                    case 1:
                        token = _b.sent();
                        success = function (data) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(data.order && data.order.status == "PAID")) return [3 /*break*/, 2];
                                        this.closeModal();
                                        return [4 /*yield*/, this.walletService.getOrderDetailsCashfree(data.order.orderId, this.orderData)];
                                    case 1:
                                        _a.sent();
                                        return [3 /*break*/, 3];
                                    case 2:
                                        // console.log('inside else of not paid');
                                        this.closeModal();
                                        this.walletService.presentFailureAlert();
                                        _a.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); };
                        failure = function (data) {
                            // console.log('inisde failure');
                            _this.closeModal();
                            _this.walletService.presentFailureAlert();
                        };
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.orderService.createOrderCashfree(this.orderData, this.autoConfirm)];
                    case 3:
                        _a = _b.sent(), cashfreeToken = _a.cashfreeToken, orderCashfree = _a.orderCashfree;
                        token = cashfreeToken;
                        // console.log('token:', cashfreeToken);
                        this.orderData = orderCashfree;
                        success = function (data) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(data.order && data.order.status == "PAID")) return [3 /*break*/, 2];
                                        this.closeModal();
                                        //this.cashfreeService.paymentSuccess({...this.orderData, orderId: data.order.orderId});
                                        return [4 /*yield*/, this.orderService.getOrderDetailsCashfree(data.order.orderId, this.orderData, this.autoConfirm)];
                                    case 1:
                                        //this.cashfreeService.paymentSuccess({...this.orderData, orderId: data.order.orderId});
                                        _a.sent();
                                        return [3 /*break*/, 3];
                                    case 2:
                                        this.closeModal();
                                        this.orderService.cashfreePaymentFailed(this.autoConfirm, this.orderData);
                                        this.orderService.presentFailureAlert();
                                        _a.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); };
                        failure = function (data) {
                            _this.closeModal();
                            _this.orderService.cashfreePaymentFailed(_this.autoConfirm, _this.orderData);
                            _this.orderService.presentFailureAlert();
                        };
                        _b.label = 4;
                    case 4:
                        dropConfig = {
                            "components": [
                                "order-details",
                                "card",
                                "netbanking",
                                "app",
                                "upi-collect",
                                "upi-intent"
                            ],
                            "orderToken": token,
                            "onSuccess": success,
                            "onFailure": failure,
                            "style": {
                                "backgroundColor": "#ffffff",
                                "color": "#11385b",
                                "fontFamily": "Lato",
                                "fontSize": "14px",
                                "errorColor": "#ff0000",
                                "theme": "light",
                            }
                        };
                        this.showLoader = false;
                        cashfree.initialiseDropin(paymentDom, dropConfig);
                        return [2 /*return*/];
                }
            });
        });
    };
    CashfreePage.prototype.closeModal = function () {
        this.modalController.dismiss();
    };
    return CashfreePage;
}());
export { CashfreePage };
