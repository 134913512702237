import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { LoadingController, ModalController, Events } from '@ionic/angular';
var PaymentModalPage = /** @class */ (function () {
    function PaymentModalPage(events, loadingController, modalController) {
        this.events = events;
        this.loadingController = loadingController;
        this.modalController = modalController;
    }
    PaymentModalPage.prototype.ngOnInit = function () { };
    PaymentModalPage.prototype.ionViewWillEnter = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.loader = true;
                this.initializeSubscriptions();
                this.events.publish('chat:getPaymentDetails');
                return [2 /*return*/];
            });
        });
    };
    PaymentModalPage.prototype.ionViewWillLeave = function () {
        this.removeSubscriptions();
    };
    PaymentModalPage.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('chat:publishPaymentDetails', function (paymentData) {
            _this.paymentData = paymentData;
            _this.loader = false;
        });
    };
    PaymentModalPage.prototype.closeModal = function () {
        this.modalController.dismiss();
    };
    PaymentModalPage.prototype.removeSubscriptions = function () {
        this.events.unsubscribe('chat:publishPaymentDetails');
    };
    return PaymentModalPage;
}());
export { PaymentModalPage };
