<ion-header class="modal-header">
  <ion-toolbar>
    <ion-title class="modal-header-title">Make Payment</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>      
  <div class="spinner" *ngIf="showLoader">
  <ion-spinner color="primary"></ion-spinner>
</div>
<div id="payment-form"></div>
</ion-content>