import * as tslib_1 from "tslib";
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Events } from '@ionic/angular';
import { first } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
import { Storage } from '@ionic/storage';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "@ionic/angular";
import * as i3 from "@angular/fire/storage";
import * as i4 from "@ionic/storage";
var MediaService = /** @class */ (function () {
    function MediaService(afs, events, fbStorage, storage) {
        this.afs = afs;
        this.events = events;
        this.fbStorage = fbStorage;
        this.storage = storage;
        this.chatImage = {
            url: null,
            size: null,
            uploadedAt: null,
            userId: null
        };
        this.uid = '';
    }
    MediaService.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('media:addChatImage', function (uid, msg, imageResponse) {
            //// // console.log('in media service 1');
            _this.addChatImage(uid, msg, imageResponse);
        });
        this.events.subscribe('media:broadcastMessage', function (imageResponse, msg) {
            //// // console.log('in media service 2');
            _this.broadcastMessage(imageResponse, msg);
        });
        this.mediaRef = this.afs.collection('media');
    };
    MediaService.prototype.addChatImage = function (uid, msg, imageResponse) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var listofImageId, listOfDownloadUrls, msgRef, lastMsgData, i, mediaDocRef, imgRef;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        listofImageId = [];
                        listOfDownloadUrls = [];
                        msg.imageCount = imageResponse.length;
                        return [4 /*yield*/, this.afs.collection('chats').doc(uid).valueChanges().pipe(first()).toPromise()];
                    case 1:
                        lastMsgData = _a.sent();
                        if (!(msg.author === "user")) return [3 /*break*/, 6];
                        if (!(lastMsgData.adminActive === false)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.afs.collection('chats').doc(uid).collection('messages').add(msg)];
                    case 2:
                        msgRef = _a.sent();
                        this.afs.collection('chats').doc(uid).update({ unreadMsgs: lastMsgData.unreadMsgs + 1 });
                        return [3 /*break*/, 5];
                    case 3:
                        msg.isRead = true;
                        return [4 /*yield*/, this.afs.collection('chats').doc(uid).collection('messages').add(msg)];
                    case 4:
                        msgRef = _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 10];
                    case 6:
                        if (!(lastMsgData.userActive === false)) return [3 /*break*/, 8];
                        return [4 /*yield*/, this.afs.collection('chats').doc(uid).collection('messages').add(msg)];
                    case 7:
                        msgRef = _a.sent();
                        this.afs.collection('chats').doc(uid).update({ unreadAdminMsgs: lastMsgData.unreadAdminMsgs + 1 });
                        return [3 /*break*/, 10];
                    case 8:
                        msg.isRead = true;
                        return [4 /*yield*/, this.afs.collection('chats').doc(uid).collection('messages').add(msg)];
                    case 9:
                        msgRef = _a.sent();
                        _a.label = 10;
                    case 10:
                        //// // console.log('msg.images.length', imageResponse.length);
                        this.events.publish('media:showUnsavedImages', msgRef.id, imageResponse);
                        i = 0;
                        _a.label = 11;
                    case 11:
                        if (!(i < imageResponse.length)) return [3 /*break*/, 15];
                        //// // console.log('i in loop:', i);
                        this.chatImage.url = "";
                        this.chatImage.size = imageResponse[i].size;
                        this.chatImage.uploadedAt = new Date();
                        this.chatImage.userId = uid;
                        return [4 /*yield*/, this.mediaRef.doc('images').collection('chats').add(this.chatImage)];
                    case 12:
                        mediaDocRef = _a.sent();
                        imgRef = this.fbStorage.ref("chats/" + uid + "/messages/" + msgRef.id + "/images/" + mediaDocRef.id + '.png');
                        return [4 /*yield*/, imgRef.putString(imageResponse[i].url, 'data_url')];
                    case 13:
                        _a.sent();
                        _a.label = 14;
                    case 14:
                        i++;
                        return [3 /*break*/, 11];
                    case 15:
                        this.afs.collection('chats').doc(uid).update({ lastMessage: 'Uploaded an image, click here to see details.',
                            lastMessageAt: msg.createdAt, totalMsgs: lastMsgData.totalMsgs + 1 });
                        this.events.publish('media:chatImageSuccess');
                        return [2 /*return*/];
                }
            });
        });
    };
    MediaService.prototype.broadcastMessage = function (imageResponse, msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var broadcastDocId, j, imgRef, downloadURL, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 10, , 11]);
                        broadcastDocId = this.afs.collection('broadcast').ref.doc().id;
                        if (!(imageResponse.length !== 0)) return [3 /*break*/, 7];
                        j = 0;
                        _a.label = 1;
                    case 1:
                        if (!(j < imageResponse.length)) return [3 /*break*/, 5];
                        imgRef = this.fbStorage.ref("broadcast/" + broadcastDocId + "/images/" + new Date().getTime().toString() + '.png');
                        return [4 /*yield*/, imgRef.putString(imageResponse[j].url, 'data_url')];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, imgRef.getDownloadURL().pipe(first()).toPromise()];
                    case 3:
                        downloadURL = _a.sent();
                        msg.images.push({ url: downloadURL });
                        _a.label = 4;
                    case 4:
                        j++;
                        return [3 /*break*/, 1];
                    case 5: return [4 /*yield*/, this.afs.collection('broadcast').doc(broadcastDocId).set(msg)];
                    case 6:
                        _a.sent();
                        return [3 /*break*/, 9];
                    case 7: return [4 /*yield*/, this.afs.collection('broadcast').doc(broadcastDocId).set(msg)];
                    case 8:
                        _a.sent();
                        _a.label = 9;
                    case 9:
                        this.events.publish('media:broadcastMessageSuccess');
                        return [3 /*break*/, 11];
                    case 10:
                        error_1 = _a.sent();
                        console.dir(error_1);
                        this.events.publish('media:broadcastMessageFailure');
                        return [3 /*break*/, 11];
                    case 11: return [2 /*return*/];
                }
            });
        });
    };
    MediaService.prototype.removeSubscriptions = function () {
        this.events.unsubscribe('media:addChatImage');
        this.events.unsubscribe('media:broadcastMessage');
    };
    MediaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MediaService_Factory() { return new MediaService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.Events), i0.ɵɵinject(i3.AngularFireStorage), i0.ɵɵinject(i4.Storage)); }, token: MediaService, providedIn: "root" });
    return MediaService;
}());
export { MediaService };
