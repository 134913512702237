import * as i0 from "@angular/core";
var ProductUtilsService = /** @class */ (function () {
    function ProductUtilsService() {
    }
    ProductUtilsService.prototype.getBookingDuration = function (product) {
        var duration = null;
        if (product.scheduleData && product.scheduleData.duration) {
            var months = this.getDurationKeyString('month', product.scheduleData.duration.months);
            var days = this.getDurationKeyString('day', product.scheduleData.duration.days);
            var hours = this.getDurationKeyString('hr', product.scheduleData.duration.hours);
            var minutes = this.getDurationKeyString('min', product.scheduleData.duration.minutes);
            duration = months + " " + days + " " + hours + " " + minutes;
        }
        return duration;
    };
    ProductUtilsService.prototype.getDurationKeyString = function (key, value) {
        return value ? value > 1 ? value + " " + key + "s" : value + " " + key : '';
    };
    ProductUtilsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProductUtilsService_Factory() { return new ProductUtilsService(); }, token: ProductUtilsService, providedIn: "root" });
    return ProductUtilsService;
}());
export { ProductUtilsService };
