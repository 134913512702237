import { Component, OnInit } from '@angular/core';
import { OrderService } from 'src/app/services/order/order.service';
import { LoadingController, ToastController, ModalController } from '@ionic/angular';
import { LabelService } from 'src/app/services/label/label.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { ConfigService } from 'src/app/services/config/config.service';

@Component({
  selector: 'app-phonepe',
  templateUrl: './phonepe.page.html',
  styleUrls: ['./phonepe.page.scss'],
})
export class PhonepePage implements OnInit {

  SHARED_LABELS: any = {};
  loading: any;
  orderData: any;
  autoConfirm: any;
  orderDocId: any;
  constructor(
    private orderService: OrderService,
    private modalController: ModalController,
    private loadingController: LoadingController,
    private labelService: LabelService,
    private toastController: ToastController,
    private sharedService: SharedService,
    private configService: ConfigService
  ) { }

  async ngOnInit() { }

  async ionViewWillEnter() {
    this.SHARED_LABELS = this.labelService.labels['SHARED'];
    await this.getCheckoutUrl();
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      message: this.SHARED_LABELS['please_wait'],
      // message: 'loading....',
    });
    await this.loading.present();
  }

  async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
    });
    toast.present();
  }

  closeModal() {
    this.modalController.dismiss();
  }

  async getCheckoutUrl() {
    console.log('Attempting to get checkout url!!!')
    const orderRes: any = await this.orderService.paymentGatewayProcessor(this.orderData)
    console.log("orderRes: ", orderRes)
    this.orderData['orderDocId'] = orderRes.orderDocId;
    const paymentUrl: string = await this.orderService.getPhonepeCheckoutUrl(this.orderData);
    if(paymentUrl) {
      window.open(paymentUrl, "_self");
    } else {
      this.orderService.presentFailureAlert();
    }
  }

  async timeDelay(time: any) {
    return new Promise(async (resolve, rejects) => {
      setTimeout(() => {
        resolve(true)
      }, time * 1000);
    })
  }

  // async initWebview(checkoutUrl: string) {
  //   const browser: InAppBrowserObject = this.inAppBrowser.create(checkoutUrl, '_blank', 'toolbarcolor=#fafafa,hideurlbar=yes');
  //   browser.on('loadstop').subscribe(async (event) => {
  //     let finalUrl = event.url;
  //     if (this.sharedService.loading) {
  //       this.sharedService.loading.dismiss()
  //     }
  //     if (this.loading) {
  //       this.loading.dismiss()
  //     }
  //     if (finalUrl.includes('phonepeResponseHandler')) {
  //       await this.timeDelay(5)
  //       let orderState = await this.orderService.checkOrderStatus(this.orderData['orderDocId'])
  //       if (orderState.status) {
  //         browser.close();
  //         if (this.loading) {
  //           this.loading.dismiss()
  //         }
  //         console.log('order state : ', orderState)
  //         this.orderService.presentAlert("Payment has been completed successfully!");
  //         return;
  //       } else {
  //         // await this.orderService.paymentFailedUpdate(this.orderDocId);
  //         browser.close();
  //         if (this.loading) {
  //           console.log('here 000 L')
  //           this.loading.dismiss()
  //         }
  //         this.orderService.presentFailureAlert();
  //         return;
  //       }
  //     }
  //   });
  // }


}
