import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Events } from '@ionic/angular';
import { first } from 'rxjs/operators';
import { LogglyLoggerService } from '../loggly-logger/loggly-logger.service';

@Injectable({
  providedIn: 'root'
})
export class MembershipService {
  membershipRef = this.angularFirestore.collection('features').doc('membership');
  constructor(private angularFirestore: AngularFirestore,
    private events: Events,
    private logglyService: LogglyLoggerService) { }

    initializeSubscriptions() {
      this.events.subscribe('membership:getMembershipSettings', () => {
          this.getMembershipSettings();
      });
  }

  async getMembershipSettings() {
    try {
        const settings = await this.membershipRef.valueChanges().pipe(first()).toPromise();
        this.events.publish('membership:membershipSettings', settings);
    } catch (error) {
        console.dir(error);
        error['location'] = 'membership-service:getMembershipSettings';
        this.logglyService.log(error);
    }
}
}
