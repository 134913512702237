import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OrderService } from 'src/app/services/order/order.service';
import { get } from 'scriptjs';
import { ConfigService } from 'src/app/services/config/config.service';
var PaypalPage = /** @class */ (function () {
    function PaypalPage(modalController, orderService, configService) {
        var _this_1 = this;
        this.modalController = modalController;
        this.orderService = orderService;
        this.configService = configService;
        var _this = this;
        setTimeout(function () {
            // dynamic load paypal script js
            get("https://www.paypal.com/sdk/js?client-id=" + _this_1.paypalObj.client_id + "&currency=" + _this_1.configService.environment.currencyCode, function () {
                // Render the PayPal button into #paypal-button-container
                window['paypal'].Buttons({
                    // Set up the transaction
                    createOrder: function (data, actions) {
                        return tslib_1.__awaiter(this, void 0, void 0, function () {
                            var _a, payableAmnt, order, payableAmnt;
                            return tslib_1.__generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        if (!_this.autoConfirm) return [3 /*break*/, 2];
                                        return [4 /*yield*/, _this.orderService.ac_prepareOrderForPayment(_this.orderData, 'paypal')];
                                    case 1:
                                        _a = _b.sent(), payableAmnt = _a.payableAmnt, order = _a.order;
                                        _this.order = order;
                                        _this.payableAmnt = payableAmnt;
                                        return [3 /*break*/, 3];
                                    case 2:
                                        payableAmnt = _this.orderData.partialPayment.status ? _this.orderData.partialPayment.online.amount : (_this.orderData.totalAmountToPaid - (_this.orderData.walletAmount + _this.orderData.cashbackAmount));
                                        _this.payableAmnt = payableAmnt;
                                        _this.order = _this.orderData;
                                        _b.label = 3;
                                    case 3: return [2 /*return*/, actions.order.create({
                                            purchase_units: [{
                                                    amount: {
                                                        value: _this.payableAmnt.toString()
                                                    }
                                                }]
                                        })];
                                }
                            });
                        });
                    },
                    // Finalize the transaction
                    onApprove: function (data, actions) {
                        return actions.order.capture()
                            .then(function (details) {
                            // Show a success message to the buyer
                            // console.log('details', details.id);
                            if (_this.autoConfirm) {
                                _this.orderService.ac_payWithPaypal(_this.order, { id: details.id }, _this.payableAmnt);
                            }
                            else {
                                _this.orderService.payWithPaypal(_this.order, { id: details.id }, _this.payableAmnt);
                            }
                        })
                            .catch(function (err) {
                            // console.log(err);
                        });
                    }
                }).render('#paypal-button-container');
            });
        }, 500);
    }
    PaypalPage.prototype.ngOnInit = function () {
    };
    PaypalPage.prototype.closeModal = function () {
        this.modalController.dismiss();
    };
    return PaypalPage;
}());
export { PaypalPage };
