import * as tslib_1 from "tslib";
import { first, map } from 'rxjs/operators';
import { Events } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Storage } from '@ionic/storage';
import { SharedService } from '../shared/shared.service';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "@angular/fire/storage";
import * as i4 from "@ionic/storage";
import * as i5 from "../shared/shared.service";
var BrandsService = /** @class */ (function () {
    function BrandsService(events, afs, fbStorage, storage, sharedService) {
        this.events = events;
        this.afs = afs;
        this.fbStorage = fbStorage;
        this.storage = storage;
        this.sharedService = sharedService;
    }
    BrandsService.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('brands:getAllBrandsForAdmin', function () {
            _this.getAllBrandsForAdmin();
        });
        this.events.subscribe('brands:getAllBrandsForUser', function () {
            _this.getAllBrandsForUser();
        });
        this.events.subscribe('brands:saveBrand', function (data, img, banner, bid) {
            _this.saveBrand(data, img, banner, bid);
        });
        this.events.subscribe('brands:deleteBrand', function (bid) {
            _this.deleteBrand(bid);
        });
        this.events.subscribe('brands:getBrandsData', function (bid) {
            _this.getBrandsData(bid);
        });
        this.setBrandsInLocalStorage();
        this.events.subscribe('brands:removeBrandsSubs', function () {
            if (_this.brandsSubs) {
                _this.brandsSubs.unsubscribe();
            }
        });
    };
    BrandsService.prototype.getAllBrandsForAdmin = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var brandsData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getAllBrands()];
                    case 1:
                        brandsData = _a.sent();
                        if (!brandsData.length) {
                            this.events.publish('brands:noBrandAvailableForAdmin');
                        }
                        else {
                            this.events.publish('brands:publishAllBrandsForAdmin', brandsData);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    BrandsService.prototype.getAllBrandsForUser = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var region, regionId_1, brandsRef, brandsSnap, brandsSub_1, error_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.sharedService.checkRegionIdForApi()];
                    case 1:
                        region = _a.sent();
                        regionId_1 = region.regionId;
                        brandsRef = void 0;
                        if (regionId_1) {
                            brandsRef = this.afs.collection('brands', function (ref) { return ref
                                .orderBy('sortedAt', 'desc')
                                .where('status', '==', true)
                                .where('regionId', 'array-contains', regionId_1); });
                        }
                        else {
                            brandsRef = this.afs.collection('brands', function (ref) { return ref
                                .where('status', '==', true)
                                .orderBy('sortedAt', 'desc'); });
                        }
                        brandsSnap = brandsRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                            var data = a.payload.doc.data();
                            var id = a.payload.doc.id;
                            return tslib_1.__assign({ id: id }, data);
                        }); }));
                        brandsSub_1 = brandsSnap.subscribe(function (brandsData) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                if (!brandsData.length) {
                                    this.events.publish('brands:noBrandAvailableForUser');
                                    brandsSub_1.unsubscribe();
                                }
                                else {
                                    this.events.publish('brands:publishAllBrandsForUser', brandsData);
                                    brandsSub_1.unsubscribe();
                                }
                                return [2 /*return*/];
                            });
                        }); });
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.dir(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    BrandsService.prototype.getAllBrands = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var _this = this;
                        return tslib_1.__generator(this, function (_a) {
                            this.storage.get('userRole').then(function (role) {
                                var brandsRef;
                                if (role === 'admin') {
                                    brandsRef = _this.afs.collection('brands', function (ref) { return ref.orderBy('sortedAt', 'desc'); });
                                }
                                else {
                                    brandsRef = _this.afs.collection('brands', function (ref) { return ref
                                        .where('status', '==', true)
                                        .orderBy('sortedAt', 'desc'); });
                                }
                                var brandsSnap = brandsRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                    var data = a.payload.doc.data();
                                    var id = a.payload.doc.id;
                                    return tslib_1.__assign({ id: id }, data);
                                }); }));
                                _this.brandsSubs = brandsSnap.subscribe(function (brandsData) {
                                    resolve(brandsData);
                                });
                            });
                            return [2 /*return*/];
                        });
                    }); })];
            });
        });
    };
    BrandsService.prototype.saveBrand = function (data, img, banner, bid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var brandid, baseImg_1, storageImg_1, bannerBaseImg_1, bannerStorageImg_1, imgRef, imgRef, error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        brandid = bid;
                        baseImg_1 = {};
                        storageImg_1 = {};
                        bannerBaseImg_1 = {};
                        bannerStorageImg_1 = {};
                        if (!brandid) {
                            brandid = this.afs.collection('brands').ref.doc().id;
                        }
                        if (img.length) {
                            img.forEach(function (i) {
                                if (i.url.includes('data:image/jpeg;base64,')) {
                                    baseImg_1 = i;
                                }
                                else {
                                    storageImg_1 = i;
                                }
                            });
                        }
                        if (banner.length) {
                            banner.forEach(function (i) {
                                if (i.url.includes('data:image/jpeg;base64,')) {
                                    bannerBaseImg_1 = i;
                                }
                                else {
                                    bannerStorageImg_1 = i;
                                }
                            });
                        }
                        return [4 /*yield*/, this.afs.collection('brands').doc(brandid).set({
                                status: data.status,
                                name: data.name,
                                image: storageImg_1,
                                banner: bannerStorageImg_1,
                                sortedAt: data.sortedAt
                            })];
                    case 1:
                        _a.sent();
                        if (!baseImg_1.hasOwnProperty('url')) return [3 /*break*/, 3];
                        imgRef = this.fbStorage.ref("brands/" + brandid + "/image/" + new Date().getTime().toString() + '.png');
                        return [4 /*yield*/, imgRef.putString(baseImg_1.url, 'data_url')];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        if (!bannerBaseImg_1.hasOwnProperty('url')) return [3 /*break*/, 5];
                        imgRef = this.fbStorage.ref("brandsBanner/" + brandid + "/image/" + new Date().getTime().toString() + '.png');
                        return [4 /*yield*/, imgRef.putString(bannerBaseImg_1.url, 'data_url')];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        this.events.publish('brands:saveBrandSuccess');
                        return [3 /*break*/, 7];
                    case 6:
                        error_2 = _a.sent();
                        console.dir(error_2);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    BrandsService.prototype.deleteBrand = function (bid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('brands').doc(bid).delete()];
                    case 1:
                        _a.sent();
                        this.events.publish('brands:deleteBrandSuccess');
                        return [3 /*break*/, 3];
                    case 2:
                        error_3 = _a.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    BrandsService.prototype.updateBrandsPosition = function (id, changedDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.afs.doc("brands/" + id).update({ sortedAt: changedDate })];
                    case 1:
                        _a.sent();
                        this.events.publish('brands:updateBrandsPostionSucess');
                        return [2 /*return*/];
                }
            });
        });
    };
    BrandsService.prototype.getBrandsData = function (bid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var brandData, error_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('brands').doc(bid).valueChanges().pipe(first()).toPromise()];
                    case 1:
                        brandData = _a.sent();
                        this.events.publish('brands:publishBrandsData', brandData);
                        return [3 /*break*/, 3];
                    case 2:
                        error_4 = _a.sent();
                        console.dir(error_4);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    BrandsService.prototype.getAllBrandsForSideMenu = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var storageBrands, brandsData, error_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        storageBrands = JSON.parse(localStorage.getItem('brands')) || [];
                        if (!!storageBrands.length) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.setBrandsInLocalStorage()];
                    case 1:
                        brandsData = _a.sent();
                        return [2 /*return*/, brandsData];
                    case 2: return [2 /*return*/, storageBrands];
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        error_5 = _a.sent();
                        console.dir(error_5);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    BrandsService.prototype.setBrandsInLocalStorage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var region, regionId, brandsRef, brandsSnap, brandsData;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this.sharedService.checkRegionIdForApi()];
                                case 1:
                                    region = _a.sent();
                                    regionId = region.regionId;
                                    if (regionId) {
                                        brandsRef = this.afs.collection('brands', function (ref) { return ref
                                            .orderBy('sortedAt', 'desc')
                                            .where('status', '==', true)
                                            .where('regionId', 'array-contains', regionId); });
                                    }
                                    else {
                                        brandsRef = this.afs.collection('brands', function (ref) { return ref
                                            .orderBy('sortedAt', 'desc')
                                            .where('status', '==', true); });
                                    }
                                    brandsSnap = brandsRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                        var data = a.payload.doc.data();
                                        var id = a.payload.doc.id;
                                        return tslib_1.__assign({ id: id }, data);
                                    }); }));
                                    return [4 /*yield*/, brandsSnap.pipe(first()).toPromise()];
                                case 2:
                                    brandsData = _a.sent();
                                    localStorage.setItem('brands', JSON.stringify(brandsData));
                                    resolve(brandsData);
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    BrandsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BrandsService_Factory() { return new BrandsService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.AngularFireStorage), i0.ɵɵinject(i4.Storage), i0.ɵɵinject(i5.SharedService)); }, token: BrandsService, providedIn: "root" });
    return BrandsService;
}());
export { BrandsService };
