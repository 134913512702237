import * as tslib_1 from "tslib";
import { first } from 'rxjs/operators';
import { UserService } from './../user/user.service';
import { Storage } from '@ionic/storage';
import { Events } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "@angular/fire/storage";
import * as i4 from "@ionic/storage";
import * as i5 from "../user/user.service";
var FeedbackService = /** @class */ (function () {
    function FeedbackService(events, afs, fbStorage, storage, userService) {
        this.events = events;
        this.afs = afs;
        this.fbStorage = fbStorage;
        this.storage = storage;
        this.userService = userService;
    }
    FeedbackService.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('feedback:saveFeedback', function (desc, images) {
            _this.saveFeedback(desc, images);
        });
        this.events.subscribe('feedback:getFeedbacks', function () {
            _this.getFeedbacks();
        });
    };
    FeedbackService.prototype.getFeedbacks = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var feedbacks, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('feedbacks', function (ref) { return ref
                                .orderBy('createdAt', 'desc'); }).valueChanges().pipe(first()).toPromise()];
                    case 1:
                        feedbacks = _a.sent();
                        this.events.publish('feedback:publishFeedbacks', feedbacks);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.dir(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    FeedbackService.prototype.saveFeedback = function (desc, images) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var name_1, uid, fid_1, error_2;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.nameInStorage()];
                    case 1:
                        name_1 = _a.sent();
                        return [4 /*yield*/, this.uidInStorage()];
                    case 2:
                        uid = _a.sent();
                        fid_1 = this.afs.collection('feedbacks').ref.doc().id;
                        return [4 /*yield*/, this.afs.collection('feedbacks').doc(fid_1).set({
                                userName: name_1,
                                userPhone: this.userService.getPhoneNo(),
                                userId: uid,
                                description: desc,
                                images: [],
                                createdAt: new Date()
                            })];
                    case 3:
                        _a.sent();
                        if (images.length) {
                            images.forEach(function (img) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var imgRef;
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            imgRef = this.fbStorage.ref("feedbacks/" + fid_1 + "/images/" + new Date().getTime().toString() + '.png');
                                            return [4 /*yield*/, imgRef.putString(img.url, 'data_url')];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); });
                        }
                        this.events.publish('feedback:saveFeedbackSuccess');
                        return [3 /*break*/, 5];
                    case 4:
                        error_2 = _a.sent();
                        console.dir(error_2);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    FeedbackService.prototype.nameInStorage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.storage.get('userName').then(function (name) {
                            resolve(name);
                        });
                    })];
            });
        });
    };
    FeedbackService.prototype.uidInStorage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.storage.get('uid').then(function (uid) {
                            resolve(uid);
                        });
                    })];
            });
        });
    };
    FeedbackService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FeedbackService_Factory() { return new FeedbackService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.AngularFireStorage), i0.ɵɵinject(i4.Storage), i0.ɵɵinject(i5.UserService)); }, token: FeedbackService, providedIn: "root" });
    return FeedbackService;
}());
export { FeedbackService };
