import * as tslib_1 from "tslib";
import { Storage } from '@ionic/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { Events } from '@ionic/angular';
import { first, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "@ionic/storage";
var WishlistService = /** @class */ (function () {
    function WishlistService(events, afs, storage) {
        this.events = events;
        this.afs = afs;
        this.storage = storage;
        this.userRef = this.afs.collection('users');
    }
    WishlistService.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('wishlist:heartClicked', function (pid, status, pindex) {
            _this.heartClicked(pid, status, pindex);
        });
        this.events.subscribe('wishlist:getWishlist', function () {
            _this.getWishlist();
        });
        this.events.subscribe('wishlist:getAllWishlistProducts', function () {
            _this.getAllWishlistProducts();
        });
        this.events.subscribe('wishlist:deleteProduct', function (pid, i) {
            _this.deleteProduct(pid, i);
        });
    };
    WishlistService.prototype.heartClicked = function (pid, status, pindex) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var uid_1, wlist, wishlistRef, wlist, index, error_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 8, , 9]);
                        return [4 /*yield*/, this.storage.get('uid')];
                    case 1:
                        uid_1 = _a.sent();
                        if (!(status === 'not_in_list')) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.userRef.doc(uid_1).collection('wishlist').add({ productId: pid })];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.getStorageWishlist()];
                    case 3:
                        wlist = _a.sent();
                        wlist.push(pid);
                        this.storage.set('wishlistProducts', wlist);
                        this.events.publish('wishlist:getAllWishlistProducts');
                        return [3 /*break*/, 7];
                    case 4: return [4 /*yield*/, this.userRef.doc(uid_1).collection('wishlist', function (ref) {
                            return ref.where('productId', '==', pid);
                        }).snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                            var data = a.payload.doc.data();
                            var id = a.payload.doc.id;
                            return tslib_1.__assign({ id: id }, data);
                        }); })).pipe(first()).toPromise()];
                    case 5:
                        wishlistRef = _a.sent();
                        wishlistRef.forEach(function (w) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.userRef.doc(uid_1).collection('wishlist').doc(w.id).delete()];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                        return [4 /*yield*/, this.getStorageWishlist()];
                    case 6:
                        wlist = _a.sent();
                        index = wlist.indexOf(pid);
                        wlist.splice(index, 1);
                        this.storage.set('wishlistProducts', wlist);
                        this.events.publish('wishlist:getAllWishlistProducts');
                        _a.label = 7;
                    case 7: return [3 /*break*/, 9];
                    case 8:
                        error_1 = _a.sent();
                        console.dir(error_1);
                        return [3 /*break*/, 9];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    WishlistService.prototype.getWishlist = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var wishlist, uid, wishlistData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        wishlist = [];
                        return [4 /*yield*/, this.getStorageUid()];
                    case 1:
                        uid = _a.sent();
                        return [4 /*yield*/, this.userRef.doc(uid).collection('wishlist').valueChanges().pipe(first()).toPromise()];
                    case 2:
                        wishlistData = _a.sent();
                        wishlistData.forEach(function (w) {
                            wishlist.push(w.productId);
                        });
                        //// // console.log('wishlist', wishlist);
                        this.storage.set('wishlistProducts', wishlist);
                        return [2 /*return*/];
                }
            });
        });
    };
    WishlistService.prototype.getAllWishlistProducts = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var wishlistPids, products, uid, wishlistData, _i, wishlistPids_1, pid, product;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        wishlistPids = [];
                        products = [];
                        return [4 /*yield*/, this.getStorageUid()];
                    case 1:
                        uid = _a.sent();
                        return [4 /*yield*/, this.userRef.doc(uid).collection('wishlist').valueChanges().pipe(first()).toPromise()];
                    case 2:
                        wishlistData = _a.sent();
                        wishlistData.forEach(function (w) {
                            wishlistPids.push(w.productId);
                        });
                        _i = 0, wishlistPids_1 = wishlistPids;
                        _a.label = 3;
                    case 3:
                        if (!(_i < wishlistPids_1.length)) return [3 /*break*/, 6];
                        pid = wishlistPids_1[_i];
                        return [4 /*yield*/, this.afs.collection('products').doc(pid).valueChanges().pipe(first()).toPromise()];
                    case 4:
                        product = _a.sent();
                        if (product) {
                            products.push({ id: pid, data: product });
                        }
                        _a.label = 5;
                    case 5:
                        _i++;
                        return [3 /*break*/, 3];
                    case 6:
                        this.events.publish('wishlist:allWishlistProducts', products);
                        return [2 /*return*/];
                }
            });
        });
    };
    WishlistService.prototype.deleteProduct = function (pid, i) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var uid_2, wishlistRef, error_2;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.getStorageUid()];
                    case 1:
                        uid_2 = _a.sent();
                        return [4 /*yield*/, this.userRef.doc(uid_2).collection('wishlist', function (ref) {
                                return ref.where('productId', '==', pid);
                            }).snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 2:
                        wishlistRef = _a.sent();
                        wishlistRef.forEach(function (w) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.userRef.doc(uid_2).collection('wishlist').doc(w.id).delete()];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                        return [4 /*yield*/, this.getWishlist()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        error_2 = _a.sent();
                        console.dir(error_2);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    WishlistService.prototype.getStorageUid = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            this.storage.get('uid').then(function (val) {
                                resolve(val);
                            });
                            return [2 /*return*/];
                        });
                    }); })];
            });
        });
    };
    WishlistService.prototype.getStorageWishlist = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            this.storage.get('wishlistProducts').then(function (val) {
                                resolve(val);
                            });
                            return [2 /*return*/];
                        });
                    }); })];
            });
        });
    };
    WishlistService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WishlistService_Factory() { return new WishlistService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.Storage)); }, token: WishlistService, providedIn: "root" });
    return WishlistService;
}());
export { WishlistService };
