import { Storage } from '@ionic/storage';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Events } from '@ionic/angular';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WishlistService {
  userRef = this.afs.collection('users');
  constructor(private events: Events,
              private afs: AngularFirestore,
              private storage: Storage) { }

  initializeSubscriptions() {
      this.events.subscribe('wishlist:heartClicked', (pid, status, pindex) => {
        this.heartClicked(pid, status, pindex);
      });
      this.events.subscribe('wishlist:getWishlist', () => {
        this.getWishlist();
      });
      this.events.subscribe('wishlist:getAllWishlistProducts', () => {
        this.getAllWishlistProducts();
      });
      this.events.subscribe('wishlist:deleteProduct', (pid, i) => {
        this.deleteProduct(pid, i);
      });
    }

  async heartClicked(pid: string, status: string, pindex: number) {
    try {
      const uid = await this.storage.get('uid');
      if (status === 'not_in_list') {
        await this.userRef.doc(uid).collection('wishlist').add({productId: pid});
        let wlist: any = await this.getStorageWishlist();
        wlist.push(pid);
        this.storage.set('wishlistProducts', wlist);
        this.events.publish('wishlist:getAllWishlistProducts');
      } else {
        const wishlistRef = await this.userRef.doc(uid).collection('wishlist', ref =>
        ref.where('productId', '==', pid)).snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          }))
        ).pipe(first()).toPromise();
        wishlistRef.forEach(async w => {
          await this.userRef.doc(uid).collection('wishlist').doc(w.id).delete();
        });
        let wlist: any = await this.getStorageWishlist();
        const index = wlist.indexOf(pid);
        wlist.splice(index, 1);
        this.storage.set('wishlistProducts', wlist);
        this.events.publish('wishlist:getAllWishlistProducts');
      }
    } catch (error) {
      console.dir(error);
    }
  }

  async getWishlist() {
    const wishlist = [];
    const uid = await this.getStorageUid();
    const wishlistData: any = await this.userRef.doc(uid).collection('wishlist').valueChanges().pipe(first()).toPromise();
    wishlistData.forEach(w => {
      wishlist.push(w.productId);
    });
    //// // console.log('wishlist', wishlist);
    this.storage.set('wishlistProducts', wishlist);
  }

  async getAllWishlistProducts() {
    const wishlistPids = [];
    const products = [];
    const uid = await this.getStorageUid();
    const wishlistData: any = await this.userRef.doc(uid).collection('wishlist').valueChanges().pipe(first()).toPromise();
    wishlistData.forEach(w => {
      wishlistPids.push(w.productId);
    });
    for (const pid of wishlistPids) {
      const product: any = await this.afs.collection('products').doc(pid).valueChanges().pipe(first()).toPromise();
      if (product) {
        products.push({id: pid, data: product});
      }
    }
    this.events.publish('wishlist:allWishlistProducts', products);
  }

  async deleteProduct(pid: string, i: number) {
    try {
      const uid = await this.getStorageUid();
      const wishlistRef = await this.userRef.doc(uid).collection('wishlist', ref =>
        ref.where('productId', '==', pid)).snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          }))
        ).pipe(first()).toPromise();
      wishlistRef.forEach(async w => {
          await this.userRef.doc(uid).collection('wishlist').doc(w.id).delete();
        });
      await this.getWishlist();
    } catch (error) {
      console.dir(error);
    }
  }


  async getStorageUid(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      this.storage.get('uid').then((val: string) => {
        resolve(val);
      });
    });
  }

  async getStorageWishlist(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      this.storage.get('wishlistProducts').then((val: string) => {
        resolve(val);
      });
    });
  }
}
