<ion-header class="modal-header">
  <ion-toolbar>
    <ion-title class="modal-header-title">{{'PAYMENT_MODULE.header_text' | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <div class="total-amount">
    <span>{{'PAYMENT_MODULE.total_amount' | translate}}</span>
    <span class="f-w-b">{{paymentData.order.amount | currency: currencyCode:true:'.2-2'}}</span>
  </div>
</ion-header>

<ion-content>
  <br>
  <div class="price-card" *ngIf="walletActive && (walletBalance > 0 || cashbackBalance > 0)">
    <ion-grid class="ion-no-padding">

      <ion-row class="ion-align-items-center">
        <ion-col size="8" class="ion-no-padding use-balance-wrapper">
          <div class="use-balance-txt">
            <ion-checkbox color="primary" (click)="onClickUseWallet()"
              [disabled]="(walletBalance === 0) && (cashbackBalance > 0) && (paymentData.order.amount < minOrderAmntToUseWallet)">
            </ion-checkbox>
            <span>{{'AUTO_CONFIRM_PAYMENT.use_wallet_balance' | translate}}</span>
          </div>
          <div class="current-balance-txt">
            {{'AUTO_CONFIRM_PAYMENT.current_balance' | translate}}:
            {{walletBalance - walletAmount | currency: currencyCode:true:'.2-2'}}
          </div>
          <div class="current-balance-txt" *ngIf="cashbackBalance > 0">
            {{'AUTO_CONFIRM_PAYMENT.cashback' | translate}}:
            {{cashbackBalance - cashbackAmount | currency: currencyCode:true:'.2-2'}}
          </div>
        </ion-col>
        <ion-col size="4" *ngIf="walletUsed" class="ion-no-padding wallet-amount">
          <ion-text color="success">-{{walletAmount + cashbackAmount | currency: currencyCode:true:'.2-2'}}</ion-text>
        </ion-col>
        <div *ngIf="paymentData.order.amount < minOrderAmntToUseWallet">
          <ion-text color="danger">
            {{'AUTO_CONFIRM_PAYMENT.min_amount_to_use_wallet_msg' | translate:{'amount': minOrderAmntToUseWallet  | currency: currencyCode:true:'.2-2'} }}
          </ion-text>
        </div>
      </ion-row>

      <ion-row class="ion-no-padding" class="ion-align-items-center">
        <hr class="line">
        <ion-col class="ion-no-padding">
          <p class="total-estimated">{{'AUTO_CONFIRM_PAYMENT.you_pay' | translate}}</p>
        </ion-col>
        <ion-col class="ion-no-padding ion-text-right">
          <ion-text color="primary">{{getFinalAmount() | currency: currencyCode:true:'.2-2'}}
          </ion-text>
        </ion-col>
      </ion-row>

    </ion-grid>
  </div>

  <div class="partial-note" *ngIf="partialPayment.status && (getFinalAmount() > 0)">
    <p>
      <span class="info-icon">
        <ion-icon name="information-circle"></ion-icon>
      </span>
      <span class="f-w-b">{{'AUTO_CONFIRM_PAYMENT.note' | translate}}: </span>
      <span
        class="opacity-07 partial-note-txt">{{'AUTO_CONFIRM_PAYMENT.partial_note' | translate:{'onlinePercent': 100 - codPercent} }}</span>
    </p>
  </div>


  <div class="payment-methods">
    <p class="payment-modes">{{'SHARED.payment_modes' | translate}}</p>
    <hr class="line no-space-line">
    <div class="spinner" *ngIf="showLoader; else modesLoaded">
      <ion-spinner color="primary"></ion-spinner>
    </div>

    <ng-template #modesLoaded>
      <ng-container *ngIf="getFinalAmount() > 0; else wholePaymentWithWallet;">

        <div class="partial" *ngIf="partialPayment.status">
          <div class="partial-selection">
            <div>
              <ion-button fill="clear" expand="block" color="dark" (click)="setPaymentType('full')">
                <ion-checkbox [checked]="paymentType === 'full'" mode="ios" slot="start"></ion-checkbox>
                <span>{{'AUTO_CONFIRM_PAYMENT.pay_in_full' | translate}}</span>
              </ion-button>
            </div>

            <div>
              <ion-button fill="clear" expand="block" color="dark" (click)="setPaymentType('partial')">
                <ion-checkbox [checked]="paymentType === 'partial'" mode="ios" slot="start"></ion-checkbox>
                <span>{{'AUTO_CONFIRM_PAYMENT.pay_partially' | translate}}</span>
              </ion-button>
            </div>
          </div>

          <div class="partial-info" *ngIf="paymentType === 'partial'">
            <div>
              <p>{{'AUTO_CONFIRM_PAYMENT.pay_with_online' | translate}}</p>
              <p>{{partialPayment.online.amount | currency: currencyCode:true:'.2-2'}}</p>
            </div>
            <div>
              <i class="flaticon-null-28"></i>
            </div>
            <div>
              <p>{{'AUTO_CONFIRM_PAYMENT.pay_with_cod' | translate}}</p>
              <p>{{partialPayment.cod | currency: currencyCode:true:'.2-2'}}</p>
            </div>
          </div>
        </div>


        <ion-list class="payment-modes-wrapper" lines="none">
          <ion-item button (click)="onClickPaymentMode('cash')" detail class="ion-no-padding ion-item"
            *ngIf="isCod && !partialPayment.status">
            <ion-img slot="start" src="assets/img/cod.png"></ion-img>
            <ion-label>
              <h4>{{'SHARED.cash_on_delivery' | translate}}</h4>

              <ng-container *ngTemplateOutlet="extraChargeTemplate; context: extraCharge.cash"></ng-container>
            </ion-label>
          </ion-item>
          <ion-item button (click)="onClickPaymentMode('paytm')" detail *ngIf="paytmActive && envPaytmActive"
            class="ion-no-padding ion-item">
            <ion-img slot="start" src="assets/img/paytm.png"></ion-img>
            <ion-label>
              <h4>{{'SHARED.paytm' | translate}}</h4>
              <ng-container *ngTemplateOutlet="extraChargeTemplate; context: extraCharge.paytm"></ng-container>
            </ion-label>
          </ion-item>

          <ion-item button (click)="onClickPaymentMode('upiManual')" detail
            *ngIf="upiManual.active && (paymentType !== 'partial')" class="ion-no-padding ion-item">
            <ion-img slot="start" src="assets/img/upi.png"></ion-img>
            <ion-label>
              <h4>{{'SHARED.upi_manual' | translate}}</h4>
            </ion-label>
          </ion-item>

          <ion-item button (click)="onClickPaymentMode('custom')" detail
            *ngIf="customOption.active && (paymentType !== 'partial')" class="ion-no-padding ion-item">
            <ion-img slot="start" src="assets/img/cod.png"></ion-img>
            <ion-label>
              <h4>{{customOption.name}}</h4>
            </ion-label>
          </ion-item>

          <ng-container *ngIf="razorpayActive">
            <ion-item button (click)="onClickPaymentMode('razorpay', 'card')" detail class="ion-no-padding ion-item">
              <ion-img slot="start" src="assets/img/credit-card.png"></ion-img>
              <ion-label class="ion-text-wrap">
                <h4>{{'SHARED.credit_debit_cards' | translate}}</h4>
                <ng-container *ngTemplateOutlet="extraChargeTemplate; context: extraCharge.razorpay"></ng-container>
              </ion-label>
            </ion-item>

            <ion-item button (click)="onClickPaymentMode('razorpay', 'wallet')" detail class="ion-no-padding ion-item">
              <ion-img slot="start" src="assets/img/wallet.png"></ion-img>
              <ion-label class="ion-text-wrap">
                <h4>{{'SHARED.wallets' | translate}}</h4>
                <ng-container *ngTemplateOutlet="extraChargeTemplate; context: extraCharge.razorpay"></ng-container>
              </ion-label>
            </ion-item>

            <ion-item button (click)="onClickPaymentMode('razorpay', 'upi')" detail class="ion-no-padding ion-item">
              <ion-img slot="start" src="assets/img/upi.png"></ion-img>
              <ion-label class="ion-text-wrap">
                <h4>{{'SHARED.upi' | translate}}</h4>
                <ng-container *ngTemplateOutlet="extraChargeTemplate; context: extraCharge.razorpay"></ng-container>
              </ion-label>
            </ion-item>

            <ion-item button (click)="onClickPaymentMode('razorpay', 'netbanking')" detail class="ion-no-padding">
              <ion-img slot="start" src="assets/img/online-banking.png"></ion-img>
              <ion-label class="ion-text-wrap">
                <h4>{{'SHARED.netbanking' | translate}}</h4>
                <ng-container *ngTemplateOutlet="extraChargeTemplate; context: extraCharge.razorpay"></ng-container>
              </ion-label>
            </ion-item>
          </ng-container>

          <!-- stripe method -->
          <!-- <ion-item button (click)="onClickPaymentMode('stripe')" detail *ngIf="stripeData.active"
            class="ion-no-padding">
            <ion-img slot="start" src="assets/img/credit-card.png"></ion-img>
            <ion-label class="ion-text-wrap">
              <h4>{{'SHARED.credit_debit_cards' | translate}}</h4>
              <ng-container *ngTemplateOutlet="extraChargeTemplate; context: extraCharge.stripe"></ng-container>
            </ion-label>
          </ion-item> -->
          <!-- stripe method -->

          <!-- Paypal Method Start-->
          <!-- <ion-item button (click)="onClickPaymentMode('paypal')" detail *ngIf="paypalObj.active"
            class="ion-no-padding">
            <ion-img slot="start" src="assets/img/paypal.png"></ion-img>
            <ion-label class="ion-text-wrap">
              <h4>{{'SHARED.paypal' | translate}}</h4>
              <ng-container *ngTemplateOutlet="extraChargeTemplate; context: extraCharge.paypal"></ng-container>
            </ion-label>
          </ion-item> -->
          <!-- Paypal Method End-->

          <!-- Cashfree Method Start -->
          <!-- <ion-item button (click)="onClickPaymentMode('cashfree')" detail *ngIf="cashfreeObj.active"
            class="ion-no-padding">
            <ion-img slot="start" src="assets/img/cashfree.jpg"></ion-img>
            <ion-label class="ion-text-wrap">
              <h4>{{'SHARED.cashfree' | translate}}</h4>
              <ng-container *ngTemplateOutlet="extraChargeTemplate; context: extraCharge.cashfree"></ng-container>
            </ion-label>
          </ion-item> -->
          <!-- Cashfree Method End -->

        </ion-list>
      </ng-container>

      <ng-template #wholePaymentWithWallet>
        <div class="w-b-c-txt">
          {{'AUTO_CONFIRM_PAYMENT.wallet_covers_payment' | translate}}
        </div>
      </ng-template>

    </ng-template>
  </div>
</ion-content>

<ion-footer no-border *ngIf="getFinalAmount() === 0">
  <ion-button (click)="completePaymentWithWallet()" class="btn-2" color="success" expand="full">
    {{'AUTO_CONFIRM_PAYMENT.place_order' | translate}}
  </ion-button>
</ion-footer>

<!-- extra charge template -->
<ng-template #extraChargeTemplate let-charge="charge" let-type="type" let-maxCharge="maxCharge">
  <ng-container *ngIf="charge && paymentType !== 'partial'">
    <h5 class="ion-text-wrap extra-charge-txt" *ngIf="type === 'flat'; else percentCharge;">
      {{'SHARED.extra_charge_flat' | translate:{'amount': charge | currency: currencyCode:true:'.2-2'} }}</h5>
    <ng-template #percentCharge>
      <h5 class="ion-text-wrap extra-charge-txt" *ngIf="maxCharge">
        {{'SHARED.extra_charge_upto_percent' | translate:{'percent': charge, 'maxCharge': maxCharge | currency: currencyCode:true:'.2-2'} }}
      </h5>
      <h5 class="ion-text-wrap extra-charge-txt" *ngIf="!maxCharge">
        {{'SHARED.extra_charge_percent' | translate:{'percent': charge} }}</h5>
    </ng-template>
  </ng-container>
</ng-template>