import * as tslib_1 from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import { Events } from '@ionic/angular';
import * as firebase from 'firebase';
import { first, map } from 'rxjs/operators';
import { LogglyLoggerService } from '../loggly-logger/loggly-logger.service';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "../loggly-logger/loggly-logger.service";
var MultiRegionService = /** @class */ (function () {
    function MultiRegionService(events, afs, logglyService) {
        this.events = events;
        this.afs = afs;
        this.logglyService = logglyService;
        this.multiRegionRef = this.afs.collection('features').doc('multiRegion').collection('regions');
    }
    MultiRegionService.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('multi-region:saveRegion', function (regionData) {
            _this.saveRegion(regionData);
        });
        this.events.subscribe('multi-region:toggleMultiRegionActive', function (status) {
            _this.toggleMultiRegionActive(status);
        });
        this.events.subscribe('multi-region:getActiveStatus', function () {
            _this.getActiveStatus();
        });
        this.events.subscribe('multi-region:getAllRegions', function () {
            _this.getAllRegions();
        });
        this.events.subscribe('multi-region:toggleRegionActive', function (status, id) {
            _this.toggleRegionActive(status, id);
        });
        this.events.subscribe('multi-region:deleteRegion', function (id) {
            _this.deleteRegion(id);
        });
        this.events.subscribe('multi-region:getAllActiveRegions', function () {
            _this.getAllActiveRegions();
        });
    };
    MultiRegionService.prototype.saveRegion = function (regionData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var regionId, regionClone, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        regionId = '';
                        regionClone = JSON.parse(JSON.stringify(regionData));
                        if (regionClone.hasOwnProperty('id')) {
                            regionId = regionClone.id;
                            delete regionData.id;
                        }
                        else {
                            regionId = this.multiRegionRef.ref.doc().id;
                        }
                        regionData['createdAt'] = new Date();
                        return [4 /*yield*/, this.multiRegionRef.doc(regionId).set(regionData)];
                    case 1:
                        _a.sent();
                        this.events.publish('multi-region:regionSaved');
                        this.events.publish('multi-region:getAllRegions');
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.dir(error_1);
                        error_1['location'] = 'multi-region-service:saveRegion';
                        this.logglyService.log(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MultiRegionService.prototype.toggleMultiRegionActive = function (status) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('features').doc('multiRegion').set({ active: status })];
                    case 1:
                        _a.sent();
                        this.events.publish('multi-region:multiRegionActiveChanged');
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        console.dir(error_2);
                        error_2['location'] = 'multi-region-service:toogleMultiRegionActive';
                        this.logglyService.log(error_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MultiRegionService.prototype.getActiveStatus = function (route) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var multiRegionDoc, error_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('features').doc('multiRegion').valueChanges().pipe(first()).toPromise()];
                    case 1:
                        multiRegionDoc = _a.sent();
                        if (route === 'service') {
                            return [2 /*return*/, multiRegionDoc];
                        }
                        else {
                            this.events.publish('multi-region:publishActiveStatus', multiRegionDoc);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_3 = _a.sent();
                        console.dir(error_3);
                        error_3['location'] = 'multi-region-service:getActiveStatus';
                        this.logglyService.log(error_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MultiRegionService.prototype.getAllRegions = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var multiRegions, error_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('features').doc('multiRegion').collection('regions', function (ref) { return ref
                                .orderBy('createdAt', 'desc'); }).snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 1:
                        multiRegions = _a.sent();
                        // console.log('multiRegions', multiRegions);
                        this.events.publish('multi-region:publishAllRegions', multiRegions);
                        return [3 /*break*/, 3];
                    case 2:
                        error_4 = _a.sent();
                        console.dir(error_4);
                        error_4['location'] = 'multi-region-service:getAllRegions';
                        this.logglyService.log(error_4);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MultiRegionService.prototype.toggleRegionActive = function (status, id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.multiRegionRef.doc(id).update({ active: status })];
                    case 1:
                        _a.sent();
                        this.events.publish('multi-region:regionActiveChanged');
                        return [3 /*break*/, 3];
                    case 2:
                        error_5 = _a.sent();
                        console.dir(error_5);
                        error_5['location'] = 'multi-region-service:toggleRegionActive';
                        this.logglyService.log(error_5);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MultiRegionService.prototype.deleteRegion = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.multiRegionRef.doc(id).delete()];
                    case 1:
                        _a.sent();
                        this.events.publish('multi-region:regionDeleted');
                        this.events.publish('multi-region:getAllRegions');
                        return [3 /*break*/, 3];
                    case 2:
                        error_6 = _a.sent();
                        console.dir(error_6);
                        error_6['location'] = 'multi-region-service:deleteRegion';
                        this.logglyService.log(error_6);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MultiRegionService.prototype.getAllActiveRegions = function (callType) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var multiRegions, error_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('features').doc('multiRegion').collection('regions', function (ref) { return ref
                                .where('active', '==', true); }).snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 1:
                        multiRegions = _a.sent();
                        // console.log('multiRegions', multiRegions);
                        if (callType && callType === 'return') {
                            return [2 /*return*/, multiRegions];
                        }
                        else {
                            this.events.publish('multi-region:publishAllActiveRegions', multiRegions);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_7 = _a.sent();
                        console.dir(error_7);
                        error_7['location'] = 'multi-region-service:getAllRegions';
                        this.logglyService.log(error_7);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MultiRegionService.prototype.getNearestStoreRegion = function (address) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var regions, storeRegion, error_8;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, this.getAllActiveRegions('return')];
                                case 1:
                                    regions = _a.sent();
                                    storeRegion = firebase.functions().httpsCallable('orders-getNearestStoreRegion');
                                    storeRegion({ address: address, regions: regions }).then(function (res) {
                                        resolve(res.data);
                                    });
                                    return [3 /*break*/, 3];
                                case 2:
                                    error_8 = _a.sent();
                                    // console.log(error);
                                    error_8['location'] = 'multi-region-service:getNearestStoreRegion';
                                    this.logglyService.log(error_8);
                                    resolve({ status: 'failure' });
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    MultiRegionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MultiRegionService_Factory() { return new MultiRegionService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.LogglyLoggerService)); }, token: MultiRegionService, providedIn: "root" });
    return MultiRegionService;
}());
export { MultiRegionService };
