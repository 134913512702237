import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(private angularFirestore:AngularFirestore) { }
  async initializeThemeSettings(){
    let data:any = await this.angularFirestore.collection('settings').doc('theme').valueChanges().pipe(first()).toPromise();
    const css = `:root {--primary-color: ${data.colors.primary};}`;
  const head = document.getElementsByTagName('head')[0];
  const style = document.createElement('style');
  style.type = 'text/css';
  style.appendChild(document.createTextNode(css));
  head.appendChild(style);

  }
  async getThemeSttings() {
    return new Promise(async (resolve, reject) => {
        let data = JSON.parse(sessionStorage.getItem("themeSettingsData")) || {};
        if (!(Object.keys(data).length)) {
          data = await this.angularFirestore.collection('settings').doc('theme').valueChanges().pipe(first()).toPromise();
            sessionStorage.setItem("themeSettingsData", JSON.stringify(data));
        }
        resolve(data);
    });
}
}
