import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { first } from 'rxjs/operators';
import { ConfigService } from '../config/config.service';
import { LabelService } from '../label/label.service';
import { MultiCountriesService } from '../multi-countries/multi-countries.service';

@Injectable({
  providedIn: 'root'
})
export class SharedNewService {

  constructor(private configService: ConfigService,
    private multiCountriesService: MultiCountriesService,
    private afs: AngularFirestore,
    private alertController: AlertController,
    private labelService: LabelService,
	private modalController: ModalController,
    private navCtrl: NavController) { }

  showQtyLeftMsg(product: any, index = 0) {
    const qtyLeftMsgCount = this.configService.environment.qtyLeft || 5;
    let quantity = 0;
    quantity = product.isPriceList ? +product.priceList[index].totalQuantity || 0 : +product.productQty || 0;
    return quantity > 0 && quantity <= qtyLeftMsgCount ? quantity : 0;
  }

  showPriceListQtyLeftMsg(quantity: number) {
    const qtyLeftMsgCount = this.configService.environment.qtyLeft || 5;
    return quantity > 0 && quantity <= qtyLeftMsgCount ? quantity : 0;
  }

  getPriceBasedOnExhangeRate(price) {
    if(this.multiCountriesService.multiCountriesSettings.settings.autoExchange) {
      const toRate = this.multiCountriesService.rates[this.configService.environment.currencyCode];
      const fromRate = this.multiCountriesService.rates['INR'];
      return ((toRate / fromRate) * price);
    } else {
        return price;
    }
  }

  	async getTemplateData(templateId: string) {
        const templateData = await this.afs.collection('templates').doc(templateId).valueChanges().pipe(first()).toPromise();
        return templateData || null;
    }

	async presentAlert(alertData) {
	    const alert = await this.alertController.create({
	        message: alertData.msg,
			backdropDismiss: alertData.backdropDismiss,
	        buttons: [{
	            text: `${this.labelService.labels['SHARED']['ok']}`,
	            handler: () => {
                    if(alertData.page) {
                        this.navCtrl.navigateRoot([alertData.page]);
	                } else {
	                    return;
	                }
	            }
	        }]
	    });
	    await alert.present();
	}
}
