/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./repeat-addon-choice.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i4 from "@ionic/angular";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./repeat-addon-choice.page";
var styles_RepeatAddonChoicePage = [i0.styles];
var RenderType_RepeatAddonChoicePage = i1.ɵcrt({ encapsulation: 0, styles: styles_RepeatAddonChoicePage, data: {} });
export { RenderType_RepeatAddonChoicePage as RenderType_RepeatAddonChoicePage };
function View_RepeatAddonChoicePage_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["+"]))], null, null); }
function View_RepeatAddonChoicePage_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RepeatAddonChoicePage_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_v.parent.context.index !== (_co.lastAddon.length - 1)) || (_v.context.index !== (_v.parent.context.$implicit.options.length - 1))); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); }); }
function View_RepeatAddonChoicePage_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RepeatAddonChoicePage_3)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.options; _ck(_v, 2, 0, currVal_0); }, null); }
function View_RepeatAddonChoicePage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "ion-content", [["class", "page-content"]], null, null, null, i3.View_IonContent_0, i3.RenderType_IonContent)), i1.ɵdid(1, 49152, null, 0, i4.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 5, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h6", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 1, "ion-icon", [["name", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_IonIcon_0, i3.RenderType_IonIcon)), i1.ɵdid(7, 49152, null, 0, i4.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(8, 0, null, 0, 5, "div", [["class", "addons-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "product-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, [" ", " "])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "addons"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RepeatAddonChoicePage_2)), i1.ɵdid(13, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(14, 0, null, 0, 8, "div", [["class", "action-btns"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 3, "ion-button", [["class", "footer-button"], ["expand", "block"], ["fill", "outline"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveChoice("new") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_IonButton_0, i3.RenderType_IonButton)), i1.ɵdid(16, 49152, null, 0, i4.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { expand: [0, "expand"], fill: [1, "fill"] }, null), (_l()(), i1.ɵted(17, 0, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(19, 0, null, null, 3, "ion-button", [["class", "footer-button"], ["expand", "block"], ["fill", "outline"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveChoice("repeat") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_IonButton_0, i3.RenderType_IonButton)), i1.ɵdid(20, 49152, null, 0, i4.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { expand: [0, "expand"], fill: [1, "fill"] }, null), (_l()(), i1.ɵted(21, 0, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = "close"; _ck(_v, 7, 0, currVal_1); var currVal_3 = _co.lastAddon; _ck(_v, 13, 0, currVal_3); var currVal_4 = "block"; var currVal_5 = "outline"; _ck(_v, 16, 0, currVal_4, currVal_5); var currVal_7 = "block"; var currVal_8 = "outline"; _ck(_v, 20, 0, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("REPEAT_ADDON_CHOICE.header_text")); _ck(_v, 4, 0, currVal_0); var currVal_2 = _co.name; _ck(_v, 10, 0, currVal_2); var currVal_6 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform("REPEAT_ADDON_CHOICE.add_new")); _ck(_v, 17, 0, currVal_6); var currVal_9 = i1.ɵunv(_v, 21, 0, i1.ɵnov(_v, 22).transform("REPEAT_ADDON_CHOICE.repeat_last")); _ck(_v, 21, 0, currVal_9); }); }
export function View_RepeatAddonChoicePage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_RepeatAddonChoicePage_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.name; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_RepeatAddonChoicePage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-repeat-addon-choice", [], null, null, null, View_RepeatAddonChoicePage_0, RenderType_RepeatAddonChoicePage)), i1.ɵdid(1, 114688, null, 0, i6.RepeatAddonChoicePage, [i4.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RepeatAddonChoicePageNgFactory = i1.ɵccf("app-repeat-addon-choice", i6.RepeatAddonChoicePage, View_RepeatAddonChoicePage_Host_0, {}, {}, []);
export { RepeatAddonChoicePageNgFactory as RepeatAddonChoicePageNgFactory };
