<ion-header *ngIf="pageType === 'page'">
  <ion-toolbar class="toolbar">
    <ion-title text-center>{{'SELECT_ADDRESS.header_text' | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button *ngIf="showAddAddressBtn" (click)="addNewAddress()">
        <ion-icon slot="start" name="add"></ion-icon>
        {{'SELECT_ADDRESS.add_new_address' | translate}}
      </ion-button>
      <ion-button (click)="modalDismiss()">
        <ion-icon slot="icon-only" name="close-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-header class="modal-header" *ngIf="pageType === 'modal'">
  <ion-toolbar>
    <ion-title class="modal-header-title">{{'SELECT_ADDRESS.header_text' | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button *ngIf="!externalPage" (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
	<div class="add-address-toolbar" (click)="goToPage('new-address')" *ngIf="showAddAddressBtn">
    	<p>
    	  + {{'SELECT_ADDRESS.add_new_address' | translate}}
    	</p>
  	</div>
  <div class="spinner" *ngIf="showLoader">
    <ion-spinner color="primary"></ion-spinner>
  </div>
  <div class="address-container" *ngIf="!showLoader && addressesData">
    <div class="address-wrapper" *ngFor="let address of addressesData; let i=index"  [class.active]="selectedAddress == address" (click)="selectAddress(address)">

      <div class="address-wrap">
        <span class="selected"><ion-icon name="checkmark-circle"></ion-icon></span>

        <p class="s-user-name ion-text-wrap">{{address.name}}</p>
        <p class="s-address ion-text-wrap">{{address.address}}</p>
        <p class="s-phone-no">{{address.phoneNo}}</p>
      </div>
      <div class="btn-wrap"*ngIf="!externalPage" (click)="editAddress(address)">
        <ion-button class="btn-2" fill="outline" shape="round"> {{'SELECT_ADDRESS.edit' | translate}}</ion-button>
      </div>
    </div>
  </div>

</ion-content>
<ion-footer >
  <ion-button class="btn-1 i-start" expand="full" (click)="onClickDeliverHere()" *ngIf="addressType === 'shipping'">
    <i class="flaticon-null-20 app-footer-icon"></i>
    {{'SELECT_ADDRESS.deliver_here' | translate}}
  </ion-button>

  <ion-button class="btn-1 i-start" expand="full" (click)="onClickDeliverHere()" *ngIf="addressType === 'billing'">
    <i class="flaticon-null-20 app-footer-icon"></i>
    {{'SELECT_ADDRESS.use_as_billing_address' | translate}}
  </ion-button>
</ion-footer>
