/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./promo-modal.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "./promo-modal.page";
import * as i6 from "@angular/router";
import * as i7 from "../services/utils/utils.service";
import * as i8 from "../services/label/label.service";
import * as i9 from "../services/shared/shared.service";
import * as i10 from "../services/widgets/widgets.service";
var styles_PromoModalPage = [i0.styles];
var RenderType_PromoModalPage = i1.ɵcrt({ encapsulation: 0, styles: styles_PromoModalPage, data: {} });
export { RenderType_PromoModalPage as RenderType_PromoModalPage };
function View_PromoModalPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ion-img", [["useImg", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickPopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonImg_0, i2.RenderType_IonImg)), i1.ɵdid(1, 49152, [[1, 4], ["imageBox", 4]], 0, i3.IonImg, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { src: [0, "src"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.popup.banner.mob; _ck(_v, 1, 0, currVal_0); }, null); }
function View_PromoModalPage_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ion-img", [["useImg", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickPopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonImg_0, i2.RenderType_IonImg)), i1.ɵdid(1, 49152, [[1, 4], ["imageBox", 4]], 0, i3.IonImg, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { src: [0, "src"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.popup.banner.url; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PromoModalPage_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { imageBox: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 9, "ion-content", [["class", "ion-no-padding"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(2, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 7, "div", [["class", "promo-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "popup-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "flaticon-null-19"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "popup-inner-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PromoModalPage_1)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PromoModalPage_2)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.popup.banner.mob; _ck(_v, 8, 0, currVal_0); var currVal_1 = (!_co.popup.banner.mob && _co.popup.banner.url); _ck(_v, 10, 0, currVal_1); }, null); }
export function View_PromoModalPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-promo-modal", [], null, null, null, View_PromoModalPage_0, RenderType_PromoModalPage)), i1.ɵdid(1, 114688, null, 0, i5.PromoModalPage, [i6.Router, i7.UtilsService, i3.ModalController, i8.LabelService, i1.NgZone, i9.SharedService, i10.WidgetsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PromoModalPageNgFactory = i1.ɵccf("app-promo-modal", i5.PromoModalPage, View_PromoModalPage_Host_0, {}, {}, []);
export { PromoModalPageNgFactory as PromoModalPageNgFactory };
