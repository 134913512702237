/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./select-language.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "./select-language.page";
import * as i7 from "@angular/router";
import * as i8 from "../../services/label/label.service";
import * as i9 from "@ionic/storage";
import * as i10 from "../../services/config/config.service";
import * as i11 from "@ionic-native/splash-screen/ngx/index";
var styles_SelectLanguagePage = [i0.styles];
var RenderType_SelectLanguagePage = i1.ɵcrt({ encapsulation: 0, styles: styles_SelectLanguagePage, data: {} });
export { RenderType_SelectLanguagePage as RenderType_SelectLanguagePage };
function View_SelectLanguagePage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ion-spinner", [["color", "primary"]], null, null, null, i2.View_IonSpinner_0, i2.RenderType_IonSpinner)), i1.ɵdid(2, 49152, null, 0, i3.IonSpinner, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null)], function (_ck, _v) { var currVal_0 = "primary"; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SelectLanguagePage_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "no-langs"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("SELECT_LANGUAGE.no_languages_added")); _ck(_v, 1, 0, currVal_0); }); }
function View_SelectLanguagePage_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "ion-col", [["class", "lang-wrapper"], ["size", "6"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectLang(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonCol_0, i2.RenderType_IonCol)), i1.ɵdid(1, 49152, null, 0, i3.IonCol, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { size: [0, "size"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 7, "div", [["class", "lang-names"]], null, null, null, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { "active-lang": 0, "inactive-lang": 1 }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "lang-names-actual"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "lang-names-eng"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = "6"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "lang-names"; var currVal_2 = _ck(_v, 5, 0, _v.context.$implicit.isDefault, !_v.context.$implicit.isDefault); _ck(_v, 4, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_3 = _v.context.$implicit.langName; _ck(_v, 7, 0, currVal_3); var currVal_4 = _v.context.$implicit.englishName; _ck(_v, 9, 0, currVal_4); }); }
function View_SelectLanguagePage_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ion-grid", [["class", "lang-grid ion-padding"]], null, null, null, i2.View_IonGrid_0, i2.RenderType_IonGrid)), i1.ɵdid(1, 49152, null, 0, i3.IonGrid, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "ion-row", [], null, null, null, i2.View_IonRow_0, i2.RenderType_IonRow)), i1.ɵdid(3, 49152, null, 0, i3.IonRow, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SelectLanguagePage_5)), i1.ɵdid(5, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languages; _ck(_v, 5, 0, currVal_0); }, null); }
function View_SelectLanguagePage_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectLanguagePage_3)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["langsAvaialble", 2]], null, 0, null, View_SelectLanguagePage_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.languages.length; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_SelectLanguagePage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "ion-header", [], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 12, "ion-toolbar", [["class", "toolbar"]], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(3, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 4, "ion-title", [["text-center", ""]], null, null, null, i2.View_IonTitle_0, i2.RenderType_IonTitle)), i1.ɵdid(5, 49152, null, 0, i3.IonTitle, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵdid(6, 16384, null, 0, i3.CssUtilsDeprecations, [i1.ElementRef], null, null), (_l()(), i1.ɵted(7, 0, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, 0, 5, "ion-buttons", [["slot", "end"]], null, null, null, i2.View_IonButtons_0, i2.RenderType_IonButtons)), i1.ɵdid(10, 49152, null, 0, i3.IonButtons, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(11, 0, null, 0, 3, "ion-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalDismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(12, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(13, 0, null, 0, 1, "ion-icon", [["name", "close-circle"], ["slot", "icon-only"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(14, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 4, "ion-content", [], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(16, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SelectLanguagePage_1)), i1.ɵdid(18, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["langsLoaded", 2]], 0, 0, null, View_SelectLanguagePage_2)), (_l()(), i1.ɵeld(20, 0, null, null, 5, "ion-footer", [], null, null, null, i2.View_IonFooter_0, i2.RenderType_IonFooter)), i1.ɵdid(21, 49152, null, 0, i3.IonFooter, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(22, 0, null, 0, 3, "ion-button", [["class", "btn-1"], ["expand", "full"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickSelect() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(23, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { expand: [0, "expand"] }, null), (_l()(), i1.ɵted(24, 0, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = "close-circle"; _ck(_v, 14, 0, currVal_1); var currVal_2 = _co.showLoader; var currVal_3 = i1.ɵnov(_v, 19); _ck(_v, 18, 0, currVal_2, currVal_3); var currVal_4 = "full"; _ck(_v, 23, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("SELECT_LANGUAGE.header_text")); _ck(_v, 7, 0, currVal_0); var currVal_5 = i1.ɵunv(_v, 24, 0, i1.ɵnov(_v, 25).transform("SHARED.save")); _ck(_v, 24, 0, currVal_5); }); }
export function View_SelectLanguagePage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-select-language", [], null, null, null, View_SelectLanguagePage_0, RenderType_SelectLanguagePage)), i1.ɵdid(1, 114688, null, 0, i6.SelectLanguagePage, [i3.Events, i3.ModalController, i7.Router, i8.LabelService, i4.TranslateService, i9.Storage, i1.NgZone, i3.NavController, i7.ActivatedRoute, i3.LoadingController, i10.ConfigService, i11.SplashScreen], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SelectLanguagePageNgFactory = i1.ɵccf("app-select-language", i6.SelectLanguagePage, View_SelectLanguagePage_Host_0, {}, {}, []);
export { SelectLanguagePageNgFactory as SelectLanguagePageNgFactory };
