<ion-header class="modal-header">
  <ion-toolbar>
    <ion-title class="modal-header-title">{{'PICK_AND_DROP_HISTORY.order_details' | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
      <div>
          <ion-card>
            <ion-card-header>
              <ion-card-subtitle>{{order.createdAt.toDate() | date: 'medium'}}</ion-card-subtitle>
              <ion-card-title>{{'PICK_AND_DROP_HISTORY.order_id' | translate}}: {{order.orderId}}
                <span class="t-a-r">{{order.status | uppercase }}</span>
              </ion-card-title>
            </ion-card-header>

            <ion-card-content>

              <!-- pickup address -->
              <div class="address">
                <div class="icon">
                  <div class="green-circle"></div>
                </div>
                <div>
                  <b>{{'PICK_AND_DROP_HISTORY.pickup_address' | translate}}: </b>{{order.pickup.address}}
                </div>
              </div>
              <!-- pickup address -->

              <!-- drop address -->
              <div class="address">
                <div class="icon">
                  <div class="red-circle"></div>
                </div>
                <div>
                  <b>{{'PICK_AND_DROP_HISTORY.drop_address' | translate}}: </b>{{order.drop.address}}
                </div>
              </div>
              <!-- drop address -->

              <!-- pickup time -->
              <p>
                <b>{{'PICK_AND_DROP_HISTORY.pickup_at' | translate}}: </b>
                <span>{{order.pickup.date}}, {{order.pickup.time}}</span>
              </p>
              <!-- pickup time -->

              <!-- delivery time -->
              <p *ngIf="order.timeline && order.timeline.delivered">
                <b>{{'PICK_AND_DROP_HISTORY.delivered_at' | translate}}: </b>
                <span>{{order.timeline.delivered.time}}</span>
              </p>
              <!-- delivery time -->

              <!-- package details -->
              <p *ngIf="order.packageDetails">
                <b>{{'PICK_AND_DROP_HISTORY.package_details' | translate}}: </b>
                <span>{{order.packageDetails}}</span>
              </p>
              <!-- package details -->

              <!-- package cost -->
              <p *ngIf="order.delivery.cost">
                <b>{{'PICK_AND_DROP_HISTORY.amount' | translate}}: </b>
                <span>{{order.delivery.cost}}</span>
              </p>
              <!-- package cost -->

              <!-- cancel Reason -->
              <p *ngIf="order.cancelData && order.cancelData.reason">
                <b>{{'PICK_AND_DROP_HISTORY.amount' | translate}}: </b>
                <span>{{order.cancelData.reason}}</span>
              </p>
              <!-- cancel Reason -->

            </ion-card-content>
          </ion-card>

      </div>

</ion-content>