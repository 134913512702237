import * as tslib_1 from "tslib";
import { Events } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { map } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "@ionic/storage";
var PriceRequestService = /** @class */ (function () {
    function PriceRequestService(events, afs, storage) {
        this.events = events;
        this.afs = afs;
        this.storage = storage;
    }
    PriceRequestService.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('price-req:sendPriceRequest', function (uid) {
            _this.sendPriceRequest(uid);
        });
        this.events.subscribe('price-req:getAllPriceRequests', function () {
            _this.getAllPriceRequests();
        });
        this.events.subscribe('price-req:rejectPriceRequest', function (uid) {
            _this.rejectPriceRequest(uid);
        });
        this.events.subscribe('price-req:acceptPriceRequest', function (uid) {
            _this.acceptPriceRequest(uid);
        });
        // this.events.subscribe('price-req:getUserPriceRequestData', (uid) => {
        //   this.getUserPriceRequestData(uid);
        // });
        this.events.subscribe('price-req:requestForQuote', function (name, qty, uid) {
            _this.requestForQuote(name, qty, uid);
        });
        this.events.subscribe('price-req:removePriceRequestsSubs', function () {
            if (_this.reqSubs) {
                _this.reqSubs.unsubscribe();
            }
        });
    };
    PriceRequestService.prototype.sendPriceRequest = function (uid) {
        var _this = this;
        try {
            var priceRequest = firebase.functions().httpsCallable('products-sendPriceRequest');
            priceRequest(uid).then(function (response) {
                //// // console.log(response);
                if (response.data.status && response.data.status === 'sent') {
                    _this.events.publish('price-req:sendPriceRequestSuccess', 'Request has been send succesfully.');
                }
                else if (response.data.status && response.data.status === 'already_sent') {
                    _this.events.publish('price-req:sendPriceRequestSuccess', 'Request has been already send.');
                }
                else {
                    _this.events.publish('price-req:sendPriceRequestSuccess', 'There is some problem in sending request. Please try again.');
                }
            });
        }
        catch (error) {
            console.dir(error);
        }
    };
    PriceRequestService.prototype.getAllPriceRequests = function () {
        var _this = this;
        try {
            var reqRef = this.afs.collection('priceRequests', function (ref) { return ref.orderBy('createdOn', 'desc'); }).snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                var data = a.payload.doc.data();
                var id = a.payload.doc.id;
                return tslib_1.__assign({ id: id }, data);
            }); }));
            this.reqSubs = reqRef.subscribe(function (requests) {
                _this.events.publish('price-req:publishAllPriceRequests', requests);
            });
        }
        catch (error) {
            console.dir(error);
        }
    };
    PriceRequestService.prototype.rejectPriceRequest = function (uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.afs.collection('priceRequests').doc(uid).update({
                                active: false
                            })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.afs.collection('users').doc(uid).update({
                                showPrices: false
                            })];
                    case 2:
                        _a.sent();
                        this.events.publish('price-req:rejectPriceRequestSuccess');
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.dir(error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PriceRequestService.prototype.acceptPriceRequest = function (uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var prAccept;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    prAccept = firebase.functions().httpsCallable('products-acceptPriceRequest');
                    prAccept(uid).then(function (response) {
                        //// // console.log(response.data);
                        if (response.data.status === 'accepted') {
                            _this.events.publish('price-req:acceptPriceRequestSuccess', 'Prices are now active for this user.');
                        }
                        else {
                            _this.events.publish('price-req:acceptPriceRequestSuccess', 'There is some problem in activating prices. Please try again.');
                        }
                    });
                }
                catch (error) {
                    console.dir(error);
                }
                return [2 /*return*/];
            });
        });
    };
    PriceRequestService.prototype.requestForQuote = function (name, qty, uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var rfqChatMsg;
            return tslib_1.__generator(this, function (_a) {
                try {
                    rfqChatMsg = {
                        type: 'txt',
                        createdAt: new Date(),
                        author: 'user',
                        isRead: false,
                        published: true,
                        message: "I am interested in buying " + qty + " quantities of \"" + name + "\". Please quote for this product."
                    };
                    this.events.publish('chat:sendMsg', rfqChatMsg, uid);
                    this.events.publish('price-req:requestForQuoteSuccess');
                }
                catch (error) {
                    console.dir(error);
                }
                return [2 /*return*/];
            });
        });
    };
    PriceRequestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PriceRequestService_Factory() { return new PriceRequestService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.Storage)); }, token: PriceRequestService, providedIn: "root" });
    return PriceRequestService;
}());
export { PriceRequestService };
