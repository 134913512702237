<ion-header>
  <ion-toolbar>
    <ion-title>{{'PICK_AND_DROP_MAP.add_location' | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <div class="input-wrap">
          <ion-label>{{'PICK_AND_DROP_MAP.complete_address' | translate}}</ion-label>
          <ion-textarea type="text" row="3" class="form-input" [(ngModel)]="address.address">
          </ion-textarea>
        </div>
      </ion-col>
      <ion-col size="4">
        <div>
          <p>{{'PICK_AND_DROP_MAP.city' | translate}}</p>
          <ion-input type="text" class="form-input" [(ngModel)]="address.city"></ion-input>
        </div>
      </ion-col>
      <ion-col size="4">
        <div>
          <p>{{'PICK_AND_DROP_MAP.pincode' | translate}}</p>
          <ion-input type="number" class="form-input" [(ngModel)]="address.pincode"></ion-input>
        </div>
      </ion-col>
      <ion-col size="4">
        <div>
          <p>{{'PICK_AND_DROP_MAP.state' | translate}}</p>
          <div class="form-input state-wrapper" *ngIf="pickupState" (click)="presentAlert()">
            <div>{{pickupState}}</div>
            <div>
              <i class="flaticon-null-13"></i>
            </div>
          </div>
          <div class="form-input state-wrapper" (click)="openStateModal()" *ngIf="!pickupState">
            <div *ngIf="address.state">{{address.state}}</div>
            <div *ngIf="!address.state">{{'PICK_AND_DROP_MAP.select_state' | translate}}</div>
            <div>
              <i class="flaticon-null-13"></i>
            </div>
          </div>
        </div>
      </ion-col>
      <ion-col size="6" size-sm="3" *ngIf="choice == 'pickup'">
       <div class="data-field">
         <div class="data-field-txt">
           {{'PICK_AND_DROP.pickup_date' | translate}}
         </div>
         <div>
           <ion-datetime class="form-input" displayFormat="DD/MM/YYYY" [min]="pickupDate" placeholder="Select Date" [(ngModel)]="pickupDate"></ion-datetime>
         </div>
       </div>
      </ion-col>
      <ion-col size="6" size-sm="3" *ngIf="choice == 'pickup'">
       <div class="data-field">
         <div class="data-field-txt">
           {{'PICK_AND_DROP.pickup_time' | translate}}
         </div>
         <div>
           <ion-datetime class="form-input" displayFormat="hh:mm A" pickerFormat="hh:mm A" [(ngModel)]="pickupTime"></ion-datetime>
         </div>
       </div>
      </ion-col>
      <ion-col size="12" style="text-align: center;">
        <div>
          <ion-button (click)="getLatLong()" [disabled]="disableShowArea()" shape="round" size="small">
            {{'PICK_AND_DROP_MAP.show_address_on_map' | translate}}
          </ion-button>
          <br>
        </div>
      </ion-col>
      <ion-col size="12" *ngIf="showMap">
        <div #map id="map"></div>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>

<ion-footer no-border class="page-footer">
  <div class="main-container">
    <ion-button (click)="saveArea()" shape="round" [disabled]="disableSave()" class="btn-1 i-start" color="success">
      <i class="flaticon-null-20 margin-icon"></i>
      {{'PICK_AND_DROP_MAP.done' | translate}}
    </ion-button>
  </div>
</ion-footer>