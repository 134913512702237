import * as tslib_1 from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { first } from 'rxjs/operators';
import { ConfigService } from '../config/config.service';
import { LabelService } from '../label/label.service';
import { MultiCountriesService } from '../multi-countries/multi-countries.service';
import * as i0 from "@angular/core";
import * as i1 from "../config/config.service";
import * as i2 from "../multi-countries/multi-countries.service";
import * as i3 from "@angular/fire/firestore";
import * as i4 from "@ionic/angular";
import * as i5 from "../label/label.service";
var SharedNewService = /** @class */ (function () {
    function SharedNewService(configService, multiCountriesService, afs, alertController, labelService, modalController, navCtrl) {
        this.configService = configService;
        this.multiCountriesService = multiCountriesService;
        this.afs = afs;
        this.alertController = alertController;
        this.labelService = labelService;
        this.modalController = modalController;
        this.navCtrl = navCtrl;
    }
    SharedNewService.prototype.showQtyLeftMsg = function (product, index) {
        if (index === void 0) { index = 0; }
        var qtyLeftMsgCount = this.configService.environment.qtyLeft || 5;
        var quantity = 0;
        quantity = product.isPriceList ? +product.priceList[index].totalQuantity || 0 : +product.productQty || 0;
        return quantity > 0 && quantity <= qtyLeftMsgCount ? quantity : 0;
    };
    SharedNewService.prototype.showPriceListQtyLeftMsg = function (quantity) {
        var qtyLeftMsgCount = this.configService.environment.qtyLeft || 5;
        return quantity > 0 && quantity <= qtyLeftMsgCount ? quantity : 0;
    };
    SharedNewService.prototype.getPriceBasedOnExhangeRate = function (price) {
        if (this.multiCountriesService.multiCountriesSettings.settings.autoExchange) {
            var toRate = this.multiCountriesService.rates[this.configService.environment.currencyCode];
            var fromRate = this.multiCountriesService.rates['INR'];
            return ((toRate / fromRate) * price);
        }
        else {
            return price;
        }
    };
    SharedNewService.prototype.getTemplateData = function (templateId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var templateData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.afs.collection('templates').doc(templateId).valueChanges().pipe(first()).toPromise()];
                    case 1:
                        templateData = _a.sent();
                        return [2 /*return*/, templateData || null];
                }
            });
        });
    };
    SharedNewService.prototype.presentAlert = function (alertData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            message: alertData.msg,
                            backdropDismiss: alertData.backdropDismiss,
                            buttons: [{
                                    text: "" + this.labelService.labels['SHARED']['ok'],
                                    handler: function () {
                                        if (alertData.page) {
                                            _this.navCtrl.navigateRoot([alertData.page]);
                                        }
                                        else {
                                            return;
                                        }
                                    }
                                }]
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SharedNewService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SharedNewService_Factory() { return new SharedNewService(i0.ɵɵinject(i1.ConfigService), i0.ɵɵinject(i2.MultiCountriesService), i0.ɵɵinject(i3.AngularFirestore), i0.ɵɵinject(i4.AlertController), i0.ɵɵinject(i5.LabelService), i0.ɵɵinject(i4.ModalController), i0.ɵɵinject(i4.NavController)); }, token: SharedNewService, providedIn: "root" });
    return SharedNewService;
}());
export { SharedNewService };
