import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-repeat-addon-choice',
  templateUrl: './repeat-addon-choice.page.html',
  styleUrls: ['./repeat-addon-choice.page.scss'],
})
export class RepeatAddonChoicePage implements OnInit {
  name;
  lastAddon;
  constructor(private modalController: ModalController) { }

  ngOnInit() {
    // console.log('lastAddon', this.lastAddon);
  }

  saveChoice(choice: string) {
    this.modalController.dismiss({choice});
  }

  getOptionsLength() {
    let length = 0;
    this.lastAddon.forEach(addon => {
      length += addon.options.length;
    });
    return length - 1;
  }

  closeModal() {
    this.modalController.dismiss({choice: ''});
  }

}
