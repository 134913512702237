import { first, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Events } from '@ionic/angular';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Subscription } from 'rxjs';
import { Storage } from '@ionic/storage';
import { SharedService } from '../shared/shared.service';
@Injectable({
    providedIn: 'root'
})
export class BrandsService {
    brandsSubs: Subscription;
    constructor(private events: Events,
        private afs: AngularFirestore,
        private fbStorage: AngularFireStorage,
        private storage: Storage,
        private sharedService: SharedService, ) { }

    initializeSubscriptions() {
        this.events.subscribe('brands:getAllBrandsForAdmin', () => {
            this.getAllBrandsForAdmin();
        });
        this.events.subscribe('brands:getAllBrandsForUser', () => {
            this.getAllBrandsForUser();
        });
        this.events.subscribe('brands:saveBrand', (data, img, banner, bid) => {
            this.saveBrand(data, img, banner, bid);
        });
        this.events.subscribe('brands:deleteBrand', (bid) => {
            this.deleteBrand(bid);
        });
        this.events.subscribe('brands:getBrandsData', (bid) => {
            this.getBrandsData(bid);
        });

        this.setBrandsInLocalStorage();

        this.events.subscribe('brands:removeBrandsSubs', () => {
            if (this.brandsSubs) {
                this.brandsSubs.unsubscribe();
            }
        });
    }

    async getAllBrandsForAdmin() {
        const brandsData: any = await this.getAllBrands();
        if (!brandsData.length) {
            this.events.publish('brands:noBrandAvailableForAdmin');
        } else {
            this.events.publish('brands:publishAllBrandsForAdmin', brandsData);
        }
    }

    async getAllBrandsForUser() {
        try {
            const region = await this.sharedService.checkRegionIdForApi();
            let regionId = region.regionId;
            let brandsRef: AngularFirestoreCollection;
            if (regionId) {
                brandsRef = this.afs.collection('brands', ref => ref
                    .orderBy('sortedAt', 'desc')
                    .where('status', '==', true)
                    .where('regionId', 'array-contains', regionId));
            } else {
                brandsRef = this.afs.collection('brands', ref => ref
                    .where('status', '==', true)
                    .orderBy('sortedAt', 'desc'));
            }

            const brandsSnap = brandsRef.snapshotChanges().pipe(
                map((actions: any) => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return { id, ...data };
                }))
            );
            const brandsSub = brandsSnap.subscribe(async (brandsData) => {
                if (!brandsData.length) {
                    this.events.publish('brands:noBrandAvailableForUser');
                    brandsSub.unsubscribe();
                } else {
                    this.events.publish('brands:publishAllBrandsForUser', brandsData);
                    brandsSub.unsubscribe();
                }
            });
        } catch (error) {
            console.dir(error);
        }
    }

    async getAllBrands() {
        return new Promise(async (resolve, reject) => {
            this.storage.get('userRole').then((role) => {
                let brandsRef;
                if (role === 'admin') {
                    brandsRef = this.afs.collection('brands', ref => ref.orderBy('sortedAt', 'desc'));
                } else {
                    brandsRef = this.afs.collection('brands', ref => ref
                        .where('status', '==', true)
                        .orderBy('sortedAt', 'desc'));
                }
                const brandsSnap = brandsRef.snapshotChanges().pipe(
                    map((actions: any) => actions.map(a => {
                        const data = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return { id, ...data };
                    }))
                );
                this.brandsSubs = brandsSnap.subscribe((brandsData) => {
                    resolve(brandsData);
                });
            });
        });
    }


    async saveBrand(data: any, img: any, banner: any, bid: string) {
        try {
            let brandid = bid;
            let baseImg: any = {};
            let storageImg: any = {};
            let bannerBaseImg: any = {};
            let bannerStorageImg: any = {};
            if (!brandid) {
                brandid = this.afs.collection('brands').ref.doc().id;
            }
            if (img.length) {
                img.forEach(i => {
                    if (i.url.includes('data:image/jpeg;base64,')) {
                        baseImg = i;
                    } else {
                        storageImg = i;
                    }
                });
            }
            if (banner.length) {
                banner.forEach(i => {
                    if (i.url.includes('data:image/jpeg;base64,')) {
                        bannerBaseImg = i;
                    } else {
                        bannerStorageImg = i;
                    }
                });
            }
            await this.afs.collection('brands').doc(brandid).set({
                status: data.status,
                name: data.name,
                image: storageImg,
                banner: bannerStorageImg,
                sortedAt: data.sortedAt
            });
            if (baseImg.hasOwnProperty('url')) {
                const imgRef: any = this.fbStorage.ref(`brands/${brandid}/image/` + new Date().getTime().toString() + '.png');
                await imgRef.putString(baseImg.url, 'data_url');
            }
            if (bannerBaseImg.hasOwnProperty('url')) {
                const imgRef: any = this.fbStorage.ref(`brandsBanner/${brandid}/image/` + new Date().getTime().toString() + '.png');
                await imgRef.putString(bannerBaseImg.url, 'data_url');
            }
            this.events.publish('brands:saveBrandSuccess');
        } catch (error) {
            console.dir(error);
        }
    }
    async deleteBrand(bid: string) {
     
        try {
            await this.afs.collection('brands').doc(bid).delete();
            this.events.publish('brands:deleteBrandSuccess');
        } catch (error) {
            
        }
    }

    async updateBrandsPosition(id: string, changedDate: any) {
        await this.afs.doc(`brands/${id}`).update({ sortedAt: changedDate });
        this.events.publish('brands:updateBrandsPostionSucess');
    }

    async getBrandsData(bid: string) {
        try {
            const brandData: any = await this.afs.collection('brands').doc(bid).valueChanges().pipe(first()).toPromise();
            this.events.publish('brands:publishBrandsData', brandData);
        } catch (error) {
            console.dir(error);
        }
    }

    async getAllBrandsForSideMenu() {
        try {
            const storageBrands = JSON.parse(localStorage.getItem('brands')) || [];
            
            if (!storageBrands.length) {
                const brandsData = await this.setBrandsInLocalStorage();
                return brandsData;
            } else {
                return storageBrands;
            }

        } catch (error) {
            console.dir(error);
        }
    }

    async setBrandsInLocalStorage() {
        return new Promise(async (resolve, reject) => {
            const region = await this.sharedService.checkRegionIdForApi();
                let regionId = region.regionId;
                let brandsRef: AngularFirestoreCollection;
                if (regionId) {
                    brandsRef = this.afs.collection('brands', ref => ref
                        .orderBy('sortedAt', 'desc')
                        .where('status', '==', true)
                        .where('regionId', 'array-contains', regionId));
                } else {
                    brandsRef = this.afs.collection('brands', ref => ref
                        .orderBy('sortedAt', 'desc')
                        .where('status', '==', true));
                }

                const brandsSnap = brandsRef.snapshotChanges().pipe(
                    map(actions => actions.map(a => {
                        const data = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return { id, ...data };
                    }))
                );
                const brandsData: any = await brandsSnap.pipe(first()).toPromise();
              

                localStorage.setItem('brands', JSON.stringify(brandsData));
                
                resolve(brandsData);
        })
    }
}
