<ion-header class="modal-header">
  <ion-toolbar>
    <ion-title class="modal-header-title">Make Payment</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="stripe-content">

  <ion-card class="card">
    <ion-img src="assets/icon/stripe.png"></ion-img>
    <ion-card-header>
      <ion-card-subtitle>Add your card details</ion-card-subtitle>
    </ion-card-header>
    <ion-card-content>
      <form action="/" method="post" id="payment-form">
        <div class="form-row">
          <div id="card-element">
            <!-- a Stripe Element will be inserted here. -->
          </div>
          <!-- Used to display Element errors -->
          <div id="card-errors" role="alert"></div>
        </div>
      </form>
    </ion-card-content>
  </ion-card>

  <ion-button color="success" expand="full" (click)="makePayment()">Make Payment</ion-button>


</ion-content>