/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./rate-product.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../components/rating/rating.component.ngfactory";
import * as i7 from "../../components/rating/rating.component";
import * as i8 from "../../services/shared/shared.service";
import * as i9 from "../../services/label/label.service";
import * as i10 from "../../services/user/user.service";
import * as i11 from "./rate-product.page";
var styles_RateProductPage = [i0.styles];
var RenderType_RateProductPage = i1.ɵcrt({ encapsulation: 0, styles: styles_RateProductPage, data: {} });
export { RenderType_RateProductPage as RenderType_RateProductPage };
function View_RateProductPage_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.product.coverPic.thumb, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_RateProductPage_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.product.coverPic.url, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_RateProductPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ion-avatar", [["slot", "start"]], null, null, null, i2.View_IonAvatar_0, i2.RenderType_IonAvatar)), i1.ɵdid(1, 49152, null, 0, i3.IonAvatar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_RateProductPage_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_RateProductPage_3)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.product.coverPic.thumb; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.product.coverPic.thumb; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_RateProductPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "ion-header", [], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 12, "ion-toolbar", [["class", "toolbar"]], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(3, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 4, "ion-title", [["text-center", ""]], null, null, null, i2.View_IonTitle_0, i2.RenderType_IonTitle)), i1.ɵdid(5, 49152, null, 0, i3.IonTitle, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵdid(6, 16384, null, 0, i3.CssUtilsDeprecations, [i1.ElementRef], null, null), (_l()(), i1.ɵted(7, 0, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, 0, 5, "ion-buttons", [["slot", "end"]], null, null, null, i2.View_IonButtons_0, i2.RenderType_IonButtons)), i1.ɵdid(10, 49152, null, 0, i3.IonButtons, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(11, 0, null, 0, 3, "ion-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(12, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(13, 0, null, 0, 1, "ion-icon", [["name", "close"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(14, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 12, "ion-content", [["class", "f-s-14"]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(16, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(17, 0, null, 0, 7, "ion-item", [["class", "rp-pdt-info"], ["lines", "none"]], null, null, null, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(18, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { lines: [0, "lines"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_RateProductPage_1)), i1.ɵdid(20, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(21, 0, null, 0, 3, "ion-label", [], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(22, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(23, 0, null, 0, 1, "h4", [["class", "ion-text-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵted(24, null, ["", ""])), (_l()(), i1.ɵeld(25, 0, null, 0, 0, "hr", [["class", "line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, 0, 1, "app-rating", [["type", "product"]], null, [[null, "rateEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("rateEvent" === en)) {
        var pd_0 = (_co.rate($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_RatingComponent_0, i6.RenderType_RatingComponent)), i1.ɵdid(27, 114688, null, 0, i7.RatingComponent, [i8.SharedService, i9.LabelService, i3.ModalController, i3.ActionSheetController, i10.UserService], { type: [0, "type"], stars: [1, "stars"] }, { rateEvent: "rateEvent" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "close"; _ck(_v, 14, 0, currVal_1); var currVal_2 = "none"; _ck(_v, 18, 0, currVal_2); var currVal_3 = (_co.product.hasOwnProperty("coverPic") && _co.product.coverPic.url); _ck(_v, 20, 0, currVal_3); var currVal_5 = "product"; var currVal_6 = _co.rating; _ck(_v, 27, 0, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("RATE_PRODUCT.header_text")); _ck(_v, 7, 0, currVal_0); var currVal_4 = _co.product.prodName; _ck(_v, 24, 0, currVal_4); }); }
export function View_RateProductPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-rate-product", [], null, null, null, View_RateProductPage_0, RenderType_RateProductPage)), i1.ɵdid(1, 114688, null, 0, i11.RateProductPage, [i3.ModalController, i3.Events, i9.LabelService, i3.LoadingController, i3.AlertController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RateProductPageNgFactory = i1.ɵccf("app-rate-product", i11.RateProductPage, View_RateProductPage_Host_0, {}, {}, []);
export { RateProductPageNgFactory as RateProductPageNgFactory };
