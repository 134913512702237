import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
var SplashScreenPage = /** @class */ (function () {
    function SplashScreenPage(modalController) {
        this.modalController = modalController;
    }
    SplashScreenPage.prototype.ngOnInit = function () {
    };
    SplashScreenPage.prototype.ionViewDidEnter = function () {
        var _this = this;
        // console.log('splash data', this.data)
        setTimeout(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.dismiss()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); }, parseInt(this.data.timeout) * 1000);
    };
    return SplashScreenPage;
}());
export { SplashScreenPage };
