import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { AppointmentService } from 'src/app/services/appointment/appointment.service';
import { LabelService } from 'src/app/services/label/label.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { UserService } from 'src/app/services/user/user.service';
var AppointmentSchedulesPage = /** @class */ (function () {
    function AppointmentSchedulesPage(userService, appointmentService, sharedService, labelService, modalController, router) {
        this.userService = userService;
        this.appointmentService = appointmentService;
        this.sharedService = sharedService;
        this.labelService = labelService;
        this.modalController = modalController;
        this.router = router;
        this.schedules = [];
        this.maxDays = 0;
        this.scheduleSlots = [];
        this.selectedTime = {};
        this.item = {};
        this.booked = false;
    }
    AppointmentSchedulesPage.prototype.ngOnInit = function () {
        // console.log('schedules', this.schedules);
        // console.log('maxDays', this.maxDays);
        // console.log('item', this.item);
        this.getSchedules();
    };
    AppointmentSchedulesPage.prototype.getSchedules = function () {
        var _loop_1 = function (index) {
            var dayToCheck = moment().add(index, 'days');
            var schedule = this_1.schedules.filter(function (s) { return s.day === dayToCheck.format('dddd'); });
            if (schedule.length) {
                if (schedule[0].active && schedule[0].schedule.length) {
                    this_1.scheduleSlots.push({
                        date: dayToCheck.toDate(),
                        schedules: schedule[0].schedule,
                        day: schedule[0].day,
                        active: false
                    });
                }
            }
        };
        var this_1 = this;
        for (var index = 0; index < this.maxDays; index++) {
            _loop_1(index);
        }
        this.scheduleSlots[0].active = true;
    };
    AppointmentSchedulesPage.prototype.selectDate = function (i) {
        this.scheduleSlots[i].active = true;
        this.scheduleSlots.forEach(function (slot, index) {
            if (index !== i) {
                slot.active = false;
            }
        });
    };
    AppointmentSchedulesPage.prototype.getActiveTimeSchedules = function () {
        return this.scheduleSlots.filter(function (s) { return s.active === true; })[0].schedules;
    };
    AppointmentSchedulesPage.prototype.setTimeSlot = function (event) {
        this.selectedTime = event.target.value;
    };
    AppointmentSchedulesPage.prototype.bookAppointment = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var scheduleSlot, data, res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.sharedService.presentLoading()];
                    case 1:
                        _a.sent();
                        scheduleSlot = this.scheduleSlots.filter(function (s) { return s.active === true; })[0];
                        data = {
                            item: this.item,
                            schedule: {
                                date: moment(scheduleSlot.date).format('DD-MM-YYYY'),
                                slot: {
                                    start: this.selectedTime.start,
                                    end: this.selectedTime.end
                                },
                                day: scheduleSlot.day
                            },
                            vendor: this.vendor,
                            user: {
                                id: this.userService.getUserId(),
                                name: this.userService.getUserName()
                            },
                            status: 'accepted'
                        };
                        return [4 /*yield*/, this.appointmentService.bookAppointment(data, this.selectedTime.slotLimit)];
                    case 2:
                        res = _a.sent();
                        this.sharedService.loading.dismiss();
                        if (res === 'added') {
                            this.sharedService.presentAlert(this.labelService.labels['APPOINTMENT_SCHEDULE']['appointment_booked']);
                            this.booked = true;
                            this.closeModal();
                            //this.router.navigate(['/']);
                        }
                        else if (res === 'not_available') {
                            this.sharedService.presentAlert(this.labelService.labels['APPOINTMENT_SCHEDULE']['slot_not_available']);
                        }
                        else {
                            this.sharedService.presentAlert(this.labelService.labels['SHARED']['some_issue_msg']);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AppointmentSchedulesPage.prototype.closeModal = function () {
        this.modalController.dismiss({ booked: this.booked });
    };
    AppointmentSchedulesPage.prototype.getDate = function (date) {
        return moment(date).format('DD MMM');
    };
    AppointmentSchedulesPage.prototype.isBookNowDisabled = function () {
        return !Object.keys(this.selectedTime).length;
    };
    return AppointmentSchedulesPage;
}());
export { AppointmentSchedulesPage };
