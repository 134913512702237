import * as tslib_1 from "tslib";
import { Events } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, first } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "@angular/fire/storage";
var ProductOptionsService = /** @class */ (function () {
    function ProductOptionsService(events, afs, fbStorage) {
        this.events = events;
        this.afs = afs;
        this.fbStorage = fbStorage;
    }
    ProductOptionsService.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('product-options:getAllProductOptions', function (pid) {
            _this.getAllProductOptions(pid);
        });
        this.events.subscribe('product-options:deleteProductOption', function (oid, pid) {
            _this.deleteProductOption(oid, pid);
        });
        this.events.subscribe('product-options:editProductOption', function (editdata, pid, oid, listOfImages, barcode) {
            _this.editProductOption(editdata, pid, oid, listOfImages, barcode);
        });
        this.events.subscribe('product-options:getOptionData', function (pid, oid) {
            _this.getOptionData(pid, oid);
        });
    };
    ProductOptionsService.prototype.getAllProductOptions = function (pid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var optionsRef, optionsSnap, options, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        optionsRef = this.afs.collection('products').doc(pid).collection('options');
                        optionsSnap = optionsRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                            var data = a.payload.doc.data();
                            var id = a.payload.doc.id;
                            return tslib_1.__assign({ id: id }, data);
                        }); }));
                        return [4 /*yield*/, optionsSnap.pipe(first()).toPromise()];
                    case 1:
                        options = _a.sent();
                        this.events.publish('product-options:publishAllProductOptions', options);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.dir(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProductOptionsService.prototype.deleteProductOption = function (oid, pid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('products').doc(pid).collection('options').doc(oid).delete()];
                    case 1:
                        _a.sent();
                        this.events.publish('product-options:getAllProductOptions', pid);
                        this.events.publish('product-options:deleteProductOptionSuccess');
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        console.dir(error_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProductOptionsService.prototype.editProductOption = function (editdata, pid, oid, listOfImages, barcode) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var colorObj, imgRef, downloadURL, imgRef, downloadURL, error_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        colorObj = {};
                        if (editdata.color.hasOwnProperty('image') && editdata.color.image.includes('data:image/jpeg;base64,')) {
                            colorObj = tslib_1.__assign({}, editdata.color);
                            delete editdata.color.image;
                        }
                        if (!(barcode !== '')) return [3 /*break*/, 4];
                        imgRef = this.fbStorage.ref("productOptions/" + pid + "/options/" + oid + "/barcode/" + new Date().getTime().toString() + '.png');
                        return [4 /*yield*/, imgRef.putString(barcode, 'data_url')];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, imgRef.getDownloadURL().pipe(first()).toPromise()];
                    case 2:
                        downloadURL = _a.sent();
                        editdata["barcode"] = downloadURL;
                        return [4 /*yield*/, this.afs.collection('products').doc(pid).collection('options').doc(oid).update(editdata)];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, this.afs.collection('products').doc(pid).collection('options').doc(oid).update(editdata)];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6:
                        if (!colorObj.hasOwnProperty('image')) return [3 /*break*/, 10];
                        imgRef = this.fbStorage.ref("productOptions/" + pid + "/options/" + oid + "/color/" + new Date().getTime().toString() + '.png');
                        return [4 /*yield*/, imgRef.putString(colorObj.image, 'data_url')];
                    case 7:
                        _a.sent();
                        return [4 /*yield*/, imgRef.getDownloadURL().pipe(first()).toPromise()];
                    case 8:
                        downloadURL = _a.sent();
                        return [4 /*yield*/, this.afs.collection('products').doc(pid).collection('options').doc(oid).update({
                                color: {
                                    image: downloadURL,
                                    name: colorObj.name,
                                    code: colorObj.code
                                }
                            })];
                    case 9:
                        _a.sent();
                        _a.label = 10;
                    case 10:
                        if (!(listOfImages.length !== 0)) return [3 /*break*/, 15];
                        _a.label = 11;
                    case 11:
                        _a.trys.push([11, 13, , 14]);
                        return [4 /*yield*/, this.addimgtoFirebase(pid, oid, listOfImages)];
                    case 12:
                        _a.sent();
                        this.events.publish('product-options:editSuccess', 'Success', 'Product edited successfully!');
                        return [3 /*break*/, 14];
                    case 13:
                        error_3 = _a.sent();
                        //// // console.log(error);
                        this.events.publish('product-options:editFailure', 'Failure', 'Product not edited successfully!');
                        return [3 /*break*/, 14];
                    case 14: return [3 /*break*/, 18];
                    case 15:
                        if (!(!editdata.images.length && !listOfImages.length)) return [3 /*break*/, 17];
                        return [4 /*yield*/, this.afs.collection('products').doc(pid).collection('options').doc(oid).update({
                                coverPic: {
                                    url: 'assets/img/placeholder-img.jpg',
                                    mob: 'assets/img/placeholder-img.jpg',
                                    thumb: 'assets/img/placeholder-img.jpg'
                                }
                            })];
                    case 16:
                        _a.sent();
                        this.events.publish('product-options:editSuccess', 'Success', 'Product edited successfully!');
                        return [3 /*break*/, 18];
                    case 17:
                        //// // console.log('in else part');
                        this.events.publish('product-options:editSuccess', 'Success', 'Product edited successfully!');
                        _a.label = 18;
                    case 18: return [2 /*return*/];
                }
            });
        });
    };
    ProductOptionsService.prototype.addimgtoFirebase = function (pid, oid, imgdataAndSize) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var image, i, mediaDocRef, imgRef, downloadURL;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        image = {
                            url: null,
                            size: null,
                            uploadedAt: null,
                            productId: null,
                            optionId: null
                        };
                        i = 0;
                        _a.label = 1;
                    case 1:
                        if (!(i < imgdataAndSize.length)) return [3 /*break*/, 6];
                        image.url = '';
                        image.size = imgdataAndSize[i].size;
                        image.uploadedAt = new Date();
                        image.productId = pid;
                        image.optionId = oid;
                        return [4 /*yield*/, this.afs.collection('media').doc('images').collection('productOptions').add(image)];
                    case 2:
                        mediaDocRef = _a.sent();
                        imgRef = this.fbStorage.ref("productOptions/" + pid + "/options/" + oid + "/images/" + mediaDocRef.id + '.png');
                        return [4 /*yield*/, imgRef.putString(imgdataAndSize[i].base64Img, 'data_url')];
                    case 3:
                        _a.sent();
                        if (!(imgdataAndSize[i].cover === true)) return [3 /*break*/, 5];
                        return [4 /*yield*/, imgRef.getDownloadURL().pipe(first()).toPromise()];
                    case 4:
                        downloadURL = _a.sent();
                        this.afs.collection('products').doc(pid).collection('options').doc(oid).update({ coverPic: { imageId: mediaDocRef.id, url: downloadURL } });
                        _a.label = 5;
                    case 5:
                        i++;
                        return [3 /*break*/, 1];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ProductOptionsService.prototype.getOptionData = function (pid, oid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var optData, productData, error_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.afs.collection('products').doc(pid).collection('options').doc(oid).valueChanges().pipe(first()).toPromise()];
                    case 1:
                        optData = _a.sent();
                        return [4 /*yield*/, this.afs.collection('products').doc(pid).valueChanges().pipe(first()).toPromise()];
                    case 2:
                        productData = _a.sent();
                        this.events.publish('product-options:publishOptionData', optData, productData);
                        return [3 /*break*/, 4];
                    case 3:
                        error_4 = _a.sent();
                        console.dir(error_4);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ProductOptionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProductOptionsService_Factory() { return new ProductOptionsService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.AngularFireStorage)); }, token: ProductOptionsService, providedIn: "root" });
    return ProductOptionsService;
}());
export { ProductOptionsService };
