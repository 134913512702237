<ion-header>
  <ion-toolbar class="toolbar">
    <ion-title text-center>{{'SELECT_LANGUAGE.header_text' | translate}}</ion-title>
    <ion-buttons slot="end"  >
      <ion-button (click)="modalDismiss()">
        <ion-icon slot="icon-only" name="close-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>


<ion-content>

  <div class="spinner" *ngIf="showLoader; else langsLoaded;">
    <ion-spinner color="primary"></ion-spinner>
  </div>

  <ng-template #langsLoaded>
    <div class="no-langs" *ngIf="!languages.length; else langsAvaialble;">
      {{'SELECT_LANGUAGE.no_languages_added' | translate}}
    </div>

    <ng-template #langsAvaialble>
      <ion-grid class="lang-grid ion-padding">
        <ion-row>
          <ion-col size="6" *ngFor="let lang of languages; let i=index;" class="lang-wrapper" (click)="selectLang(i)">
            <div class="lang-names" [ngClass]="{'active-lang': lang.isDefault, 'inactive-lang': !lang.isDefault}">
              <div class="lang-names-actual">
                {{lang.langName}}
              </div>
              <div class="lang-names-eng">
                {{lang.englishName}}
              </div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ng-template>


  </ng-template>


</ion-content>

<ion-footer>
  <ion-button class="btn-1" expand="full" (click)="onClickSelect()">
    {{'SHARED.save' | translate}}
  </ion-button>
  </ion-footer>
