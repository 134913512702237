<ion-header>
  <ion-toolbar class="toolbar">
    <ion-title text-center>{{'BOOKING_SCHEDULE.header_text' | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="modalDismiss()">
        <ion-icon slot="icon-only" name="close-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="data?.item">
  <ng-container *ngIf="getBookingDuration() as duration">
    <div class="duration">
      <span>
        {{'BOOKING_SCHEDULE.duration' | translate}}:
      </span>
      <span>
        {{duration}}
      </span>
    </div>
    <div class="separator"></div>
  </ng-container>

  <ng-container *ngIf="data.item.shortDescription">
    <div class="heading">
      {{'BOOKING_SCHEDULE.booking_desc' | translate}}:
    </div>
    <div class="product-short-desciption" [innerHtml]="data.item.shortDescription | safeHtml"></div>
    <div class="separator"></div>
  </ng-container>

  <ng-container *ngIf="scheduleSlots.length; else noSlots;">
    <div class="service-slot">
      <p class="choose-txt">{{'BOOKING_SCHEDULE.choose_slot' | translate}}</p>
      <div class="scrolling-wrapper">
        <div *ngFor="let slot of scheduleSlots; let i = index;" class="slot-date"
          [ngClass]="{'slot-active': slot.active}" (click)="selectDate(i)">
          <div class="f-w-b">{{slot.day}}</div>
          <div>{{getDate(slot.date)}}</div>
          <div class="arrow-up" *ngIf="slot.active"></div>
        </div>
      </div>

      <div class="time-schedules">
        <ion-list>
          <ion-radio-group (ionChange)="setTimeSlot($event)">
            <ion-item *ngFor="let timeSlot of getActiveTimeSchedules()" lines="none">
              <ion-label>
                <ion-text>
                  {{convert24to12(timeSlot.start)}} to {{convert24to12(timeSlot.end)}}
                </ion-text>
                <ion-text *ngIf="!timeSlot.slotLimit">
                  {{'BOOKING_SCHEDULE.slot_full' | translate}}
                </ion-text>
              </ion-label>
              <ion-radio slot="start" [value]="timeSlot" [disabled]="!timeSlot.slotLimit"></ion-radio>
            </ion-item>
          </ion-radio-group>
        </ion-list>
      </div>
    </div>
    <div class="separator"></div>
  </ng-container>

  <ng-template #noSlots>
    <p class="no-data-txt">{{'BOOKING_SCHEDULE.no_slots' | translate}}</p>
    <div class="separator"></div>
  </ng-template>

  <ng-container *ngIf="data.item.allowAddress">
    <div class="content-padding address">
      <div class="d-flex">
        <h6 class="heading">{{'BOOKING_SCHEDULE.address' | translate}}</h6>
        <div class="address-btn">
          <ion-button (click)="onClickChangeOrAddAddress()" color="secondary" fill="outline">
            {{'BOOKING_SCHEDULE.change_or_add_address' | translate}}
          </ion-button>
        </div>
      </div>
      <div *ngIf="address">
        <p>{{address.name}}</p>
        <p>{{address.address}}</p>
        <p>{{address.phoneNo}}</p>
      </div>
    </div>
    <div class="separator"></div>
  </ng-container>

  <ng-container *ngIf="isCustomInputAvailable()">
    <div class="content-padding">
      <h6 class="heading">{{data.item.additionalInfo.customInput.label || 'Custom Input'}}</h6>
      <ion-textarea class="form-input" row="2" placeholder="{{'BOOKING_SCHEDULE.enter_ph_text' | translate}}"
        [(ngModel)]="data.item.additionalInfo.customInput.value">
      </ion-textarea>
    </div>
    <div class="separator"></div>
  </ng-container>

  <div class="booking-summary">
    <h6 class="heading">{{'BOOKING_SCHEDULE.booking_summary' | translate}}</h6>
    <div class="name">
      <span>{{data.item.name}}</span>
      <span>{{data.item.price | currency: currencyCode:true:'.2-2'}}</span>
    </div>

    <div class="options" *ngIf="data.item.addOns.selectedOptions.length">
      <ng-container *ngFor="let section of data.item.addOns.selectedOptions">
        <ng-container *ngFor="let option of section.options">
          <div class="option">
            <span>{{option.name}}</span>
            <span>{{option.price | currency: currencyCode:true:'.2-2'}}</span>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div class="price-summary">
      <div class="total-gst" *ngIf="data.item.gst.isExclusive">
        <span>{{taxType}}</span>
        <span>{{getTotalAmount().totalGst | currency: currencyCode:true:'.2-2'}}</span>
      </div>
      <div class="total-price">
        <span>{{'BOOKING_SCHEDULE.total_price' | translate}}</span>
        <span>{{getTotalAmount().totalAmount | currency: currencyCode:true:'.2-2'}}</span>
      </div>
    </div>
  </div>

  <!-- <ng-template #noSlots>
  <p class="no-data-txt">{{'BOOKING_SCHEDULE.no_slots' | translate}}</p>
</ng-template> -->
</ion-content>

<ion-footer>
  <ion-button (click)="bookService()" expand="block" class="footer-button" [disabled]="isBookNowDisabled()">
    {{'BOOKING_SCHEDULE.book_now' | translate}}
  </ion-button>
</ion-footer>