import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { DeliveryService } from 'src/app/services/delivery/delivery.service';
import { LabelService } from 'src/app/services/label/label.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { StatesModalPage } from '../../states-modal/states-modal.page';
declare var google;
var markerDragged = false;

@Component({
  selector: 'app-pick-drop-map',
  templateUrl: './pick-drop-map.page.html',
  styleUrls: ['./pick-drop-map.page.scss'],
})
export class PickDropMapPage implements OnInit {
  choice;
  pickupState;
  prefillDetails;
  @ViewChild('map',{ static: false}) mapElement: ElementRef;
  map: any;
  lat:any = 0;
  lng:any = 0;
  address = {
    address: '',
    city: '',
    state: '',
    pincode: '',
  };
  PICK_AND_DROP_MAP;
  SHARED;
  showMap = false;
  marker;
  
  pickupDate = new Date().toISOString();
  pickupTime;

  loading: any;
  constructor(private modalController: ModalController, private loadingController: LoadingController, private deliveryService: DeliveryService, private sharedService: SharedService, private labelService: LabelService) {
  }

  ngOnInit() { }

  async ionViewWillEnter(){
    // console.log('choice:', this.choice);
    this.address.state = this.pickupState;
    if (this.prefillDetails && 'address' in this.prefillDetails) {
      this.address = this.prefillDetails.address;
      this.pickupDate = this.prefillDetails.date;
      this.pickupTime = this.prefillDetails.time;
    }
    this.PICK_AND_DROP_MAP = this.labelService.labels['PICK_AND_DROP_MAP'];
    this.SHARED = this.labelService.labels['SHARED'];
  }

  closeModal() {
    this.modalController.dismiss();
  }

  async openStateModal() {
    const modal = await this.modalController.create({
    component: StatesModalPage,
    backdropDismiss: false,
    });
    modal.onDidDismiss()
    .then((res) => {
      if(res.data) {
        this.address.state = res.data.state;
      }
  });
    await modal.present();
}

disableShowArea() {
  for (let property in this.address) {
  if (this.address[property] == '') {
    return true;
  }
}
  return false;
}

disableSave(){
  if (this.lat == 0 || this.lng == 0 ) {
    return true;
  }
  return false;
}

async getLatLong(){
  this.presentLoading(`${this.PICK_AND_DROP_MAP['fetching_area']}`);
  const latLngObj:any = await this.deliveryService.getLatLngFromAddress(this.address);
  // console.log('latLngObj:', latLngObj);
  if(latLngObj){
    this.lat = latLngObj.results[0].geometry.location.lat;
    this.lng = latLngObj.results[0].geometry.location.lng,
    this.showMap = true;
    setTimeout(() => {
      this.loadMap();
    }, 500);
    this.loading.dismiss();
  } else{
    this.loading.dismiss();
    this.sharedService.presentAlert(`${this.PICK_AND_DROP_MAP['please_try_again_later']}`);
  }
}

  async saveArea(){
    if ((!this.pickupTime && this.choice == 'pickup')) {
      this.sharedService.presentAlert(`${this.PICK_AND_DROP_MAP['please_select_pickup_time']}`);
    } else {
      await this.presentLoading(`${this.SHARED['please_wait']}`);
      let latLngObj = {
        lat: this.marker.getPosition().lat(),
        lng: this.marker.getPosition().lng()
      };
      if (markerDragged) {
        const address:any = await this.deliveryService.getAddressFromLatLng(latLngObj);
        this.address.address = address.results[0].formatted_address;
      }
      let obj = {
        address: this.address,
        latLng: latLngObj,
        pickupDate: this.pickupDate,
        pickupTime: this.pickupTime,
      }
      this.loading.dismiss();
      setTimeout(() => {
        this.modalController.dismiss(obj);
      }, 500);
    }
}
presentAlert(){
  this.sharedService.presentAlert(`${this.PICK_AND_DROP_MAP['state_and_city_same']}`);
}

async presentLoading(msg: string) {
  this.loading = await this.loadingController.create({
    message: msg,
    duration: 3000
  });
  this.loading.present();
}

loadMap(){
  let latLng = new google.maps.LatLng(this.lat, this.lng);
  let mapOptions = {
    center: latLng,
    zoom: 15,
    scrollwheel: false,
    mapTypeId: google.maps.MapTypeId.ROADMAP
  }

  this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
  let marker = new google.maps.Marker({
    map: this.map,
    animation: google.maps.Animation.DROP,
    draggable:true,
    position: this.map.getCenter()
  });
  this.marker = marker;
  new google.maps.event.addListener(this.marker, 'dragend', function (event) {
    markerDragged = true;
  });
}

}
