/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./size-chart-modal.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "./size-chart-modal.page";
var styles_SizeChartModalPage = [i0.styles];
var RenderType_SizeChartModalPage = i1.ɵcrt({ encapsulation: 0, styles: styles_SizeChartModalPage, data: {} });
export { RenderType_SizeChartModalPage as RenderType_SizeChartModalPage };
export function View_SizeChartModalPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "ion-content", [], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(1, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 5, "ion-fab", [["horizontal", "center"], ["slot", "fixed"], ["vertical", "top"]], null, null, null, i2.View_IonFab_0, i2.RenderType_IonFab)), i1.ɵdid(3, 49152, null, 0, i3.IonFab, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { horizontal: [0, "horizontal"], vertical: [1, "vertical"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 3, "ion-fab-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonFabButton_0, i2.RenderType_IonFabButton)), i1.ɵdid(5, 49152, null, 0, i3.IonFabButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "ion-icon", [["name", "close"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(7, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(8, 0, null, 0, 4, "div", [["class", "image-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "btn-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "img-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "ion-img", [], null, null, null, i2.View_IonImg_0, i2.RenderType_IonImg)), i1.ɵdid(12, 49152, null, 0, i3.IonImg, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { src: [0, "src"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "center"; var currVal_1 = "top"; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = "close"; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.image; _ck(_v, 12, 0, currVal_3); }, null); }
export function View_SizeChartModalPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-size-chart-modal", [], null, null, null, View_SizeChartModalPage_0, RenderType_SizeChartModalPage)), i1.ɵdid(1, 114688, null, 0, i4.SizeChartModalPage, [i3.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SizeChartModalPageNgFactory = i1.ɵccf("app-size-chart-modal", i4.SizeChartModalPage, View_SizeChartModalPage_Host_0, { image: "image" }, {}, []);
export { SizeChartModalPageNgFactory as SizeChartModalPageNgFactory };
