import { Component, OnInit,Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-size-chart-modal',
  templateUrl: './size-chart-modal.page.html',
  styleUrls: ['./size-chart-modal.page.scss'],
})
export class SizeChartModalPage implements OnInit {
  @Input() image: number;
  constructor(private modalController: ModalController) { }

  ngOnInit() {
  }
  closeModal(){
    this.modalController.dismiss();
  }

}
