
  <ion-grid class="ion-no-padding" id="grid-content">
    <ion-row>
    <ion-col size="12">
      <div class="input-wrap">
        <ion-label>{{'RATING_CO.rate_product_ques' | translate:{'type': type} }}</ion-label>
        <ionic4-star-rating activeIcon="ios-star" defaultIcon="ios-star-outline" [activeColor]="getStarColor()"
          defaultColor="#e1e1e1" readonly="false"  [rating]="ratingData.rating" fontSize="32px" (ratingChanged)="logRatingChange($event)">
        </ionic4-star-rating>
      </div>
    </ion-col>

    <ion-col size="12">
      <div class="input-wrap">
        <ion-label>
          <strong>{{'RATING_CO.add_written_review' | translate}} : </strong>{{'RATING_CO.written_review_ques' | translate:{'type': type} }}
        </ion-label>
        <ion-textarea class="form-input" rows="5" [placeholder]="reviewPh" [(ngModel)]="ratingData.review"></ion-textarea>
      </div>
    </ion-col>

  

    <ion-col size="12">
      <div class="input-wrap">
        <div class="flex-label">
        <div><ion-label>
          <strong>{{'RATING_CO.add_photo' | translate}} : </strong>
          {{'RATING_CO.max_photos' | translate:{'type': type} }}
        </ion-label></div>
        <div class="upload-btn-wrapper" *ngIf="ratingData.photos.length < 3">
          <button class="upload-btn btn-1 i-start"> <i class="flaticon-null-16"></i>upload</button>
          <input type="file" name="myfile" (change)="uploadImage($event.target.files,'catImg')" />
        </div>
      </div>
      <div class="image-wrapper">
        <div class="img-wrap" *ngFor="let img of ratingData.photos; let i=index;" >
          <img [src]="img.url">
          <span class="remove-btn" shape="round" color="danger"  (click)="removeImage(i)">
            <ion-icon name="trash" slot="icon-only"></ion-icon>
          </span>
        </div>
      </div>
      </div>
    </ion-col>


    
    <ion-col size="12" *ngIf="type==='product'">
      <div class="input-wrap">
        <ion-label>
          <strong>{{'RATING_CO.public_name' | translate}} : </strong>{{'RATING_CO.display_to_others' | translate}}
        </ion-label>
        <ion-input class="form-input" [(ngModel)]="ratingData.userName"></ion-input>
      </div>
    </ion-col>
  </ion-row>

  </ion-grid>


<div class="ion-text-center">
  <ion-button  class="btn-2" (click)="callParentRate()" shape="round">
    {{'SHARED.submit' | translate}}
  </ion-button>
</div>
  