import * as tslib_1 from "tslib";
import { UserService } from 'src/app/services/user/user.service';
import { Storage } from '@ionic/storage';
import { first, map } from 'rxjs/operators';
import { Events } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { SharedService } from '../shared/shared.service';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "@angular/fire/storage";
import * as i4 from "@ionic/storage";
import * as i5 from "../user/user.service";
import * as i6 from "../shared/shared.service";
var ServicesFeatureService = /** @class */ (function () {
    function ServicesFeatureService(events, afs, fbStorage, storage, userService, sharedService) {
        this.events = events;
        this.afs = afs;
        this.fbStorage = fbStorage;
        this.storage = storage;
        this.userService = userService;
        this.sharedService = sharedService;
        this.serviceRef = this.afs.collection('services');
    }
    ServicesFeatureService.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('services-feature:getAllServices', function () {
            _this.getAllServices();
        });
        this.events.subscribe('services-feature:getAllServicesForHome', function () {
            _this.getAllServicesForHome();
        });
        this.events.subscribe('services-feature:getServicesActiveStatus', function () {
            _this.getServicesActiveStatus();
        });
        this.events.subscribe('services-feature:changeServiceStatus', function (status) {
            _this.changeServiceStatus(status);
        });
        this.events.subscribe('services-feature:deleteService', function (sid) {
            _this.deleteService(sid);
        });
        this.events.subscribe('services-feature:saveService', function (data, banner, sid) {
            _this.saveService(data, banner, sid);
        });
        this.events.subscribe('services-feature:submitResponse', function (res, imgs) {
            _this.submitResponse(res, imgs);
        });
        this.events.subscribe('services-feature:getUserRequests', function () {
            _this.getUserRequests();
        });
        this.events.subscribe('services-feature:completeRequest', function (rid) {
            _this.completeRequest(rid);
        });
        this.events.subscribe('services-feature:getAllRequests', function () {
            _this.getAllRequests();
        });
        this.setServicesInLocalStorage();
    };
    ServicesFeatureService.prototype.getServiceWithId = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var serviceData;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this.afs.collection('services').doc(id).valueChanges().pipe(first()).toPromise()];
                                case 1:
                                    serviceData = _a.sent();
                                    resolve(serviceData);
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    ServicesFeatureService.prototype.getAllServices = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var services, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('services', function (ref) { return ref
                                .orderBy('createdAt', 'desc'); }).snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 1:
                        services = _a.sent();
                        this.events.publish('services-feature:publishAllServices', services);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.dir(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ServicesFeatureService.prototype.getAllServicesForHome = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var region, regionId_1, services, servicesRef, error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.sharedService.checkRegionIdForApi()];
                    case 1:
                        region = _a.sent();
                        regionId_1 = region.regionId;
                        services = void 0;
                        servicesRef = void 0;
                        if (regionId_1) {
                            servicesRef = this.afs.collection('services', function (ref) { return ref
                                .orderBy('createdAt', 'desc')
                                .where('regionId', 'array-contains', regionId_1); });
                        }
                        else {
                            servicesRef = this.afs.collection('services', function (ref) { return ref
                                .orderBy('createdAt', 'desc'); });
                        }
                        return [4 /*yield*/, servicesRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 2:
                        services = _a.sent();
                        this.events.publish('services-feature:publishAllServicesForHome', services);
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _a.sent();
                        console.dir(error_2);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ServicesFeatureService.prototype.getServicesActiveStatus = function (route) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var status_1, error_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('features').doc('services').valueChanges().pipe(first()).toPromise()];
                    case 1:
                        status_1 = _a.sent();
                        if (!route) {
                            this.events.publish('services-feature:publishServicesActiveStatus', status_1);
                        }
                        else {
                            return [2 /*return*/, status_1];
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_3 = _a.sent();
                        console.dir(error_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ServicesFeatureService.prototype.changeServiceStatus = function (status) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('features').doc('services').set({ isActive: status })];
                    case 1:
                        _a.sent();
                        this.events.publish('services-feature:statusChangeSuccess');
                        return [3 /*break*/, 3];
                    case 2:
                        error_4 = _a.sent();
                        console.dir(error_4);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ServicesFeatureService.prototype.deleteService = function (sid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.serviceRef.doc(sid).delete()];
                    case 1:
                        _a.sent();
                        this.events.publish('services-feature:getAllServices');
                        this.events.publish('services-feature:deleteServiceSuccess');
                        return [3 /*break*/, 3];
                    case 2:
                        error_5 = _a.sent();
                        console.dir(error_5);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ServicesFeatureService.prototype.saveService = function (data, banner, sid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var baseImg_1, storageImg_1, imgRef, downloadURL, error_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        baseImg_1 = {};
                        storageImg_1 = {};
                        if (!sid) {
                            sid = this.afs.collection('services').ref.doc().id;
                        }
                        if (banner.length) {
                            banner.forEach(function (i) {
                                if (i.url.includes('data:image/jpeg;base64,')) {
                                    baseImg_1 = i;
                                }
                                else {
                                    storageImg_1 = i;
                                }
                            });
                        }
                        return [4 /*yield*/, this.afs.collection('services').doc(sid).set({
                                name: data.name,
                                description: data.description,
                                imageMandatory: data.imageMandatory,
                                createdAt: data.createdAt,
                                banner: storageImg_1,
                            })];
                    case 1:
                        _a.sent();
                        if (!baseImg_1.hasOwnProperty('url')) return [3 /*break*/, 5];
                        imgRef = this.fbStorage.ref("services/" + sid + "/banner/" + new Date().getTime().toString() + '.png');
                        return [4 /*yield*/, imgRef.putString(baseImg_1.url, 'data_url')];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, imgRef.getDownloadURL().pipe(first()).toPromise()];
                    case 3:
                        downloadURL = _a.sent();
                        return [4 /*yield*/, this.afs.collection('services').doc(sid).update({ banner: { url: downloadURL } })];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        this.events.publish('services-feature:saveServiceSuccess');
                        this.getAllServices();
                        return [3 /*break*/, 7];
                    case 6:
                        error_6 = _a.sent();
                        console.dir(error_6);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    ServicesFeatureService.prototype.submitResponse = function (res, imgs) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sid, _i, imgs_1, img, imgRef, error_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        res['userName'] = this.userService.getUserName();
                        res['userPhone'] = this.userService.getPhoneNo();
                        res['userId'] = this.userService.getUserId();
                        sid = this.afs.collection('serviceRequests').ref.doc().id;
                        return [4 /*yield*/, this.afs.collection('serviceRequests').doc(sid).set(res)];
                    case 1:
                        _a.sent();
                        if (!imgs.length) return [3 /*break*/, 5];
                        _i = 0, imgs_1 = imgs;
                        _a.label = 2;
                    case 2:
                        if (!(_i < imgs_1.length)) return [3 /*break*/, 5];
                        img = imgs_1[_i];
                        imgRef = this.fbStorage.ref("serviceRequests/" + sid + "/images/" + new Date().getTime().toString() + '.png');
                        return [4 /*yield*/, imgRef.putString(img.url, 'data_url')];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5:
                        this.events.publish('services-feature:submitResponseSuccess');
                        this.getUserRequests();
                        return [3 /*break*/, 7];
                    case 6:
                        error_7 = _a.sent();
                        console.dir(error_7);
                        this.events.publish('services-feature:submitResponseFailure');
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    ServicesFeatureService.prototype.getUserRequests = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var uid, requests;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getStorageUid()];
                    case 1:
                        uid = _a.sent();
                        return [4 /*yield*/, this.afs.collection('serviceRequests', function (ref) { return ref
                                .orderBy('responseAt', 'desc')
                                .where('userId', '==', uid); })
                                .snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 2:
                        requests = _a.sent();
                        this.events.publish('services-feature:publishUserRequests', requests);
                        return [2 /*return*/];
                }
            });
        });
    };
    ServicesFeatureService.prototype.getStorageUid = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            this.storage.get('uid').then(function (val) {
                                resolve(val);
                            });
                            return [2 /*return*/];
                        });
                    }); })];
            });
        });
    };
    ServicesFeatureService.prototype.getAllRequests = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requests;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.afs.collection('serviceRequests', function (ref) { return ref
                            .orderBy('responseAt', 'desc'); })
                            .snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                            var data = a.payload.doc.data();
                            var id = a.payload.doc.id;
                            return tslib_1.__assign({ id: id }, data);
                        }); })).pipe(first()).toPromise()];
                    case 1:
                        requests = _a.sent();
                        this.events.publish('services-feature:publishAllRequests', requests);
                        return [2 /*return*/];
                }
            });
        });
    };
    ServicesFeatureService.prototype.completeRequest = function (rid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_8;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('serviceRequests').doc(rid).update({ status: 'Completed' })];
                    case 1:
                        _a.sent();
                        this.events.publish('services-feature:completeRequestSuccess');
                        return [3 /*break*/, 3];
                    case 2:
                        error_8 = _a.sent();
                        console.dir(error_8);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ServicesFeatureService.prototype.getServicesForMenu = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var storageServices, services, error_9;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        storageServices = JSON.parse(localStorage.getItem('services')) || [];
                        if (!!storageServices.length) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.setServicesInLocalStorage()];
                    case 1:
                        services = _a.sent();
                        return [2 /*return*/, services];
                    case 2: return [2 /*return*/, storageServices];
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        error_9 = _a.sent();
                        console.dir(error_9);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ServicesFeatureService.prototype.setServicesInLocalStorage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var region, regionId, services, servicesRef;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this.sharedService.checkRegionIdForApi()];
                                case 1:
                                    region = _a.sent();
                                    regionId = region.regionId;
                                    if (regionId) {
                                        servicesRef = this.afs.collection('services', function (ref) { return ref
                                            .orderBy('createdAt', 'desc')
                                            .where('regionId', 'array-contains', regionId); });
                                    }
                                    else {
                                        servicesRef = this.afs.collection('services', function (ref) { return ref
                                            .orderBy('createdAt', 'desc'); });
                                    }
                                    return [4 /*yield*/, servicesRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                            var data = a.payload.doc.data();
                                            var id = a.payload.doc.id;
                                            return tslib_1.__assign({ id: id }, data);
                                        }); })).pipe(first()).toPromise()];
                                case 2:
                                    services = _a.sent();
                                    localStorage.setItem('services', JSON.stringify(services));
                                    resolve(services);
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    ServicesFeatureService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ServicesFeatureService_Factory() { return new ServicesFeatureService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.AngularFireStorage), i0.ɵɵinject(i4.Storage), i0.ɵɵinject(i5.UserService), i0.ɵɵinject(i6.SharedService)); }, token: ServicesFeatureService, providedIn: "root" });
    return ServicesFeatureService;
}());
export { ServicesFeatureService };
