import * as tslib_1 from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import { Events } from '@ionic/angular';
import { first } from 'rxjs/operators';
import { LogglyLoggerService } from '../loggly-logger/loggly-logger.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "@ionic/angular";
import * as i3 from "../loggly-logger/loggly-logger.service";
var MembershipService = /** @class */ (function () {
    function MembershipService(angularFirestore, events, logglyService) {
        this.angularFirestore = angularFirestore;
        this.events = events;
        this.logglyService = logglyService;
        this.membershipRef = this.angularFirestore.collection('features').doc('membership');
    }
    MembershipService.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('membership:getMembershipSettings', function () {
            _this.getMembershipSettings();
        });
    };
    MembershipService.prototype.getMembershipSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var settings, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.membershipRef.valueChanges().pipe(first()).toPromise()];
                    case 1:
                        settings = _a.sent();
                        this.events.publish('membership:membershipSettings', settings);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.dir(error_1);
                        error_1['location'] = 'membership-service:getMembershipSettings';
                        this.logglyService.log(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MembershipService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MembershipService_Factory() { return new MembershipService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.Events), i0.ɵɵinject(i3.LogglyLoggerService)); }, token: MembershipService, providedIn: "root" });
    return MembershipService;
}());
export { MembershipService };
