import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ModalController, Platform } from '@ionic/angular';
import { HomePage } from 'src/app/home/home.page';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
loading:any;
  constructor(private modalController: ModalController,
    private platform: Platform,
    private loadingController: LoadingController,
    private alertController: AlertController) { }

  async openLoginModal() {
    const modal = await this.modalController.create({
    component: HomePage,
    backdropDismiss:false,
    cssClass: "custom-modal login-side-modal",
    });
  
    modal.onDidDismiss().then(() => {
    });
    await modal.present();
  }

  consoleLog(key:any, log:any){
     // // console.log(key, log)
  }

  getDeviceWidth(){
    return this.platform.width();
  }

  async presentAlert(msg) {
    const alert = await this.alertController.create({
      message: msg,
      buttons: ['OK']
    });
  
    await alert.present();
  }

  async presentLoading(msg) {
    this.loading = await this.loadingController.create({
      message: msg,
      duration: 9000,
    });
    await this.loading.present();
  }

}
