import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { OrderService } from 'src/app/services/order/order.service';
import { LoadingController, ToastController, ModalController } from '@ionic/angular';
import { LabelService } from 'src/app/services/label/label.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { ConfigService } from 'src/app/services/config/config.service';
var PhonepePage = /** @class */ (function () {
    function PhonepePage(orderService, modalController, loadingController, labelService, toastController, sharedService, configService) {
        this.orderService = orderService;
        this.modalController = modalController;
        this.loadingController = loadingController;
        this.labelService = labelService;
        this.toastController = toastController;
        this.sharedService = sharedService;
        this.configService = configService;
        this.SHARED_LABELS = {};
    }
    PhonepePage.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () { return tslib_1.__generator(this, function (_a) {
            return [2 /*return*/];
        }); });
    };
    PhonepePage.prototype.ionViewWillEnter = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.SHARED_LABELS = this.labelService.labels['SHARED'];
                        return [4 /*yield*/, this.getCheckoutUrl()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PhonepePage.prototype.presentLoading = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loadingController.create({
                                message: this.SHARED_LABELS['please_wait'],
                            })];
                    case 1:
                        _a.loading = _b.sent();
                        return [4 /*yield*/, this.loading.present()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PhonepePage.prototype.presentToast = function (msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toast;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.toastController.create({
                            message: msg,
                            duration: 2000,
                        })];
                    case 1:
                        toast = _a.sent();
                        toast.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    PhonepePage.prototype.closeModal = function () {
        this.modalController.dismiss();
    };
    PhonepePage.prototype.getCheckoutUrl = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderRes, paymentUrl;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('Attempting to get checkout url!!!');
                        return [4 /*yield*/, this.orderService.paymentGatewayProcessor(this.orderData)];
                    case 1:
                        orderRes = _a.sent();
                        console.log("orderRes: ", orderRes);
                        this.orderData['orderDocId'] = orderRes.orderDocId;
                        return [4 /*yield*/, this.orderService.getPhonepeCheckoutUrl(this.orderData)];
                    case 2:
                        paymentUrl = _a.sent();
                        if (paymentUrl) {
                            window.open(paymentUrl, "_self");
                        }
                        else {
                            this.orderService.presentFailureAlert();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    PhonepePage.prototype.timeDelay = function (time) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, rejects) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            setTimeout(function () {
                                resolve(true);
                            }, time * 1000);
                            return [2 /*return*/];
                        });
                    }); })];
            });
        });
    };
    return PhonepePage;
}());
export { PhonepePage };
