<ion-header class="modal-header">
  <ion-toolbar>
    <ion-title class="modal-header-title">{{'APPOINTMENT_SCHEDULE.header_text' | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ng-container *ngIf="scheduleSlots.length; else noSlots;">
    <div>
      <p class="choose-txt">{{'APPOINTMENT_SCHEDULE.choose_slot' | translate}}</p>
      <div class="scrolling-wrapper">
        <div *ngFor="let slot of scheduleSlots; let i = index;" class="slot-date" [ngClass]="{'slot-active': slot.active}" (click)="selectDate(i)">
          <div class="f-w-b">{{slot.day}}</div>
          <div>{{getDate(slot.date)}}</div>
          <div class="arrow-up" *ngIf="slot.active"></div>
        </div>
      </div>

      <div class="time-schedules">
        <ion-list>
          <ion-radio-group (ionChange)="setTimeSlot($event)">
            <ion-item *ngFor="let timeSlot of getActiveTimeSchedules()" lines="none">
              <ion-label>
                <ion-text>
                  {{timeSlot.start}} to {{timeSlot.end}}
                </ion-text>
                <ion-text *ngIf="!timeSlot.slotLimit">
                  {{'APPOINTMENT_SCHEDULE.slot_full' | translate}}
                </ion-text>
              </ion-label>
              <ion-radio slot="start" [value]="timeSlot" [disabled]="!timeSlot.slotLimit"></ion-radio>
            </ion-item>
          </ion-radio-group>
        </ion-list>
      </div>
    </div>

  </ng-container>

  <ng-template #noSlots>
    <p class="no-data-txt">{{'APPOINTMENT_SCHEDULE.no_slots' | translate}}</p>
  </ng-template>

</ion-content>

<ion-footer *ngIf="scheduleSlots.length">
  <ion-button (click)="bookAppointment()" expand="block" class="footer-button" [disabled]="isBookNowDisabled()">
    {{'APPOINTMENT_SCHEDULE.book_now' | translate}}
  </ion-button>
</ion-footer>
