<ion-content class="coup-list-content">

  <div class="coup-list-header">
    <p>{{'COUPONS_LIST.header_text' | translate}}</p>
    <ion-icon name="close" (click)="closeModal()"></ion-icon>
  </div>

  <div class="enter-code">
    <ion-item lines="none">
      <ion-input type="text" placeholder="{{'COUPONS_LIST.enter_coupon_ph' | translate}}" [(ngModel)]="couponName"
        (ngModelChange)="couponName = $event.toLocaleUpperCase()"></ion-input>
      <ion-button (click)="applyCoupon()" fill="clear" slot="end" [disabled]="!couponName">
        {{'COUPONS_LIST.apply' | translate}}
      </ion-button>
    </ion-item>
  </div>

  <ng-container *ngIf="isLoading; else couponsLoaded;">
    <div class="spinner">
      <ion-spinner name="dots"></ion-spinner>
    </div>
  </ng-container>

  <ng-template #couponsLoaded>
    <ng-container *ngIf="!coupons.length; else couponsAvailable;">
      <p class="no-data-txt">{{'COUPONS_LIST.no_coupons_available' | translate}}</p>
    </ng-container>

    <ng-template #couponsAvailable>
      <div class="coupons-wrapper">
        <div class="coupon" *ngFor="let coupon of coupons; let i=index;">
          <div class="coupon-main">
            <div class="coupon-name">
              {{coupon.name}}
            </div>
            <ion-button (click)="applyCoupon(coupon.name)" fill="clear" slot="end">
              {{'COUPONS_LIST.apply' | translate}}
            </ion-button>
          </div>
  
          <div class="coupon-info">
            <h6 class="coupon-info-main" *ngIf="coupon.type === 'flat'">
              {{'COUPONS_LIST.flat_main_text' | translate:{'discount': coupon.amount | currency: currencyCode:true:'.2-2'} }}
            </h6>
            <h6 class="coupon-info-main" *ngIf="coupon.type === 'percentage'">
              {{'COUPONS_LIST.perc_main_text' | translate:{'discount': coupon.amount, 'maxDiscount': coupon.maxDiscount | currency: currencyCode:true:'.2-2'} }}
            </h6>
            
            <div class="coupon-info-tnc">
              <h6>{{'COUPONS_LIST.tnc' | translate}}</h6>
              <ul>
                <li>{{'COUPONS_LIST.tnc_min_order_amount' | translate:{'amount': coupon.minOrderAmount | currency: currencyCode:true:'.2-2'} }}</li>
                <li *ngIf="coupon.maxDiscount">{{'COUPONS_LIST.tnc_max_discount' | translate:{'amount': coupon.maxDiscount | currency: currencyCode:true:'.2-2'} }}</li>
                <li>{{'COUPONS_LIST.tnc_per_user' | translate:{'count': coupon.perUser} }}</li>
                <li>{{'COUPONS_LIST.tnc_valid_till' | translate:{'date': coupon.validUpto | date:'dd MMMM yyyy'} }}</li>
                <li *ngIf="!coupon.codAvailable">{{'COUPONS_LIST.tnc_cod_unavailable' | translate }}</li>
                <li *ngIf="coupon.specificUsers.isAllowed">{{'COUPONS_LIST.tnc_coupon_valid_for_specific_users' | translate }}</li>
              </ul>
            </div>

            <div class="coupon-info-desc" *ngIf="coupon.description">
              <h6>{{'COUPONS_LIST.description' | translate}}:</h6>
              <p>
                {{coupon.description}}
              </p>
            </div>
          </div>

          <hr class="line" *ngIf="i !== (coupons.length - 1)">
        </div>
      </div>
    </ng-template>

  </ng-template>

</ion-content>