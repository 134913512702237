import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ConfigService } from 'src/app/services/config/config.service';
import { CouponCodesService } from 'src/app/services/coupon-codes/coupon-codes.service';
import { LabelService } from 'src/app/services/label/label.service';
var CouponsListPage = /** @class */ (function () {
    function CouponsListPage(modalController, labelService, couponCodesService, configService) {
        this.modalController = modalController;
        this.labelService = labelService;
        this.couponCodesService = couponCodesService;
        this.configService = configService;
        this.couponName = '';
        this.isLoading = true;
        this.coupons = [];
    }
    CouponsListPage.prototype.ngOnInit = function () {
        this.COUPONS_LIST_LABELS = this.labelService.labels['COUPONS_LIST'];
        this.currencyCode = this.configService.environment.currencyCode;
    };
    CouponsListPage.prototype.ionViewWillEnter = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.couponCodesService.getCouponCodesForUser()];
                    case 1:
                        _a.coupons = (_b.sent()) || [];
                        this.isLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    CouponsListPage.prototype.closeModal = function () {
        this.modalController.dismiss();
    };
    CouponsListPage.prototype.applyCoupon = function (coupon) {
        this.modalController.dismiss({ couponName: (coupon || this.couponName).toUpperCase() });
    };
    return CouponsListPage;
}());
export { CouponsListPage };
