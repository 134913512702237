<ion-header class="templates-header">
  <ion-toolbar>
    <ion-title class="templates-header-title">{{'STATES_MODAL.header_text' | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="ion-text-center">
    <ion-searchbar mode="ios" placeholder="{{'STATES_MODAL.search_any_state' | translate}}" inputmode="text" [(ngModel)]="searchState"></ion-searchbar>
  </div>

  <div class="spinner" *ngIf="showLoader">
    <ion-spinner name="dots"></ion-spinner>
  </div>

  <ion-list lines="none" *ngIf="!showLoader">
    <ion-item *ngFor="let s of states | filter: searchState;" (click)="onClickState(s)">
      <ion-label>{{s.state}}</ion-label>
      <ion-radio value="s"></ion-radio>
    </ion-item>
  </ion-list>

</ion-content>
