import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController, IonSlides } from '@ionic/angular';
import { UtilsService } from '../services/utils/utils.service';
var ImageModalPage = /** @class */ (function () {
    function ImageModalPage(modalController, utilsService) {
        this.modalController = modalController;
        this.utilsService = utilsService;
        this.showLoader = true;
        this.isFirstSlide = true;
        this.isLastSlide = false;
        this.sliderOpts = {
            initialSlide: 0,
            zoom: {
                maxRatio: 3
            },
            spaceBetween: 10,
            preloadImages: false,
            lazy: true,
            fadeEffect: {
                crossFade: true
            },
        };
        this.spliced = false;
        this.loadingUrl = 'assets/img/img-modal-loading.gif';
        this.imgZoom = false;
    }
    ImageModalPage.prototype.slideChanged = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.modalslider.isBeginning()];
                    case 1:
                        _a.isFirstSlide = _c.sent();
                        _b = this;
                        return [4 /*yield*/, this.modalslider.isEnd()];
                    case 2:
                        _b.isLastSlide = _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ImageModalPage.prototype.nextSlide = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.modalslider.slideNext();
                return [2 /*return*/];
            });
        });
    };
    ImageModalPage.prototype.prevSlide = function () {
        this.modalslider.slidePrev();
    };
    ImageModalPage.prototype.ngOnDestroy = function () {
        if (window.history.state.modal) {
            history.back();
        }
    };
    ImageModalPage.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modalState;
            return tslib_1.__generator(this, function (_a) {
                this.utilsService.consoleLog('modal images', this.imgs);
                this.sliderOpts.initialSlide = this.index;
                this.showLoader = false;
                modalState = {
                    modal: true,
                    desc: 'State for the modal for correct back button functionality'
                };
                history.pushState(modalState, null);
                return [2 /*return*/];
            });
        });
    };
    ImageModalPage.prototype.spliceFirstImg = function () {
        if (this.spliced === false) {
            this.imgs.splice(0, 1);
            this.spliced = true;
        }
    };
    ImageModalPage.prototype.close = function () {
        this.modalController.dismiss();
    };
    // async zoom() {
    //   this.imgZoom = !this.imgZoom;
    //   const slider = await this.modalslider.getSwiper();
    //   const zoom = slider.zoom;
    //   this.imgZoom ? zoom.in() : zoom.out();
    // }
    ImageModalPage.prototype.zoom = function (zoomIn) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var slider, zoom;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalslider.getSwiper()];
                    case 1:
                        slider = _a.sent();
                        zoom = slider.zoom;
                        zoomIn ? zoom.in() : zoom.out();
                        return [2 /*return*/];
                }
            });
        });
    };
    ImageModalPage.prototype.dismissModal = function () {
        this.modalController.dismiss();
    };
    return ImageModalPage;
}());
export { ImageModalPage };
