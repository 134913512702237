import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-section-title',
  templateUrl: './section-title.component.html',
  styleUrls: ['./section-title.component.scss'],
})
export class SectionTitleComponent implements OnInit {
  @Input() title: any;
  @Input() subTitle: any;
  @Input() titleStyle: any;

  constructor() { }

  ngOnInit() {
    // // console.log('section title style', this.titleStyle);
    // // console.log('section title style', this.title);

    
  }

}
