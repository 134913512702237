
<ion-content>
  <ion-button class="close-button" (click)=" modalDismiss()">
    <ion-icon name="close-circle" slot="icon-only"></ion-icon>
  </ion-button>
 
  <ion-slides class="offer-slider" pager="true" [options]="offerSlideOpts" loop="true" *ngIf="offerData.images && offerData.images.length">
    <ion-slide *ngFor="let img of offerData.images; let i = index;">
      <img [src]="img.mob" [alt]="offerData.name">
      </ion-slide>
  </ion-slides>


  <div class="offer-container">
    <div class="section-title m-b-16">
      <!--<p>Some Text Here</p>-->
       <h3>{{offerData.name}}</h3>
       <span class="line"></span>
     </div>
     <div [innerHtml]="offerData.description" *ngIf="offerData.description"></div>
  </div>
</ion-content>

<ion-footer class="get-offer-btn" no-border *ngIf="offerData.hasOwnProperty('link') && offerData.link.link?.type !== 'None'">
  <ion-button (click)="goToOffer(offerData.link)" fill="outline" shape="round">
    Get this offer
  </ion-button>
</ion-footer>
