import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { LabelService } from '../services/label/label.service';
import { SharedService } from '../services/shared/shared.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { WidgetsService } from '../services/widgets/widgets.service';
import { encodeURL } from '../services/parseURL';

@Component({
    selector: 'app-promo-modal',
    templateUrl: './promo-modal.page.html',
    styleUrls: ['./promo-modal.page.scss'],
})
export class PromoModalPage implements OnInit {
    @ViewChild('imageBox', {
        static: false
    }) imageBox;

    popup: any;
    PROMO_POPUP_LABELS: any;
    CO_CATEGORY_GRID_LABELS: any;
    constructor(
        private router: Router,
        private utilsService: UtilsService,
        private modalController: ModalController,
        private labelService: LabelService,
        private ngZone: NgZone,
        private sharedService: SharedService,
        private widgetService: WidgetsService
    ) { }

    ngOnInit() {
        // // console.log(this.popup);
        this.PROMO_POPUP_LABELS = this.labelService.labels['PROMO_POPUP'];
        this.CO_CATEGORY_GRID_LABELS = this.labelService.labels['CO_CATEGORY_GRID'];
    }

    onClickImgDocument(event) {
        // console.log('onClickImgDocument')
        if (!this.imageBox.nativeElement.contains(event.target)) { // or some similar check
            this.ngZone.run(() => {
                this.closePopup();
            });
        }
    }

    closePopup() {
        this.modalController.dismiss();
    }


    async onClickPopup() {
        this.closePopup();
        this.widgetService.bannerLink(this.popup);
    }

}
