import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { LabelService } from 'src/app/services/label/label.service';
import { SharedService } from 'src/app/services/shared/shared.service';

import { ImageModalPage } from '../../image-modal/image-modal.page';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
})
export class RatingComponent implements OnInit {
  ratingData: any = {
    rating: 5,
    review: '',
    photos: [],
    userName: ''
  }
  reviewPh: any;
  RATING_CO_LABELS: any;
  SHARED_LABELS: any;

  @Output() rateEvent = new EventEmitter();
  @Input() type: string;
  @Input() stars: number;
  constructor(private sharedService: SharedService,
              private labelService: LabelService,
             
              private modalController: ModalController,
              private actionSheetController: ActionSheetController,
              private userService: UserService) { }

  ngOnInit() {
    this.SHARED_LABELS = this.labelService.labels['SHARED'];
    this.RATING_CO_LABELS = this.labelService.labels['RATING_CO'];
    this.reviewPh = this.RATING_CO_LABELS['review_placeholder'];
    this.ratingData.userName = this.userService.getUserName();
    if(this.stars) {
      this.ratingData.rating = this.stars;
    }
  }

  logRatingChange(rating){
    this.ratingData.rating = rating;
  }

  getStarColor() {
    return this.sharedService.getStarColor(this.ratingData.rating);
  }

  imgZoom(img: any) {
    this.modalController.create({
      component: ImageModalPage,
      cssClass: 'photo-modal-class',
      componentProps: {
        imgs: [{url: img}],
        index: 0
      }
    }).then(modal => modal.present());
  }

  removeImage(i) {
    this.ratingData.photos.splice(i, 1);
  }


  uploadImage(files: FileList, type) {
    // // console.log(type);
    for (let i = 0; i < files.length; i++) {
      let reader = new FileReader();
      reader.readAsDataURL(files.item(i))
      reader.onload = (event:any) => { // called once readAsDataURL is completed
        let base64Image:any = event.target.result;
        let base64Str = base64Image.split(',');
        this.ratingData.photos.push({url: base64Image});
        
      }
    }
  }

  callParentRate() {
    this.rateEvent.emit(this.ratingData);
  }

}
