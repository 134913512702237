<ion-header class="templates-header">
  <ion-toolbar>
    <ion-title class="templates-header-title">{{'CANCELLED_REASON.header_text' | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

  <div class="data-field">

    <div class="data-field-txt">
      {{'CANCELLED_REASON.provide_reason' | translate}}
    </div>

    <ion-textarea class="m-t-10" rows="4" placeholder="{{'CANCELLED_REASON.enter_reason' | translate}}" [(ngModel)]="reason">
    </ion-textarea>
  </div>
</ion-content>

<ion-footer>
  <ion-button (click)="submit()" expand="block" class="footer-button">
    {{'CANCELLED_REASON.submit_btn' | translate}}
  </ion-button>
</ion-footer>
