import * as tslib_1 from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import { Events } from '@ionic/angular';
import { first, map } from 'rxjs/operators';
import { LogglyLoggerService } from '../loggly-logger/loggly-logger.service';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "../loggly-logger/loggly-logger.service";
var LanguageService = /** @class */ (function () {
    function LanguageService(events, afs, logglyService) {
        this.events = events;
        this.afs = afs;
        this.logglyService = logglyService;
    }
    LanguageService.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('language:getAvaialbleLanguages', function () {
            _this.getAvaialbleLanguages();
        });
        this.events.subscribe('language:addLanguages', function (langs) {
            _this.addLanguages(langs);
        });
        this.events.subscribe('language:getAddedLanguages', function () {
            _this.getAddedLanguages();
        });
        this.events.subscribe('language:makeDefault', function (id, languages) {
            _this.makeDefault(id, languages);
        });
        this.events.subscribe('language:deleteLang', function (id, languages) {
            _this.deleteLang(id, languages);
        });
    };
    LanguageService.prototype.getAvaialbleLanguages = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var langs, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('languages').snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 1:
                        langs = _a.sent();
                        // console.log('langs', langs);
                        this.events.publish('language:publishAvaialbleLanguages', langs);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.dir(error_1);
                        error_1['location'] = 'language-service:getAvaialbleLanguages';
                        this.logglyService.log(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    LanguageService.prototype.addLanguages = function (langs) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _i, langs_1, l, error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        _i = 0, langs_1 = langs;
                        _a.label = 1;
                    case 1:
                        if (!(_i < langs_1.length)) return [3 /*break*/, 4];
                        l = langs_1[_i];
                        return [4 /*yield*/, this.afs.collection('languages').doc(l.id).update({
                                isAdded: l.isAdded
                            })];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        this.events.publish('language:addLanguagesSuccess');
                        this.events.publish('language:getAddedLanguages');
                        return [3 /*break*/, 6];
                    case 5:
                        error_2 = _a.sent();
                        console.dir(error_2);
                        error_2['location'] = 'language-service:addLanguages';
                        this.logglyService.log(error_2);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    LanguageService.prototype.getAddedLanguages = function (route) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var langs, error_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('languages', function (ref) { return ref
                                .where('isAdded', '==', true)
                                .orderBy('sortedAt', 'asc'); }).snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 1:
                        langs = _a.sent();
                        // console.log('langs', langs);
                        if (!route) {
                            this.events.publish('language:publishAddedLanguages', langs);
                        }
                        else {
                            return [2 /*return*/, langs];
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_3 = _a.sent();
                        console.dir(error_3);
                        error_3['location'] = 'language-service:getAddedLanguages';
                        this.logglyService.log(error_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    LanguageService.prototype.updateLangPosition = function (id, changedDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        // console.log('id & cdate', id, changedDate);
                        return [4 /*yield*/, this.afs.doc("languages/" + id).update({ sortedAt: changedDate })];
                    case 1:
                        // console.log('id & cdate', id, changedDate);
                        _a.sent();
                        this.events.publish('language:updateLangPostionSucess');
                        return [3 /*break*/, 3];
                    case 2:
                        error_4 = _a.sent();
                        error_4['location'] = 'language-service:updateLangPosition';
                        this.logglyService.log(error_4);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    LanguageService.prototype.makeDefault = function (id, languages) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _i, languages_1, l, error_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 7, , 8]);
                        _i = 0, languages_1 = languages;
                        _a.label = 1;
                    case 1:
                        if (!(_i < languages_1.length)) return [3 /*break*/, 6];
                        l = languages_1[_i];
                        if (!(l.id === id)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.afs.doc("languages/" + l.id).update({ isDefault: true })];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.afs.doc("languages/" + l.id).update({ isDefault: false })];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        _i++;
                        return [3 /*break*/, 1];
                    case 6:
                        this.events.publish('language:makeDefaultSuccess', id);
                        this.events.publish('language:getAddedLanguages');
                        return [3 /*break*/, 8];
                    case 7:
                        error_5 = _a.sent();
                        error_5['location'] = 'language-service:makeDefault';
                        this.logglyService.log(error_5);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    LanguageService.prototype.deleteLang = function (id, languages) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.doc("languages/" + id).update({ isAdded: false })];
                    case 1:
                        _a.sent();
                        this.events.publish('language:deleteLangSuccess', id);
                        this.events.publish('language:getAddedLanguages');
                        return [3 /*break*/, 3];
                    case 2:
                        error_6 = _a.sent();
                        error_6['location'] = 'language-service:deleteLang';
                        this.logglyService.log(error_6);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    LanguageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LanguageService_Factory() { return new LanguageService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.LogglyLoggerService)); }, token: LanguageService, providedIn: "root" });
    return LanguageService;
}());
export { LanguageService };
