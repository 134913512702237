/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./splash-screen.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "./splash-screen.page";
var styles_SplashScreenPage = [i0.styles];
var RenderType_SplashScreenPage = i1.ɵcrt({ encapsulation: 0, styles: styles_SplashScreenPage, data: {} });
export { RenderType_SplashScreenPage as RenderType_SplashScreenPage };
function View_SplashScreenPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.logo; _ck(_v, 0, 0, currVal_0); }); }
export function View_SplashScreenPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ion-content", [], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(1, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "div", [["class", "splash-container"]], [[4, "background-color", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SplashScreenPage_1)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.data.logo; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.bgColor; _ck(_v, 2, 0, currVal_0); }); }
export function View_SplashScreenPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-splash-screen", [], null, null, null, View_SplashScreenPage_0, RenderType_SplashScreenPage)), i1.ɵdid(1, 114688, null, 0, i5.SplashScreenPage, [i3.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SplashScreenPageNgFactory = i1.ɵccf("app-splash-screen", i5.SplashScreenPage, View_SplashScreenPage_Host_0, { data: "data" }, {}, []);
export { SplashScreenPageNgFactory as SplashScreenPageNgFactory };
