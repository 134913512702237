<div class="widget-container" [style.background]="background" [ngClass]="{'widget-margin': background && background != 'transparent'}">
  <div class="widget-wrapper container">
    
    <div class="form-container">


        <!-- form title -->
        
       
        <!-- form inputs -->
        <div class="form-wrapper" >
          <!-- form banner  -->
          <ng-container *ngIf="formData.banner.mob || formData.banner.url">
            <div class="form-banner">
              <ion-img [src]="formData.banner[getBannerSrc()]"></ion-img>
            </div>
          </ng-container>

          <div class="form-title">
            <app-section-title *ngIf="formData.formTitle" [title]="formData.formTitle"></app-section-title>
          </div>
          <p *ngIf="response == 'no_login'" style="text-align: center;">
            {{CO_WIDGET_FORM_LABELS.form_visible_when_login | translate}}
          </p>
       
          <div class="form-wrap" *ngIf="dynamicForm">
          
          <form [formGroup]="dynamicForm" (ngSubmit)="onSubmit()">
            <ng-container *ngFor="let element of formData.inputs">
              <ng-container [ngSwitch]="element.type">

                <!-- text, email and numebr inputs -->
                <ng-container
                  *ngSwitchCase="['text', 'email', 'number'].includes(element.type) ? element.type : !element.type">
                  <div class="input-wrap">
                    <ion-label [ngClass]="{'invalid': !isValid(element.name)}" position="floating">{{element.name}}
                    </ion-label>
                    <ion-input class="form-input" [type]="element.type" [formControlName]="element.name"
                      placeholder="{{'SHARED.please_enter' | translate}} {{element.name}}"></ion-input>
                      <p class="err" *ngIf="!isValid(element.name)">*Please fill this field.</p>
                  </div>
                </ng-container>

                <!-- date input -->
                <ng-container *ngSwitchCase="'date'">
                  <div class="input-wrap">
                    <ion-label [ngClass]="{'invalid': !isValid(element.name)}" position="floating">{{element.name}}
                    </ion-label>
                    <ion-datetime class="form-input" placeholder="{{'SHARED.select_date' | translate}}"
                      displayFormat="D MMM YYYY" [formControlName]="element.name"></ion-datetime>
                      <p class="err" *ngIf="!isValid(element.name)">*Please fill this field.</p>
                  </div>
                </ng-container>

                <!-- textarea input -->
                <ng-container *ngSwitchCase="'textarea'">
                  <div class="input-wrap">
                    <ion-label [ngClass]="{'invalid': !isValid(element.name)}" position="floating">{{element.name}}
                    </ion-label>
                    <ion-textarea class="form-input" rows="4" [formControlName]="element.name"
                      placeholder="{{'SHARED.please_enter' | translate}} {{element.name}}"></ion-textarea>
                      <p class="err" *ngIf="!isValid(element.name)">*Please fill this field.</p>
                  </div>
                </ng-container>

                <!-- select options -->
                <ng-container *ngSwitchCase="'select'">
                  <div class="input-wrap">
                    <ion-label [ngClass]="{'invalid': !isValid(element.name)}" position="floating">{{element.name}}
                    </ion-label>
                    <ion-select class="form-input" value='' [formControlName]="element.name"
                      [multiple]="element.multiple" placeholder="{{'SHARED.select' | translate}} {{element.name}}">
                      <ion-select-option [value]="option" *ngFor="let option of element.options">{{option}}
                      </ion-select-option>
                    </ion-select>
                    <p class="err" *ngIf="!isValid(element.name)">*Please select an option.</p>
                  </div>
                
                </ng-container>

                <!-- document upload -->
                <ng-container *ngSwitchCase="'document'">
                  <div class="input-wrap">
                    <p [ngClass]="{'invalid': !isValid(element.name)}">{{element.name}}</p>
                    <input type="file" [name]="element.name" (change)="chooseFile(element.name, $event.target.files)" />
                    <p class="err" *ngIf="!isValid(element.name)">*Please upload document.</p>
                  </div>
                </ng-container>

              </ng-container>
            </ng-container>

            <!-- form actions buttons -->
            <div class="action-btn">
              <ion-button class="btn-2" color="medium" (click)="resetForm()">
                {{'SHARED.reset' | translate}}
              </ion-button>

              <ion-button type="submit" class="btn-2">
                {{formData.buttonName}}
              </ion-button>
            </div>

          </form>
        </div>
        </div>
    </div>
  </div>
</div>