/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payment-modal.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "./payment-modal.page";
var styles_PaymentModalPage = [i0.styles];
var RenderType_PaymentModalPage = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentModalPage, data: {} });
export { RenderType_PaymentModalPage as RenderType_PaymentModalPage };
function View_PaymentModalPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ion-spinner", [["color", "primary"]], null, null, null, i2.View_IonSpinner_0, i2.RenderType_IonSpinner)), i1.ɵdid(1, 49152, null, 0, i3.IonSpinner, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null)], function (_ck, _v) { var currVal_0 = "primary"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_PaymentModalPage_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "payment-method"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.paymentData.paymentInfo; _ck(_v, 1, 0, currVal_0); }); }
export function View_PaymentModalPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "ion-content", [["text-center", ""]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(1, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵdid(2, 16384, null, 0, i3.CssUtilsDeprecations, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "div", [["class", "pay-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "flaticon-pay"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PaymentModalPage_1)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PaymentModalPage_2)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 5, "ion-footer", [], null, null, null, i2.View_IonFooter_0, i2.RenderType_IonFooter)), i1.ɵdid(10, 49152, null, 0, i3.IonFooter, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(11, 0, null, 0, 3, "div", [["class", "bottom-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "ion-button", [["expand", "full"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(13, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { expand: [0, "expand"] }, null), (_l()(), i1.ɵted(-1, 0, ["got it...!!!"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.loader === true); _ck(_v, 6, 0, currVal_0); var currVal_1 = (_co.loader === false); _ck(_v, 8, 0, currVal_1); var currVal_2 = "full"; _ck(_v, 13, 0, currVal_2); }, null); }
export function View_PaymentModalPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payment-modal", [], null, null, null, View_PaymentModalPage_0, RenderType_PaymentModalPage)), i1.ɵdid(1, 114688, null, 0, i5.PaymentModalPage, [i3.Events, i3.LoadingController, i3.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentModalPageNgFactory = i1.ɵccf("app-payment-modal", i5.PaymentModalPage, View_PaymentModalPage_Host_0, {}, {}, []);
export { PaymentModalPageNgFactory as PaymentModalPageNgFactory };
