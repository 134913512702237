/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cancelled-reason.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/forms";
import * as i6 from "./cancelled-reason.page";
import * as i7 from "../../services/shared/shared.service";
import * as i8 from "../../services/label/label.service";
var styles_CancelledReasonPage = [i0.styles];
var RenderType_CancelledReasonPage = i1.ɵcrt({ encapsulation: 0, styles: styles_CancelledReasonPage, data: {} });
export { RenderType_CancelledReasonPage as RenderType_CancelledReasonPage };
export function View_CancelledReasonPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "ion-header", [["class", "templates-header"]], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 11, "ion-toolbar", [], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(3, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 3, "ion-title", [["class", "templates-header-title"]], null, null, null, i2.View_IonTitle_0, i2.RenderType_IonTitle)), i1.ɵdid(5, 49152, null, 0, i3.IonTitle, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(6, 0, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, 0, 5, "ion-buttons", [["slot", "end"]], null, null, null, i2.View_IonButtons_0, i2.RenderType_IonButtons)), i1.ɵdid(9, 49152, null, 0, i3.IonButtons, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(10, 0, null, 0, 3, "ion-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(11, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(12, 0, null, 0, 1, "ion-icon", [["name", "close"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(13, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 13, "ion-content", [["class", "ion-padding"]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(15, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(16, 0, null, 0, 11, "div", [["class", "data-field"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 2, "div", [["class", "data-field-txt"]], null, null, null, null, null)), (_l()(), i1.ɵted(18, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(20, 0, null, null, 7, "ion-textarea", [["class", "m-t-10"], ["rows", "4"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "ionBlur"], [null, "ionChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 21)._handleBlurEvent($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 21)._handleInputEvent($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.reason = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_IonTextarea_0, i2.RenderType_IonTextarea)), i1.ɵdid(21, 16384, null, 0, i3.TextValueAccessor, [i1.ElementRef], null, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.TextValueAccessor]), i1.ɵdid(23, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(25, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), i1.ɵdid(26, 49152, null, 0, i3.IonTextarea, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { placeholder: [0, "placeholder"], rows: [1, "rows"] }, null), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(28, 0, null, null, 5, "ion-footer", [], null, null, null, i2.View_IonFooter_0, i2.RenderType_IonFooter)), i1.ɵdid(29, 49152, null, 0, i3.IonFooter, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(30, 0, null, 0, 3, "ion-button", [["class", "footer-button"], ["expand", "block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(31, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { expand: [0, "expand"] }, null), (_l()(), i1.ɵted(32, 0, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = "close"; _ck(_v, 13, 0, currVal_1); var currVal_10 = _co.reason; _ck(_v, 23, 0, currVal_10); var currVal_11 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 26, 0, i1.ɵnov(_v, 27).transform("CANCELLED_REASON.enter_reason")), ""); var currVal_12 = "4"; _ck(_v, 26, 0, currVal_11, currVal_12); var currVal_13 = "block"; _ck(_v, 31, 0, currVal_13); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("CANCELLED_REASON.header_text")); _ck(_v, 6, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 19).transform("CANCELLED_REASON.provide_reason")); _ck(_v, 18, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 25).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 25).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 25).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 25).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 25).ngClassValid; var currVal_8 = i1.ɵnov(_v, 25).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 25).ngClassPending; _ck(_v, 20, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_14 = i1.ɵunv(_v, 32, 0, i1.ɵnov(_v, 33).transform("CANCELLED_REASON.submit_btn")); _ck(_v, 32, 0, currVal_14); }); }
export function View_CancelledReasonPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cancelled-reason", [], null, null, null, View_CancelledReasonPage_0, RenderType_CancelledReasonPage)), i1.ɵdid(1, 114688, null, 0, i6.CancelledReasonPage, [i3.ModalController, i7.SharedService, i8.LabelService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CancelledReasonPageNgFactory = i1.ɵccf("app-cancelled-reason", i6.CancelledReasonPage, View_CancelledReasonPage_Host_0, {}, {}, []);
export { CancelledReasonPageNgFactory as CancelledReasonPageNgFactory };
