import * as tslib_1 from "tslib";
import { Events } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
var HelpService = /** @class */ (function () {
    function HelpService(events, afs) {
        this.events = events;
        this.afs = afs;
    }
    HelpService.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('help:getHelpData', function () {
            _this.getHelpData();
        });
    };
    HelpService.prototype.getHelpData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var helpData, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('help', function (ref) { return ref
                                .orderBy('order', 'asc'); }).valueChanges().pipe(first()).toPromise()];
                    case 1:
                        helpData = _a.sent();
                        this.events.publish('help:publishHelpData', helpData);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.dir(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    HelpService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HelpService_Factory() { return new HelpService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore)); }, token: HelpService, providedIn: "root" });
    return HelpService;
}());
export { HelpService };
