<ion-header class="modal-header">
  <ion-toolbar>
    <ion-title class="modal-header-title">Make Payment</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>

  <div id="paypal-button-container"></div>

</ion-content>
