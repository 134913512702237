import { Component, OnInit, HostListener, ViewChild, Input } from '@angular/core';
import { ModalController, IonSlides } from '@ionic/angular';
import { UtilsService } from '../services/utils/utils.service';


@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.page.html',
  styleUrls: ['./image-modal.page.scss'],
})
export class ImageModalPage implements OnInit {
  @ViewChild('modalslider', {static: false}) public modalslider: IonSlides;

  imgs: any;
  showLoader: boolean = true;
  isFirstSlide = true;
  isLastSlide = false;
  @Input() index: number;
  sliderOpts = {
    initialSlide: 0,
    zoom: {
      maxRatio: 3
    },
    spaceBetween: 10,
    preloadImages: false,
    lazy: true,
    fadeEffect: {
      crossFade: true
    },
  }
  spliced: boolean = false;
  loadingUrl: string = 'assets/img/img-modal-loading.gif';
  imgZoom = false;

  constructor(private modalController: ModalController,private utilsService: UtilsService ) {}

  async slideChanged() {
    this.isFirstSlide = await this.modalslider.isBeginning();
    this.isLastSlide = await this.modalslider.isEnd();
  }

  async nextSlide(){
    this.modalslider.slideNext();
  }

  prevSlide(){
    this.modalslider.slidePrev();
  }

  ngOnDestroy() {
    if (window.history.state.modal) {
      history.back();
    }
  }

  async ngOnInit() {
    this.utilsService.consoleLog('modal images', this.imgs)
    this.sliderOpts.initialSlide = this.index;
    this.showLoader = false;

	const modalState = {
		modal: true,
		desc: 'State for the modal for correct back button functionality'
	};
	history.pushState(modalState, null);
    // setTimeout(() => {
    //   this.showLoader = false;
    // }, 2000);
  }

  spliceFirstImg() {
    if(this.spliced === false) {
      this.imgs.splice(0, 1);
      this.spliced = true;
    }
  }

  close() {
    this.modalController.dismiss();
  }

  // async zoom() {
  //   this.imgZoom = !this.imgZoom;
  //   const slider = await this.modalslider.getSwiper();
  //   const zoom = slider.zoom;
  //   this.imgZoom ? zoom.in() : zoom.out();
  // }

  async zoom(zoomIn: boolean) {
    const slider = await this.modalslider.getSwiper();
    const zoom = slider.zoom;
    zoomIn ? zoom.in() : zoom.out();
  }

  @HostListener('window:popstate')
  dismissModal() {
	this.modalController.dismiss();
  }
}