import * as tslib_1 from "tslib";
import { Renderer2, RendererFactory2 } from '@angular/core';
import { AdminSettingsService } from '../admin-settings/admin-settings.service';
import { ConfigService } from '../config/config.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../admin-settings/admin-settings.service";
import * as i3 from "../config/config.service";
var LoadScriptsService = /** @class */ (function () {
    function LoadScriptsService(rendererFactory, document, adminSettingsService, configService) {
        this.document = document;
        this.adminSettingsService = adminSettingsService;
        this.configService = configService;
        this._renderer2 = rendererFactory.createRenderer(null, null);
    }
    LoadScriptsService.prototype.loadPaymentLibraries = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var paymentLibraries, _i, paymentLibraries_1, lib, gatewayType, gatewayScriptSrc, paymentGatewayData, scriptId, scriptElement;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        paymentLibraries = [
                            /* use cashfree's production library in case the cashfree.development environment variable
                                is set to false; else use cashfree's development library*/
                            {
                                'gateway': 'cashfree',
                                'gatewaySrc': this.configService.environment.cashfree && this.configService.environment.cashfree.development ?
                                    'https://sdk.cashfree.com/js/ui/1.0.26/dropinClient.sandbox.js' :
                                    'https://sdk.cashfree.com/js/ui/1.0.26/dropinClient.prod.js'
                            },
                        ];
                        _i = 0, paymentLibraries_1 = paymentLibraries;
                        _a.label = 1;
                    case 1:
                        if (!(_i < paymentLibraries_1.length)) return [3 /*break*/, 4];
                        lib = paymentLibraries_1[_i];
                        gatewayType = lib['gateway'];
                        gatewayScriptSrc = lib['gatewaySrc'];
                        return [4 /*yield*/, this.adminSettingsService.getPaymentGateway(gatewayType)];
                    case 2:
                        paymentGatewayData = _a.sent();
                        if (paymentGatewayData && paymentGatewayData.active) {
                            scriptId = gatewayType.concat('Script');
                            if (!this.document.getElementById(scriptId)) {
                                scriptElement = this._renderer2.createElement('script');
                                this._renderer2.setAttribute(scriptElement, 'src', gatewayScriptSrc);
                                this._renderer2.setAttribute(scriptElement, 'defer', '');
                                // provide id to the script tag so that it is not appended to the head twice.
                                this._renderer2.setAttribute(scriptElement, 'id', scriptId);
                                this._renderer2.appendChild(this.document.head, scriptElement);
                            }
                        }
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    LoadScriptsService.prototype.loadGoogleMapsLibrary = function () {
        if (!this.document.getElementById('googleMapsScript')) {
            var scriptElement = this._renderer2.createElement('script');
            this._renderer2.setAttribute(scriptElement, 'src', 'https://maps.google.com/maps/api/js?key=AIzaSyBi8lJj0oSwvEdVcUkM-jiXsGpzKPbp-44');
            // provide id to the script tag so that it is not appended to the head twice.
            this._renderer2.setAttribute(scriptElement, 'id', 'googleMapsScript');
            this._renderer2.appendChild(this.document.head, scriptElement);
        }
    };
    LoadScriptsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoadScriptsService_Factory() { return new LoadScriptsService(i0.ɵɵinject(i0.RendererFactory2), i0.ɵɵinject(i1.DOCUMENT), i0.ɵɵinject(i2.AdminSettingsService), i0.ɵɵinject(i3.ConfigService)); }, token: LoadScriptsService, providedIn: "root" });
    return LoadScriptsService;
}());
export { LoadScriptsService };
