import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SharedService } from '../services/shared/shared.service';
import { UserService } from '../services/user/user.service';
var BodyMeasurementPage = /** @class */ (function () {
    function BodyMeasurementPage(sharedService, modalController, userService) {
        this.sharedService = sharedService;
        this.modalController = modalController;
        this.userService = userService;
        this.allowEdit = false;
        this.userId = '';
        this.bodyMeasurement = {
            profileName: '',
            bodyLength: null,
            fullHeight: null,
            blouseLength: null,
            shirtLength: null,
            jacketLength: null,
            kurthiHeight: null,
            shoulderToWaistBack: null,
            frontShoulder: null,
            backShoulder: null,
            armhole: null,
            wrist: null,
            bicep: null,
            strapLength: null,
            sleeveLength: null,
            sleeveCircumf: null,
            threeFourthSleeveLengthCircular: null,
            threeFourthSleeveLength: null,
            sleeveLengthHalf: null,
            upperChest: null,
            blouseChest: null,
            bust: null,
            blouseBelowBust: null,
            napeToApex: null,
            shoulderToApex: null,
            neck: null,
            frontNeckWidth: null,
            backNeckWidth: null,
            frontNeckDepth: null,
            backNeckDepth: null,
            frontNeckDepth2: null,
            backNeckDepth2: null,
            bottomLength: null,
            bottomWaist: null,
            bottomHip: null,
            seat: null,
            waistToHip: null,
            inseam: null,
            shortsLength: null,
            thighCircular: null,
            thighs: null,
            thighRound: null,
            aboveKnee: null,
            belowKnee: null,
            calf: null,
            ankle: null,
            sweepLength: null,
            frontSlit: null,
            sideSlit: null,
            apexToApex: null
        };
    }
    BodyMeasurementPage.prototype.ngOnInit = function () {
    };
    BodyMeasurementPage.prototype.ionViewWillEnter = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    BodyMeasurementPage.prototype.close = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.modalController.dismiss();
                return [2 /*return*/];
            });
        });
    };
    BodyMeasurementPage.prototype.save = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var profileName;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        profileName = this.bodyMeasurement.profileName.trim().toLowerCase();
                        if (!(profileName === '')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.sharedService.presentAlert('name required!')];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                    case 2:
                        this.modalController.dismiss(this.bodyMeasurement);
                        return [2 /*return*/];
                }
            });
        });
    };
    return BodyMeasurementPage;
}());
export { BodyMeasurementPage };
