import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-pick-drop-details',
  templateUrl: './pick-drop-details.page.html',
  styleUrls: ['./pick-drop-details.page.scss'],
})
export class PickDropDetailsPage implements OnInit {
  order;

  constructor(private modalController: ModalController) { }

  ngOnInit() { }

  closeModal() {
    this.modalController.dismiss();
  }

}
