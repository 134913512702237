import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Events, LoadingController, ModalController, NavController } from '@ionic/angular';
import { Component, NgZone, OnInit } from '@angular/core';
import { LabelService } from 'src/app/services/label/label.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/services/config/config.service';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';

@Component({
  selector: 'app-select-language',
  templateUrl: './select-language.page.html',
  styleUrls: ['./select-language.page.scss'],
})
export class SelectLanguagePage implements OnInit {
  headerText = '';
  showLoader = true;
  languages: any = [];
  SHARED_LABELS: any;
  SELECT_LANGUAGE_LABELS: any;
  routeFromSidemenu = false;
  loading: any;
  constructor(private events: Events,
    private  modalController: ModalController,
              private router: Router,
              private labelService: LabelService,
              private translateService: TranslateService,
              private storage: Storage,
              private ngZone: NgZone,
              private navController: NavController,
              private route: ActivatedRoute,
              private loadingController: LoadingController,
              private configService: ConfigService,
              private splashScreen: SplashScreen) {
                
               }

  ngOnInit() {
    this.SHARED_LABELS = this.labelService.labels['SHARED'];
    this.SELECT_LANGUAGE_LABELS = this.labelService.labels['SELECT_LANGUAGE'];
    this.headerText = this.SELECT_LANGUAGE_LABELS['header_text'];
  }

  ionViewDidEnter() {
    this.splashScreen.hide();
  }

  ionViewWillEnter() {
    this.initializeSubscriptions();
    this.events.publish('language:getAddedLanguages');
  }

  ionViewWillLeave() {
    this.removeSubscriptions();
  }

  initializeSubscriptions() {
    this.events.subscribe('language:publishAddedLanguages', (langs) => {
      this.storage.get('appLang').then((langCode) => {
        if (langCode && langCode !== undefined) {
          langs.map(lang => {
            if (lang.code === langCode) {
              lang.isDefault = true;
            } else {
              lang.isDefault = false;
            }
          });
        }
        this.languages = langs;
        this.showLoader = false;
      });
    });
  }

  selectLang(i: number) {
    for (let index = 0; index < this.languages.length; index++) {
      if (i === index) {
        this.languages[index].isDefault = true;
      } else {
        this.languages[index].isDefault = false;
      }
    }
  }

  async onClickSelect() {
    if (this.languages.length) {
      await this.presentLoading();
      this.languages.forEach(lang => {
        if (lang.isDefault) {
          this.translateService.setDefaultLang(lang.code);
          this.translateService.use(lang.code);
          this.labelService.intializeSubscriptions();
          this.storage.set('appLang', lang.code);
          this.loading.dismiss();
          this.ngZone.run(() => {
            this.navController.navigateRoot(['']);
          });
          this.modalDismiss();
        }
      });
    } else {
      this.storage.set('appLang', this.configService.environment.DEFAULT_APP_LANG);
      this.ngZone.run(() => {
        this.navController.navigateRoot(['']);
      });
      this.modalDismiss();
    }
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      duration: 2000,
    });
    await this.loading.present();
  }

  removeSubscriptions() {
    this.events.unsubscribe('language:publishAddedLanguages');
  }

  modalDismiss(){
    this.modalController.dismiss();
  }
 

}